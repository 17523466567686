import Header from "../../components/shared/Header";
import "../../styles/shared/Plans.scss";
import HeroImage from "../../images/image 103.png";
import SolutionImage1 from "../../images/Frame 1618868404.png";
import SolutionImage2 from "../../images/Frame 1618868405.png";
import SolutionImage3 from "../../images/Frame 1618868405 (1).png";
import SolutionImage4 from "../../images/Frame 1618868405 (2).png";
import Focus from "../../images/focus.png";
import { useEffect, useState } from "react";
import GreenLogo from "../../images/Group 32.png";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/shared/Footer";
import Collaborators from "../../components/shared/Collaborators";

export default function Basic() {
  const navigate = useNavigate();
  const [type, setType] = useState("tracking");
  const [planVideo, setplanVideo] = useState(false);
  const [openFaq, setOpenFaq] = useState(null);

  useEffect(() => {
    const nextBtn = document.querySelector(".feedback__inner__group__svg-group__next");
    const prevBtn = document.querySelector(".feedback__inner__group__svg-group__prev");
    const slider = document.querySelector(".feedback__inner__cards");
    const card = document.querySelector(".feedback__inner__cards__card");
    let itemDimensions = card.getBoundingClientRect();
    let amountToSlide = (itemDimensions.width + 24) * 3;

    nextBtn.addEventListener("click", () => {
      slider.scrollLeft += amountToSlide;
      console.log(
        'nextBtn', nextBtn,
        'prevBtn', prevBtn,
        'slider', slider,
        'card', card,
        'itemDimensions', itemDimensions,
        'amountToSlide', amountToSlide
      );
    });

    prevBtn.addEventListener("click", () => {
      slider.scrollLeft -= amountToSlide;
    });

  }, [])

  return (
    <div className="plans">
      <Header />
      <div className="hero">
        <div className="hero__inner">
          <div className="hero__inner__title">
            CloseCarbon <span>Basic</span>
          </div>
          <div className="hero__inner__subtitle">
            Real-time emission reduction. Set new goals. Carbon incentives.
            Validation. Verification. Monitoring. Free Internet of Things (IoT) devices.
          </div>
          <div className="hero__inner__price">
            Starting at NGN0 <span>Free</span>
          </div>
          <div className="hero__inner__button-group">
            <button
            onClick={() => navigate('/template/basic')}
            >Choose plan</button>
            <button
            onClick={() => {
              window.open("https://youtu.be/yyxGaFNoDws?si=CYUdV1gbyQ6IJxyz", "_blank", "noopener,noreferrer");
            }}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.155 13.7501C3.96313 13.7497 3.77466 13.6994 3.60812 13.6041C3.23312 13.3916 3 12.9791 3 12.5313V3.46881C3 3.01974 3.23312 2.60849 3.60812 2.39599C3.77863 2.298 3.97232 2.24761 4.16896 2.25009C4.36559 2.25256 4.55796 2.30781 4.72594 2.41006L12.4712 7.04631C12.6327 7.14752 12.7657 7.28807 12.858 7.45479C12.9502 7.6215 12.9986 7.8089 12.9986 7.99943C12.9986 8.18996 12.9502 8.37736 12.858 8.54408C12.7657 8.71079 12.6327 8.85134 12.4712 8.95256L4.72469 13.5901C4.55278 13.694 4.35588 13.7493 4.155 13.7501Z"
                  fill="#0FA958"
                />
              </svg>
              Watch now
            </button>
          </div>

          <img className="hero__inner__img" src={HeroImage} alt="" />
        </div>
      </div>
      <div className="solution">
        <div className="solution__title">
          The <span>solution</span> you need for the sustainability you want
        </div>
        <div className="solution__subtitle">
          A seamless user experience, an intuitive dashboard, and innovative
          features that keep you aligned with your sustainability goals{" "} <br />
          <span>- LeadGravel</span>
        </div>
        <div className="solution__grid">
          <div className="solution__grid__nav">
            <div
              className={
                type === "tracking"
                  ? "solution__grid__nav__item solution__grid__nav__item-active"
                  : "solution__grid__nav__item"
              }
              onClick={() => setType("tracking")}
            >
              <div className="solution__grid__nav__item__title">
                Real-time tracking
              </div>
              {type === "tracking" && (
                <div className="solution__grid__nav__item__subtitle">
                  Effortlessly monitor your progress and achieve the sustainability goals tailored to your needs whether it's reducing emissions, conserving energy, or supporting renewable initiatives.
                </div>
              )}
            </div>
            <div
              className={
                type === "credit"
                  ? "solution__grid__nav__item solution__grid__nav__item-active"
                  : "solution__grid__nav__item"
              }
              onClick={() => setType("credit")}
            >
              <div className="solution__grid__nav__item__title">
                Carbon incentive
              </div>
              {type === "credit" && (
                <div className="solution__grid__nav__item__subtitle">
                  Earn our CloseCarbon reduction tonne (CCRT) as you reduce your emissions one tonne at a time.
                </div>
              )}
            </div>
            <div
              className={
                type === "verification"
                  ? "solution__grid__nav__item solution__grid__nav__item-active"
                  : "solution__grid__nav__item"
              }
              onClick={() => setType("verification")}
            >
              <div className="solution__grid__nav__item__title">
                Verification
              </div>
              {type === "verification" && (
                <div className="solution__grid__nav__item__subtitle">
                  Verification of your emission data with our certified verifiers to ensure its accuracy, reliability and compliance with established carbon methodologies and standards.
                </div>
              )}
            </div>
            <div
              className={
                type === "insights"
                  ? "solution__grid__nav__item solution__grid__nav__item-active"
                  : "solution__grid__nav__item"
              }
              onClick={() => setType("insights")}
            >
              <div className="solution__grid__nav__item__title">
                Get insights through informative reports
              </div>
              {type === "insights" && (
                <div className="solution__grid__nav__item__subtitle">
                  Automate and tailor reports on your carbon emissions, and carbon reduction, with CloseCarbon, empowering you to make confident decisions for a greener future.
                </div>
              )}
            </div>
          </div>
          {type === "tracking" && (
            <img src={SolutionImage1} className="solution__grid__img" />
          )}
          {type === "credit" && (
            <img src={SolutionImage2} className="solution__grid__img" />
          )}
          {type === "verification" && (
            <img src={SolutionImage3} className="solution__grid__img" />
          )}
          {type === "insights" && (
            <img src={SolutionImage4} className="solution__grid__img" />
          )}
        </div>
      </div>
      <div className="feedback">
        <div className="feedback__inner">
          <div className="feedback__inner__group">
            <div className="feedback__inner__group__title">
              Feedback from our customers
            </div>
            <div className="feedback__inner__group__subtitle">
              We’re trusted by many businesses
            </div>
            <div className="feedback__inner__group__svg-group">
              <div className="feedback__inner__group__svg-group__prev">
                <svg
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M0.292892 7.29289C-0.0976315 7.68342 -0.0976315 8.31658 0.292892 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292892 7.29289ZM17 7L1 7V9L17 9V7Z" />
                </svg>
              </div>

              <div className="feedback__inner__group__svg-group__next">
                <svg
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M16.7071 8.70711C17.0976 8.31658 17.0976 7.68342 16.7071 7.29289L10.3431 0.928932C9.95262 0.538408 9.31946 0.538408 8.92893 0.928932C8.53841 1.31946 8.53841 1.95262 8.92893 2.34315L14.5858 8L8.92893 13.6569C8.53841 14.0474 8.53841 14.6805 8.92893 15.0711C9.31946 15.4616 9.95262 15.4616 10.3431 15.0711L16.7071 8.70711ZM0 9H16V7H0L0 9Z" />
                </svg>
              </div>
            </div>
          </div>
          <div className="feedback__inner__cards">
          <div className="feedback__inner__cards__card">
              <div className="feedback__inner__cards__card__cover"></div>

              <div className="feedback__inner__cards__card__title">
              CloseCarbon's basic plan made it incredibly easy for me to track my carbon emissions. I'm impressed with the simplicity and effectiveness of the tools provided.
              </div>
              <div className="feedback__inner__cards__card__subtitle">
                Samuel
              </div>
              <div>LS Truval</div>
            </div>
            <div className="feedback__inner__cards__card">
              <div className="feedback__inner__cards__card__cover"></div>

              <div className="feedback__inner__cards__card__title">
              I've been using CloseCarbon's basic plan for a month now, and I'm already seeing positive changes in my carbon footprint. The real-time tracking feature is especially helpful in keeping me accountable.
              </div>
              <div className="feedback__inner__cards__card__subtitle">
                Samuel
              </div>
              <div>LS Truval</div>
            </div>
            <div className="feedback__inner__cards__card">
              <div className="feedback__inner__cards__card__cover"></div>

              <div className="feedback__inner__cards__card__title">
              This basic plan makes me feel more empowered to make sustainable choices in my daily life. The user-friendly interface have been instrumental in guiding me towards a greener lifestyle.
              </div>
              <div className="feedback__inner__cards__card__subtitle">
                Samuel
              </div>
              <div>LS Truval</div>
            </div>
            <div className="feedback__inner__cards__card">
              <div className="feedback__inner__cards__card__cover"></div>

              <div className="feedback__inner__cards__card__title">
              CloseCarbon's basic plan made it incredibly easy for me to track my carbon emissions. I'm impressed with the simplicity and effectiveness of the tools provided.
              </div>
              <div className="feedback__inner__cards__card__subtitle">
                Samuel
              </div>
              <div>LS Truval</div>
            </div>
            <div className="feedback__inner__cards__card">
              <div className="feedback__inner__cards__card__cover"></div>

              <div className="feedback__inner__cards__card__title">
              I've been using CloseCarbon's basic plan for a month now, and I'm already seeing positive changes in my carbon footprint. The real-time tracking feature is especially helpful in keeping me accountable.
              </div>
              <div className="feedback__inner__cards__card__subtitle">
                Samuel
              </div>
              <div>LS Truval</div>
            </div>
            <div className="feedback__inner__cards__card">
              <div className="feedback__inner__cards__card__cover"></div>

              <div className="feedback__inner__cards__card__title">
              This basic plan makes me feel more empowered to make sustainable choices in my daily life. The user-friendly interface have been instrumental in guiding me towards a greener lifestyle.
              </div>
              <div className="feedback__inner__cards__card__subtitle">
                Samuel
              </div>
              <div>LS Truval</div>
            </div>
            
          </div>
        </div>
      </div>

      <div className="unlock">
        <div className="unlock__card1">
          <div className="unlock__card1__title">
            Unlock the potential of CloseCarbon Basic plan
          </div>
          <div className="unlock__card1__subtitle">
            Whether you're just starting your sustainability journey or seeking
            to deepen your commitment, the basic plan provides the foundation
            you need to take meaningful steps towards environmental stewardship.
          </div>
          <img className="unlock__card1__img" src={Focus} alt="" />
        </div>
        <div className="unlock__card2">
          <div className="unlock__card2__flex">
            <img src={GreenLogo} />
            <div>
              <div>Basic</div>
              <div>
                Green Project (Solar, Renewable energy and many more) <br />
                Non-green Project (Infrastructure change and Monitoring)
              </div>
            </div>
          </div>
          <div className="unlock__card2__divider"></div>
          <div className="unlock__card2__price">
            <div>NGN 0</div>
            <div>Free</div>
          </div>
          <div className="unlock__card2__divider"></div>
          <div className="unlock__card2__more-info">
            <div className="unlock__card2__more-info__title">Access to:</div>
            <div className="unlock__card2__more-info__flex">
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="6" cy="6" r="6" fill="#E9F9D2" />
                <path
                  d="M2 6.27757L4.38766 8.73575L10 3"
                  stroke="#0D924F"
                  stroke-width="1.09252"
                />
              </svg>

              <div>
              Validation: ensuring the accuracy of assumptions and methods to support future activity outcomes.
              </div>
            </div>
            <div className="unlock__card2__more-info__flex">
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="6" cy="6" r="6" fill="#E9F9D2" />
                <path
                  d="M2 6.27757L4.38766 8.73575L10 3"
                  stroke="#0D924F"
                  stroke-width="1.09252"
                />
              </svg>

              <div>
              Verification: ensure the accuracy of your carbon reduction data meets established standards.
              </div>
            </div>
            <div className="unlock__card2__more-info__flex">
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="6" cy="6" r="6" fill="#E9F9D2" />
                <path
                  d="M2 6.27757L4.38766 8.73575L10 3"
                  stroke="#0D924F"
                  stroke-width="1.09252"
                />
              </svg>

              <div>
              Tangible proof of carbon emissions reduction called CloseCarbon Reduction Tonne (CCRT), verified and issued by regulatory bodies.
              </div>
            </div>
            <div className="unlock__card2__more-info__flex">
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="6" cy="6" r="6" fill="#E9F9D2" />
                <path
                  d="M2 6.27757L4.38766 8.73575L10 3"
                  stroke="#0D924F"
                  stroke-width="1.09252"
                />
              </svg>

              <div>
              Monitoring of your carbon emissions becomes effortless, providing real-time insights to help you track progress and make sustainable choices with ease.
              </div>
            </div>

            <button
              className="unlock__card2__more-info__button"
              onClick={() => navigate('/template/basic')}
            >
              Choose plan
            </button>
          </div>
        </div>
      </div>
      <div className="faq">
        <div className="faq__inner">
          <div className="faq__inner__card1">
            <div className="faq__inner__card1__title">
              Frequently <br /> asked <br /> <span>questions</span>
            </div>
          </div>
          <div className="faq__inner__card2">
            <div
              className="faq__inner__card2__faq-card"
              onClick={() => (openFaq === 0 ? setOpenFaq(null) : setOpenFaq(0))}
            >
              <div className="card_inner">
                <div>What features are included in the basic plan?</div>
                <div className="plus">
                  <div className="item"></div>
                  <div
                    className={
                      openFaq === 0 ? "item item2 itemOpaque" : "item item2"
                    }
                  ></div>
                </div>
              </div>
              <div>
                {openFaq === 0 && (
                  <div className="subtitle">
                    CloseCarbon's Basic plan offers essential tools for
                    monitoring carbon emissions, setting reduction goals, and
                    accessing basic verification services. Users can track their
                    carbon footprint in real-time, receive customizable reports,
                    and access a simplified verification process.
                  </div>
                )}
                <div className="line"></div>
              </div>
            </div>
            <div
              className="faq__inner__card2__faq-card"
              onClick={() => (openFaq === 1 ? setOpenFaq(null) : setOpenFaq(1))}
            >
              <div className="card_inner">
                <div>
                  Can I upgrade my basic plan to access more advanced features?
                </div>
                <div className="plus">
                  <div className="item"></div>
                  <div
                    className={
                      openFaq === 1 ? "item item2 itemOpaque" : "item item2"
                    }
                  ></div>
                </div>
              </div>
              <div>
                {openFaq === 1 && (
                  <div className="subtitle">
                    CloseCarbon's Basic plan offers essential tools for
                    monitoring carbon emissions, setting reduction goals, and
                    accessing basic verification services. Users can track their
                    carbon footprint in real-time, receive customizable reports,
                    and access a simplified verification process.
                  </div>
                )}
                <div className="line"></div>
              </div>
            </div>
            <div
              className="faq__inner__card2__faq-card"
              onClick={() => (openFaq === 2 ? setOpenFaq(null) : setOpenFaq(2))}
            >
              <div className="card_inner">
                <div>
                How accurate is the carbon tracking feature in the basic plan?
                </div>
                <div className="plus">
                  <div className="item"></div>
                  <div
                    className={
                      openFaq === 2 ? "item item2 itemOpaque" : "item item2"
                    }
                  ></div>
                </div>
              </div>
              <div>
                {openFaq === 2 && (
                  <div className="subtitle">
                    CloseCarbon's Basic plan offers essential tools for
                    monitoring carbon emissions, setting reduction goals, and
                    accessing basic verification services. Users can track their
                    carbon footprint in real-time, receive customizable reports,
                    and access a simplified verification process.
                  </div>
                )}
                <div className="line"></div>
              </div>
            </div>
            <div
              className="faq__inner__card2__faq-card"
              onClick={() => (openFaq === 3 ? setOpenFaq(null) : setOpenFaq(3))}
            >
              <div className="card_inner">
                <div>
                Is there a limit to the number of emissions I can track with the basic plan?
                </div>
                <div className="plus">
                  <div className="item"></div>
                  <div
                    className={
                      openFaq === 3 ? "item item2 itemOpaque" : "item item2"
                    }
                  ></div>
                </div>
              </div>
              <div>
                {openFaq === 3 && (
                  <div className="subtitle">
                    CloseCarbon's Basic plan offers essential tools for
                    monitoring carbon emissions, setting reduction goals, and
                    accessing basic verification services. Users can track their
                    carbon footprint in real-time, receive customizable reports,
                    and access a simplified verification process.
                  </div>
                )}
                <div className="line"></div>
              </div>
            </div>
            <div
              className="faq__inner__card2__faq-card"
              onClick={() => (openFaq === 4 ? setOpenFaq(null) : setOpenFaq(4))}
            >
              <div className="card_inner">
                <div>
                How can I cancel my basic plan subscription?
                </div>
                <div className="plus">
                  <div className="item"></div>
                  <div
                    className={
                      openFaq === 4 ? "item item2 itemOpaque" : "item item2"
                    }
                  ></div>
                </div>
              </div>
              <div>
                {openFaq === 4 && (
                  <div className="subtitle">
                    CloseCarbon's Basic plan offers essential tools for
                    monitoring carbon emissions, setting reduction goals, and
                    accessing basic verification services. Users can track their
                    carbon footprint in real-time, receive customizable reports,
                    and access a simplified verification process.
                  </div>
                )}
                <div className="line"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
     <Collaborators />
      <Footer />

    </div>
  );
}
