import { useEffect, useState } from "react";
import Search from "../../components/UserDashboard/Search";
import SideBar from "../../components/UserDashboard/SideBar";
import "../../styles/UserDashboard/Settings.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  activate2fa,
  deactivate2fa,
  deactivateUser,
  getKyc,
  updatePassword,
} from "../../apis/General/users";
import Kyc from "../../components/UserDashboard/Kyc";
import { IconButton, InputAdornment, OutlinedInput } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Settings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const auth = useSelector((state) => state.auth);
  const [scenario, setScenario] = useState("");
  const [facilityType, setFacilityType] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [tfaActive, setTfaActive] = useState(false);
  const [tfaModalOpen, setTfaModalOpen] = useState(false);
  const [twoFaAction, setTwoFaAction] = useState(null);
  const [otp1, setOtp1] = useState("");
  const [otp2, setOtp2] = useState("");
  const [otp3, setOtp3] = useState("");
  const [otp4, setOtp4] = useState("");
  const [otp5, setOtp5] = useState("");
  const [otp6, setOtp6] = useState("");

  const fetchKyc = async () => {
    const response = await getKyc(auth?.userInfo?.id, auth?.token);
    console.log("slide", response);
    setFacilityType(response?.data?.message?.facilityCategory);
    setScenario(response?.data?.message?.facilityCategory === "green" ? "green-project" : response?.data?.message?.sustainabilitySolution);

  };

  useEffect(() => {
    fetchKyc();
  }, []);

  console.log("auther", auth);

  useEffect(() => {
    const otpInputs = document.querySelectorAll(".otp_input");

    otpInputs.forEach((input, index) => {
      input.addEventListener("input", (event) => {
        const inputValue = event.target.value;

        if (inputValue && index < otpInputs.length - 1) {
          otpInputs[index + 1].focus();
        }
      });

      input.addEventListener("keydown", (event) => {
        if (event.key === "Backspace" && index > 0 && !input.value) {
          otpInputs[index - 1].focus();
          event.preventDefault();
        }
      });
    });
  }, [tfaModalOpen]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };
  const handleModalOpen2 = () => {
    setModalOpen2(true);
  };
  const handleTfaModalOpen = () => {
    if (!tfaActive) {
      setTwoFaAction("enable");
    } else {
      setTwoFaAction("disable");
    }
    setTfaModalOpen(true);
  };

  const handleModalClose = (e) => {
    if (e.target.classList.contains("password_change_modal_container")) {
      setModalOpen(false);
    }
  };
  const handleModalClose2 = (e) => {
    if (e.target.classList.contains("password_change_modal_container")) {
      setModalOpen2(false);
    }
  };
  const handleTfaModalClose = (e) => {
    if (e.target.classList.contains("tfa-modal-container")) {
      setTfaModalOpen(false);
    }
  };

  const handleUpdatePassword = async (e) => {
    setLoading(true);
    e.preventDefault();
    if(newPassword !== confirmNewPassword) {
alert("new passwords do not match. Please check and try again")
    }else {
      const response = await updatePassword(
        oldPassword,
        newPassword,
        auth?.token
      );
      console.log("update password", response);
      if (response?.data?.statusCode === 200) {
        toast.success("Password updated successfully");
        setModalOpen(false);
        setOldPassword("");
        setNewPassword("");
        setConfirmNewPassword("");
      } else {
        toast.error(response?.data?.message);
      }
    }
    setLoading(false);
  };

  const handleDeleteUser = async () => {
    var result = window.confirm(
      "Are you sure you want to delete your account?"
    );
    if (result) {
      setLoading(true);

      const response = await deactivateUser(auth?.userInfo?.id, auth?.token);
      // console.log('response', response);
      if (response?.data?.statusCode === 200) {
        toast.success(response?.data?.message);
        dispatch({
          type: "LOGOUT_SUCCESS",
        });
        localStorage.removeItem("token");
        navigate("/login");
        window.scrollTo(0, 0);
      } else {
        toast.error(response?.data?.message);
      }
      setLoading(false);
    }
  };

  const clearOtpInput = () => {
    setOtp1("");
    setOtp2("");
    setOtp3("");
    setOtp4("");
    setOtp5("");
    setOtp6("");
  };

  const handle2fa = async (e) => {
    setLoading(true);
    e.preventDefault();
    const code = `${otp1}${otp2}${otp3}${otp4}${otp5}${otp6}`;
    if (twoFaAction === "enable") {
      const response = await activate2fa(code, auth?.token);
      console.log("2fa response", response);
      if (response?.data?.statusCode === 200) {
        toast.success("Two Factor Authentication Enabled Successfully");
        setTfaModalOpen(false);
        setTfaActive(true);
        clearOtpInput();

        localStorage.removeItem("token");
        localStorage.setItem("token", response?.data?.message);

        dispatch({
          type: "USER_LOGIN_SUCCESS",
          payload: {
            token: response?.data?.message,
          },
        });
      }
    } else if (twoFaAction === "disable") {
      const response = await deactivate2fa(code, auth?.token);
      console.log("2fa dec response", response);
      if (response?.data?.statusCode === 200) {
        toast.success("Two Factor Authentication Disabled Successfully");
        setTfaModalOpen(false);
        setTfaActive(false);
        clearOtpInput();

        localStorage.removeItem("token");
        localStorage.setItem("token", response?.data?.message);

        dispatch({
          type: "USER_LOGIN_SUCCESS",
          payload: {
            token: response?.data?.message,
          },
        });
      } else {
        toast.error(response?.data?.message);
      }
    } else {
      alert("Invalid action");
    }

    setLoading(false);
  };

  useEffect(() => {
    setTfaActive(!!auth?.userInfo?.twoFaEnabled);
  }, []);

  console.log('soda', facilityType);

  return (
    <div className="settings">
      <div className="sidebar_container">
        <SideBar />
      </div>

      <div className="main">
        <Search />
        <div className="settings_divider"></div>
        <div className="settings_box">
          <div className="title">Facility category</div>
          <div className="subtitle_group">
            <div>
              {facilityType}
            </div>

            {!facilityType && <svg width="24" height="24" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"
>
<rect x="1" y="1" width="16" height="16" rx="4" stroke="url(#paint0_linear_2889_18043)" stroke-width="1.33333"/>
<defs>
<linearGradient id="paint0_linear_2889_18043" x1="1" y1="1.36719" x2="20.0689" y2="10.7886" gradientUnits="userSpaceOnUse">
<stop stop-color="#006633"/>
<stop offset="1" stop-color="#02A4CC"/>
</linearGradient>
</defs>
</svg> }  

{ !!facilityType &&<svg width="24" height="24" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"
>
<rect x="1" y="1" width="16" height="16" rx="4" stroke="url(#paint0_linear_2889_18037)" stroke-width="1.33333"/>
<path d="M5.5 9.49992L7.83333 11.8333L12.5 6.83325" stroke="url(#paint1_linear_2889_18037)" stroke-linecap="round" stroke-linejoin="round"/>
<defs>
<linearGradient id="paint0_linear_2889_18037" x1="1" y1="1.36719" x2="20.0689" y2="10.7886" gradientUnits="userSpaceOnUse">
<stop stop-color="#006633"/>
<stop offset="1" stop-color="#02A4CC"/>
</linearGradient>
<linearGradient id="paint1_linear_2889_18037" x1="5.5" y1="6.948" x2="12.5203" y2="11.8039" gradientUnits="userSpaceOnUse">
<stop stop-color="#006633"/>
<stop offset="1" stop-color="#02A4CC"/>
</linearGradient>
</defs>
</svg>}
          </div>
         

          
        </div>
        <div className="settings_divider"></div>

        <div className="settings_box">
          <div className="title">Sustainability solution</div>
          <div className="subtitle_group">
            <div>
              {scenario}
            </div>
           
            {!scenario && <svg width="24" height="24" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"
>
<rect x="1" y="1" width="16" height="16" rx="4" stroke="url(#paint0_linear_2889_18043)" stroke-width="1.33333"/>
<defs>
<linearGradient id="paint0_linear_2889_18043" x1="1" y1="1.36719" x2="20.0689" y2="10.7886" gradientUnits="userSpaceOnUse">
<stop stop-color="#006633"/>
<stop offset="1" stop-color="#02A4CC"/>
</linearGradient>
</defs>
</svg> }  

{ !!scenario &&<svg width="24" height="24" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"
>
<rect x="1" y="1" width="16" height="16" rx="4" stroke="url(#paint0_linear_2889_18037)" stroke-width="1.33333"/>
<path d="M5.5 9.49992L7.83333 11.8333L12.5 6.83325" stroke="url(#paint1_linear_2889_18037)" stroke-linecap="round" stroke-linejoin="round"/>
<defs>
<linearGradient id="paint0_linear_2889_18037" x1="1" y1="1.36719" x2="20.0689" y2="10.7886" gradientUnits="userSpaceOnUse">
<stop stop-color="#006633"/>
<stop offset="1" stop-color="#02A4CC"/>
</linearGradient>
<linearGradient id="paint1_linear_2889_18037" x1="5.5" y1="6.948" x2="12.5203" y2="11.8039" gradientUnits="userSpaceOnUse">
<stop stop-color="#006633"/>
<stop offset="1" stop-color="#02A4CC"/>
</linearGradient>
</defs>
</svg>}
          </div>

          {/* <div className="settings_button_group">
                    <button className='transparent'>Cancel</button>
                    <button className='filled'>Save Changes</button>
                </div> */}
        </div>
        <div className="settings_divider"></div>

        <div className="settings_box">
          <div className="password_group">
            <div className="fit">
              <div className="title">Password Security</div>
              <div className="subtitle_group nowrap">
                <div>Change your password</div>
              </div>
            </div>

            <div className="settings_button_group">
              <button className="filled password" onClick={handleModalOpen}>
                Change Password
              </button>
            </div>
          </div>
        </div>
        <div className="settings_divider"></div>
        <div className="settings_box">
          <div className="password_group">
            <div className="fit">
              <div className="title">Activate 2 Factor Authentication</div>
              <div className="subtitle_group nowrap">
                <div>Enhancing your security in two simple steps</div>
              </div>
            </div>

            <div className="settings_button_group">
              <div
                className={
                  tfaActive
                    ? "settings-switch settings-switch-active"
                    : "settings-switch"
                }
                onClick={handleTfaModalOpen}
              >
                <div className="settings-switch__ball"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="settings_divider"></div>
        <div className="settings_box">
          <div className="delete_group">
            <div>
              <div className="title">Delete Account</div>
              <div className="subtitle_group nowrap">
                <div>Permanently delete your account</div>
              </div>
            </div>
            <div className="settings_button_group">
              <button
                className="transparent error bold"
                onClick={handleModalOpen2}
                disabled={loading}
              >
                Delete Account
              </button>
              <button className="transparent bold">Learn More</button>
            </div>
          </div>
        </div>
      </div>

      {modalOpen && (
        <form className="password_change_modal" onSubmit={handleUpdatePassword}>
          <label htmlFor="oldPassword">Current Password</label>
          <OutlinedInput
            id="oldPassword"
            name="oldPassword"
            placeholder="Current password"
            className="password_modal_input"
            required
            type={showPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            value={oldPassword}
            onChange={(e) => {
              setOldPassword(e.target.value);
            }}
          />
          <label htmlFor="newPassword">New Password</label>

          <OutlinedInput
            id="newPassword"
            name="newPassword"
            className="password_modal_input"
            placeholder="New password"
            required
            type={showPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            value={newPassword}
            onChange={(e) => {
              setNewPassword(e.target.value);
            }}
          />
          <label htmlFor="newPassword">Confirm New Password</label>

          <OutlinedInput
            id="newPassword"
            name="newPassword"
            className="password_modal_input"
            placeholder="Confirm New Password"
            required
            type={showPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            value={confirmNewPassword}
            onChange={(e) => {
              setConfirmNewPassword(e.target.value);
            }}
          />

          <button
            className="password_modal_submit"
            type="submit"
            disabled={loading}
          >
            <div>{loading ? "Loading..." : "Update Password"}</div>
          </button>
        </form>
      )}
      {modalOpen2 && (
        <div className="delete-modal">
          <div className="delete-modal__svg">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => setModalOpen2(false)}
              className="pointer"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.8999 1.50004C15.2999 1.65004 17.5499 2.85004 19.1999 4.50004C21.1499 6.60004 22.1999 9.15004 22.1999 12.15C22.1999 14.55 21.2999 16.8 19.7999 18.75C18.2999 20.55 16.1999 21.9 13.7999 22.35C11.3999 22.8 8.99994 22.5 6.89994 21.3C4.79994 20.1 3.14994 18.3 2.24994 16.05C1.34994 13.8 1.19994 11.25 1.94994 9.00004C2.69994 6.60004 4.04994 4.65004 6.14994 3.30004C8.09994 1.95004 10.4999 1.35004 12.8999 1.50004ZM13.6499 20.85C15.5999 20.4 17.3999 19.35 18.7499 17.7C19.9499 16.05 20.6999 14.1 20.5499 12C20.5499 9.60004 19.6499 7.20004 17.9999 5.55004C16.4999 4.05004 14.6999 3.15004 12.5999 3.00004C10.6499 2.85004 8.54994 3.30004 6.89994 4.50004C5.24994 5.70004 4.04994 7.35004 3.44994 9.45004C2.84994 11.4 2.84994 13.5 3.74994 15.45C4.64994 17.4 5.99994 18.9 7.79994 19.95C9.59994 21 11.6999 21.3 13.6499 20.85ZM11.8499 11.25L15.4499 7.50004L16.4999 8.55004L12.8999 12.3L16.4999 16.05L15.4499 17.1L11.8499 13.35L8.24994 17.1L7.19994 16.05L10.7999 12.3L7.19994 8.55004L8.24994 7.50004L11.8499 11.25Z"
                fill="#667185"
              />
            </svg>
          </div>

          <div className="delete-modal__title">Delete Account?</div>
          <div className="delete-modal__subtitle">
            Are you sure you want to Delete your CloseCarbon basic account?
          </div>
          <div className="delete-modal__button-group">
            <button onClick={() => setModalOpen2(false)}>Cancel</button>
            <button onClick={handleDeleteUser}>{loading ? "Loading..." : "Confirm"}</button>
          </div>
        </div>
      )}
      {tfaModalOpen && (
        <form className="tfa-modal" onSubmit={handle2fa}>
          <div className="tfa-modal__svg">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => setTfaModalOpen(false)}
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.9 1.50004C15.3 1.65004 17.55 2.85004 19.2 4.50004C21.15 6.60004 22.2 9.15004 22.2 12.15C22.2 14.55 21.3 16.8 19.8 18.75C18.3 20.55 16.2 21.9 13.8 22.35C11.4 22.8 9 22.5 6.9 21.3C4.8 20.1 3.15 18.3 2.25 16.05C1.35 13.8 1.2 11.25 1.95 9.00004C2.7 6.60004 4.05 4.65004 6.15 3.30004C8.1 1.95004 10.5 1.35004 12.9 1.50004ZM13.65 20.85C15.6 20.4 17.4 19.35 18.75 17.7C19.95 16.05 20.7 14.1 20.55 12C20.55 9.60004 19.65 7.20004 18 5.55004C16.5 4.05004 14.7 3.15004 12.6 3.00004C10.65 2.85004 8.55 3.30004 6.9 4.50004C5.25 5.70004 4.05 7.35004 3.45 9.45004C2.85 11.4 2.85 13.5 3.75 15.45C4.65 17.4 6 18.9 7.8 19.95C9.6 21 11.7 21.3 13.65 20.85ZM11.85 11.25L15.45 7.50004L16.5 8.55004L12.9 12.3L16.5 16.05L15.45 17.1L11.85 13.35L8.25 17.1L7.2 16.05L10.8 12.3L7.2 8.55004L8.25 7.50004L11.85 11.25Z"
                fill="#667185"
              />
            </svg>
          </div>
          {twoFaAction === "enable" && (
            <div className="tfa-modal__title">
              Setup 2-factor authentication
            </div>
          )}
          {twoFaAction === "disable" && (
            <div className="tfa-modal__title">
              Deactivate 2-factor authentication
            </div>
          )}
          {twoFaAction === "enable" && (
            <div className="tfa-modal__subtitle">
              Input a 5 digit code you’ll always remember. This code will be
              asked at different interval whenever you login{" "}
            </div>
          )}
          {twoFaAction === "disable" && (
            <div className="tfa-modal__subtitle">
              Input your 5 digit code to deactivate 2-factor authentication.
            </div>
          )}
          <div className="otp_box_group">
            <div className="otp_box">
              <input
                className="otp_input"
                type="text"
                maxlength="1"
                required
                value={otp1}
                name="otp1"
                onChange={(e) => {
                  setOtp1(e.target.value);
                  // setOtpError(false);
                }}
              />
            </div>

            <div className="otp_box">
              <input
                className="otp_input"
                type="text"
                maxlength="1"
                required
                value={otp2}
                name="otp2"
                onChange={(e) => {
                  setOtp2(e.target.value);
                  // setOtpError(false);
                }}
              />
            </div>
            <div className="otp_box">
              <input
                className="otp_input"
                type="text"
                maxlength="1"
                required
                value={otp3}
                name="otp3"
                onChange={(e) => {
                  setOtp3(e.target.value);
                  // setOtpError(false);
                }}
              />
            </div>
            <div className="otp_box">
              <input
                className="otp_input"
                type="text"
                maxlength="1"
                required
                value={otp4}
                name="otp4"
                onChange={(e) => {
                  setOtp4(e.target.value);
                  // setOtpError(false);
                }}
              />
            </div>
            <div className="otp_box">
              <input
                className="otp_input"
                type="text"
                maxlength="1"
                required
                value={otp5}
                name="otp5"
                onChange={(e) => {
                  setOtp5(e.target.value);
                  // setOtpError(false);
                }}
              />
            </div>
            <div className="otp_box">
              <input
                className="otp_input"
                type="text"
                maxlength="1"
                required
                value={otp6}
                name="otp6"
                onChange={(e) => {
                  setOtp6(e.target.value);
                  // setOtpError(false);
                }}
              />
            </div>
          </div>

          <button
            className="password_modal_submit"
            type="submit"
            disabled={loading}
          >
            <div>
              {loading
                ? "Loading..."
                : twoFaAction === "disable"
                ? "Disable"
                : "Enable"}
            </div>
          </button>
        </form>
      )}
      {modalOpen && (
        <div
          className="password_change_modal_container"
          onClick={handleModalClose}
        ></div>
      )}
      {modalOpen2 && (
        <div
          className="password_change_modal_container"
          onClick={handleModalClose2}
        ></div>
      )}
      {tfaModalOpen && (
        <div
          className="tfa-modal-container"
          onClick={handleTfaModalClose}
        ></div>
      )}
    </div>
  );
};

export default Settings;
