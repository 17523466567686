import { useEffect, useRef, useState } from "react";
import SideBar from "../../components/AdminDashboard/SideBar";
import "../../styles/AdminDashboard/Iot.scss";
import moment from 'moment'
import { Tab, Tabs, Tooltip, Typography } from "@mui/material";
import {
  getDatabase,
  ref,
  onValue,
  orderByChild,
  equalTo,
  query,
} from "firebase/database";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Search from "../../components/AdminDashboard/Search";
import isTimestampWithinHourAndTenMinutes from "../../utils/iotUptime";
import { useSelector } from "react-redux";

const Iot = () => {
  const auth = useSelector((state) => state.auth);

  const [events, setEvents] = useState([]);
  const [value, setValue] = useState(0);
  const [devices, setDevices] = useState([])
  const [loading, setLoading] = useState(false)
  const [deviceUptimes, setDeviceUptimes] = useState({});
  const [deviceBatteryLevel, setDeviceBatteryLevel] = useState(0);

  const db = getDatabase();
  const tableRef = ref(db, "sensors");
  const tableRef2 = ref(db, "prod-emission-data");
  const listenersRef = useRef([]);


  const loadEvents = async () => {
    setLoading(true);

    const newRef = ref(db, "events");
     onValue(newRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = Object.values(snapshot.val());
        console.log('devices found', data);
        setEvents(data?.reverse())
       
      } else {
        console.log(`No data found`);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    loadEvents()
  }, [])
  useEffect(() => {
    setLoading(true);
   

    const unsubscribe = onValue(tableRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = Object.values(snapshot.val());
        console.log("devices found", data);
        setDevices(data?.reverse());

        // Set up listeners for each device
        data.forEach((device) => {
          const deviceQuery = query(
            tableRef2,
            orderByChild("deviceId"),
            equalTo(device.id)
          );
          const listener = onValue(deviceQuery, (snapshot) => {
            if (snapshot.exists()) {
              const data = Object.values(snapshot.val());
              const mostRecentObject = data.reduce((latest, current) => {
                return parseInt(current.createdAt) > parseInt(latest.createdAt) ? current : latest;
              });

              const isUptime = isTimestampWithinHourAndTenMinutes(mostRecentObject?.createdAt);

              setDeviceUptimes(prev => ({
                ...prev,
                [device.id]: isUptime
              }));
              setDeviceBatteryLevel((prev) => ({
                ...prev,
                [device.id]: mostRecentObject?.batteryLevel,
              }));
            }
          });
          listenersRef.current.push(listener);
        });
      } else {
        console.log(`No data found`);
      }
      setLoading(false);
    });

    // Cleanup function
    return () => {
      unsubscribe();
      listenersRef.current.forEach((listener) => {
        listener(); // Remove the listener
      });
      listenersRef.current = [];
    };
  }, [auth?.userInfo?.id]);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };


  return (
    <div className="admin_iot">
      <div className="sidebar_container">
        <SideBar />
      </div>
     
     <div className="main_container">
      <Search />
     <Tabs
          value={value}
          onChange={handleTabChange}
          aria-label="basic tabs example"
          className="tab_label_container"
        >
          <Tab
            className={value === 0 ? "tab_label tab_active" : "tab_label"}
            label="IoT Health status"
            {...a11yProps(0)}
          />
          <Tab
            className={value === 1 ? "tab_label tab_active last_tab" : "tab_label last_tab"}
            label="Blockchain Events"
            {...a11yProps(1)}
          />
        </Tabs>
     {value === 0 && <div className="main">
        {/* <div className="title">IoT Health status</div> */}
        <div className="iot_table_container">
          <div className="iot_table_header">
            <div>ID</div>
            {/* <div>Organisation</div> */}
            <div>Ping</div>
            <div>Time</div>
            <div>Date</div>
          </div>

         {loading && <>
          <div className="iot_table_body">
            <Skeleton
              width="100%"
            height={15}
            borderRadius={4}
          />
            {/* <div>Reshicnk</div> */}
            <div>
            <Skeleton
              width={15}
            height={15}
            borderRadius={4}
            circle={true}
          />
            </div>
            <Skeleton
              width="100%"
            height={15}
            borderRadius={4}
          />
            <Skeleton
              width="100%"
            height={15}
            borderRadius={4}
          />
          </div>
          <div className="iot_table_body">
            <Skeleton
              width="100%"
            height={15}
            borderRadius={4}
          />
            {/* <div>Reshicnk</div> */}
            <div>
            <Skeleton
              width={15}
            height={15}
            borderRadius={4}
            circle={true}
          />
            </div>
            <Skeleton
              width="100%"
            height={15}
            borderRadius={4}
          />
            <Skeleton
              width="100%"
            height={15}
            borderRadius={4}
          />
          </div>
          <div className="iot_table_body">
            <Skeleton
              width="100%"
            height={15}
            borderRadius={4}
          />
            {/* <div>Reshicnk</div> */}
            <div>
            <Skeleton
              width={15}
            height={15}
            borderRadius={4}
            circle={true}
          />
            </div>
            <Skeleton
              width="100%"
            height={15}
            borderRadius={4}
          />
            <Skeleton
              width="100%"
            height={15}
            borderRadius={4}
          />
          </div>
          <div className="iot_table_body">
            <Skeleton
              width="100%"
            height={15}
            borderRadius={4}
          />
            {/* <div>Reshicnk</div> */}
            <div>
            <Skeleton
              width={15}
            height={15}
            borderRadius={4}
            circle={true}
          />
            </div>
            <Skeleton
              width="100%"
            height={15}
            borderRadius={4}
          />
            <Skeleton
              width="100%"
            height={15}
            borderRadius={4}
          />
          </div>
          <div className="iot_table_body">
            <Skeleton
              width="100%"
            height={15}
            borderRadius={4}
          />
            {/* <div>Reshicnk</div> */}
            <div>
            <Skeleton
              width={15}
            height={15}
            borderRadius={4}
            circle={true}
          />
            </div>
            <Skeleton
              width="100%"
            height={15}
            borderRadius={4}
          />
            <Skeleton
              width="100%"
            height={15}
            borderRadius={4}
          />
          </div>
          
         </>}
          {devices?.map((device) => (
            <div className="iot_table_body">
            <div>{device?.id}</div>
            {/* <div>Reshicnk</div>map */}
            <div>
            <Tooltip
                  title={
                    <Typography variant="h6">
                      {deviceUptimes[device.id]
                        ? "IoT device is active"
                        : "IoT device is experiencing some issues please contact support"}
                    </Typography>
                  }
                >
              <div className={deviceUptimes[device.id] ?"ping ping-active" : "ping ping-inactive"}></div>
              </Tooltip>

              <div className="card__battery-level">
                  Battery level: <span>{deviceBatteryLevel[device.id]}%</span>
                </div>
                
            </div>
            <div>{moment(parseInt(device?.createdAt)).format('hh:mm A')}</div>
            <div>{moment(parseInt(device?.createdAt)).format('Do MMM YYYY')}</div>
          </div>
          ))}
          
        </div>
      </div>}
      {value === 1 && <div className="main">
        {/* <div className="title">Blockchain Events</div> */}
        <div className="iot_table_container">
          <div className="iot_table_header blockchain">
            <div>Event</div>
            <div>Address</div>
            <div>Date</div>
            <div>Time</div>
          </div>

         {loading && <>
          <div className="iot_table_body blockchain">
          <Skeleton
              width="60%"
            height={15}
            borderRadius={4}
          />
            {/* <div>Reshicnk</div> */}
            <Skeleton
              width="100%"
            height={15}
            borderRadius={4}
          />
             <Skeleton
              width="60%"
            height={15}
            borderRadius={4}
          />
             <Skeleton
              width="60%"
            height={15}
            borderRadius={4}
          />
          </div>
          <div className="iot_table_body blockchain">
          <Skeleton
              width="60%"
            height={15}
            borderRadius={4}
          />
            {/* <div>Reshicnk</div> */}
            <Skeleton
              width="100%"
            height={15}
            borderRadius={4}
          />
             <Skeleton
              width="60%"
            height={15}
            borderRadius={4}
          />
             <Skeleton
              width="60%"
            height={15}
            borderRadius={4}
          />
          </div>
          <div className="iot_table_body blockchain">
          <Skeleton
              width="60%"
            height={15}
            borderRadius={4}
          />
            {/* <div>Reshicnk</div> */}
            <Skeleton
              width="100%"
            height={15}
            borderRadius={4}
          />
             <Skeleton
              width="60%"
            height={15}
            borderRadius={4}
          />
             <Skeleton
              width="60%"
            height={15}
            borderRadius={4}
          />
          </div>
          <div className="iot_table_body blockchain">
          <Skeleton
              width="60%"
            height={15}
            borderRadius={4}
          />
            {/* <div>Reshicnk</div> */}
            <Skeleton
              width="100%"
            height={15}
            borderRadius={4}
          />
             <Skeleton
              width="60%"
            height={15}
            borderRadius={4}
          />
             <Skeleton
              width="60%"
            height={15}
            borderRadius={4}
          />
          </div>
          
         </>}
          {events?.map((event) => <div className="iot_table_body blockchain">
            <div>{event?.type}</div>
            <div className="address">{event?.addressToRecord}</div>
            {/* <div>
              <div className="ping"></div>
            </div> */}
            <div>{moment(parseInt(event?.timestamp) * 1000).format('Do MMM YYYY')}</div>
            <div>{moment(parseInt(event?.timestamp) * 1000).format('hh:mm A')}</div>
            {/* <div>01/11/2023</div> */}
          </div>)}
        
          
        </div>
      </div>}
     </div>
    </div>
  );
};

export default Iot;
