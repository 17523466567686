import { useEffect, useRef, useState } from "react";
import { InputLabel, OutlinedInput, Tooltip, Typography } from "@mui/material";
import "../../styles/UserDashboard/Kyc.scss";
import {
  requestVerificationToken,
  submitKycBasicGreen,
  submitKycBasicNongreen,
  submitKycGovernanceGreen,
  submitKycGovernanceMonitoring,
  submitKycGovernanceNongreen,
} from "../../apis/UserDashboard/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import { getDatabase, push, ref } from "firebase/database";
import { getKyc, getUserInfo } from "../../apis/General/users";
import { Onfido } from "onfido-sdk-ui";
import {
  createApplicant,
  createCheck,
  getAuthToken,
  getCheck,
} from "../../apis/General/idVerification";
import jwt_decode from "jwt-decode";
import { useLocation } from "react-router-dom";

const Kyc = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);
  const [modalOpen, setModalOpen] = useState(false);
  const [facilityName, setFacilityName] = useState("");
  const [modalOpenThree, setModalOpenThree] = useState(false);
  const [facilityDescription, setFacilityDescription] = useState("");
  const [facilityLocation, setFacilityLocation] = useState("");
  const [quantifiedBasedYearEmission, setQuantifiedBasedYearEmission] =
    useState("");
  const [publicKey, setPublicKey] = useState("");
  const [greenProjectLicenseId, setGreenProjectLicenseId] = useState("");
  const [subscriptionPlan, setSubscriptionPlan] = useState("");
  // const [workflowRunId, setWorkflowRunId] = useState("");
  const [facilityCategory, setFacilityCategory] = useState("");

  const [organizationBoundary, setOrganizationBoundary] = useState("");
  const [fuelSource, setFuelSource] = useState("");
  const [fuelSourceBaseline, setFuelSourceBaseline] = useState("");
  const [additonalInfo, setAdditonalInfo] = useState("");
  const [sustainabilitySolution, setSustainabilitySolution] = useState("");
  const [baseline, setBaseline] = useState("");
  const [reportingBoundary, setReportingBoundary] = useState("");
  const [historicalInventory, setHistoricalInventory] = useState("");
  const [projectStartDate, setProjectStartDate] = useState("");
  const [projectEndDate, setProjectEndDate] = useState("");
  const [typeofFuel, setTypeofFuel] = useState("");
  const [facilityType, setFacilityType] = useState("");
  const [scenario, setScenario] = useState("");
  const [loading, setLoading] = useState("");
  const [documentType, setDocumentType] = useState("");
  const [images, setImages] = useState([]);
  const [uplaodLoading, setUploadLoading] = useState(false);
  const [idVerificationModal, setIdVerificationModal] = useState(false);
  const [doubleCounting, setDoubleCounting] = useState(false);
  const mediaRef = useRef(null);
  const [
    descriptionOfRenewableEnergySources,
    setDescriptionOfRenewableEnergySources,
  ] = useState("");
  const [projectMonitoringReport, setProjectMonitoringReport] = useState("");
  const [projectDescriptionReport, setProjectDescriptionReport] = useState("");
  const [renewableEnergySolutions, setRenewableEnergySolutions] = useState("");
  const [onfidoInstance, setOnfidoInstance] = useState(null);

  const [failedIdVerificationModal, setFailedIdVerificationModal] =
    useState(false);
  // const [onfidoToken, setOnfidoToken] = useState(false);
  const [preOnfidoInitDone, setPreOnfidoInitDone] = useState(false);
  const db = getDatabase();
  let retryCount = 0;
  const MAX_RETRIES = 3;

  const createWorkflowRun = async () => {
    const response3 = await getAuthToken(
      auth?.userInfo?.trdPtyKycAppId, //applicant id
      "8d1f453f-991f-457f-bec6-283866d59612", //workflow id
      auth?.token
    );
    console.log("response3?.data?.message?.token", response3);
    if (response3?.data?.statusCode === 200) {
      localStorage.removeItem("token");
      localStorage.setItem("token", response3?.data?.message);

      dispatch({
        type: "USER_LOGIN_SUCCESS",
        payload: {
          token: response3?.data?.message,
        },
      });
      initOnfido(
        response3?.data?.onfidoResp?.sdk_token,
        response3?.data?.onfidoResp?.id
      );
    }
  };

  const preInitOnfido = async () => {
    // setLoading(true)
    console.log("got here");
    try {
      // setLoading(true);
      console.log("started1");

      if (!auth?.userInfo?.trdPtyKycAppId) {
        const response1 = await getUserInfo(auth?.userInfo?.id, auth?.token);
        console.log("response1", response1);
        const response2 = await createApplicant(
          response1?.data?.message?.firstName,
          response1?.data?.message?.lastName,
          response1?.data?.message?.email,
          auth?.token
        );

        console.log("create applicant", response2);

        if (response2?.data?.statusCode === 200) {
          localStorage.removeItem("token");
          localStorage.setItem("token", response2?.data?.message);

          dispatch({
            type: "USER_LOGIN_SUCCESS",
            payload: {
              token: response2?.data?.message,
            },
          });
          console.log("auth", auth);
        } else {
          toast.error(response2?.data?.message);
          // setLoading(false);
          return;
        }
      }

      createWorkflowRun();
    } catch (err) {
      console.log("err:", err.message, err.request);
      // setLoading(false);
        return;
    }
  };
  const initOnfido = async (token, workflowRunId) => {
    // setLoading(true);

    if (onfidoInstance) {
      console.log("Onfido instance already exists. Skipping initialization.");
      // setLoading(false)
      return;
    }

    setIdVerificationModal(true);
    console.log("massive", token, workflowRunId);
    try {
      onfidoInstance = Onfido.init({
        // useModal: false,
        token,
        containerId: "onfido-mount",
        onComplete: async (data) => {
          setTimeout(async () => {
            console.log("Everything is complete", data);
            setIdVerificationModal(false);
            toast.success("ID Verification in progress");
            push(ref(db, "notifications"), {
              type: "ID Verification in progress",
              read: false,
              user: auth?.userInfo?.id,
              timestamp: Date.now(),
            });
            const response = await getCheck(
              auth?.userInfo?.kycCheckId,
              auth?.token
            );
            console.log("response check onfido", response);
            console.log("jwt_decode(response?.data?.message)?.trdPtyKycStatus", jwt_decode(response?.data?.message)?.trdPtyKycStatus);
              console.log("get over here");
              localStorage.removeItem("token");
              localStorage.setItem("token", response?.data?.message);
              dispatch({
                type: "USER_LOGIN_SUCCESS",
                payload: {
                  token: response?.data?.message,
                },
              });
          }, 4000);
        },
        onError: async (error) => {
          console.log("error from Onfido", error);
          if (
            // (error?.type === "invalid_token" ||
            //   error?.type === "expired_token") &&
            retryCount < MAX_RETRIES
          ) {
            retryCount++;
            // if (auth?.userInfo?.trdPtyKycStatus === "awaiting_input") {
            //   //run request token function before initOnfido
            //   const response = await requestVerificationToken(
            //     auth?.userInfo?.trdPtyKycAppId,
            //     auth?.token
            //   );
            //   console.log("new token from onfido", response);
            //   console.log(
            //     "response?.data?.onfidoResp?.token",
            //     response?.data?.onfidoResp?.token
            //   );
            //   if (response?.status === 200) {
            //     initOnfido(
            //       auth?.userInfo?.kycCheckId,
            //       response?.data?.onfidoResp?.token
            //     );
            //   } else {
            //     alert("Unable to retrieve new token. Please contact support");
            //   }
            // } else {
            //   preInitOnfido();
            // }
            preInitOnfido();
          } else {
            alert("Max retries reached");
            console.log("Max retries reached");
            setIdVerificationModal(false);
            // Handle the error appropriately
          }
        },
        workflowRunId,
      });
    } catch (error) {
      console.log("there is error", error);
    }
    
    // setLoading(false);
  };

  // useEffect(() => {
  //   preInitOnfido();
  // }, []);
  // useEffect(() => {
  //   initOnfido();
  //   return () => {
  //     console.log("tear down", onfidoInstance);
  //     onfidoInstance && onfidoInstance.tearDown();
  //   };
  //   // }
  // }, [preOnfidoInitDone]);

  const checkOnfidoKycStatus = async () => {
    console.log("deyyah");
    const response = await getCheck(
      auth?.userInfo?.kycCheckId,
      auth?.token
    );
    dispatch({
      type: "USER_LOGIN_SUCCESS",
      payload: {
        token: response?.data?.message,
      },
    });
    const currenStatus = jwt_decode(response?.data?.message)?.trdPtyKycStatus;
    console.log('currenStatus', currenStatus);
    
    if (currenStatus === "approved") {
      return;
    } 
    else if (
      currenStatus === "declined" ||
      currenStatus === "error" ||
      currenStatus === "abandoned" ||
      currenStatus === "n/a" ||
      currenStatus === "review"
    ) {
      toast.error("ID verification failed. Please try again")
      preInitOnfido();
    } 
    else if (
      currenStatus === "processing"
    ) {
      return;
    } else if (currenStatus === "awaiting_input") {
      console.log("auth for onfido", auth);
      //run request token function before initOnfido
      initOnfido(auth?.userInfo?.onfidoSdkToken, auth?.userInfo?.kycCheckId);
    }
  };

  useEffect(() => {
    console.log("auth", auth);
    if (auth?.userInfo?.kycStatus !== "N/A") {
    checkOnfidoKycStatus();
    }
  }, [location]);

  useEffect(() => {
    if (auth?.userInfo?.kycStatus === "N/A") {
      setModalOpen(true);
    }
  }, []);

  const handlegetKyc = async () => {
    const response = await getKyc(auth?.userInfo?.id, auth?.token);
    console.log("kyc for user", response);

    setSubscriptionPlan(response?.data?.message?.subscriptionPlan);
    setFacilityCategory(response?.data?.message?.facilityCategory);
  };

  useEffect(() => {
    handlegetKyc();
  });

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = (e) => {
    if (e.target.classList.contains("kyc_modal_container")) {
      setModalOpen(false);
    }
  };

  const handleModalOpenThree = () => {
    setModalOpenThree(true);
  };

  const handleModalCloseThree = (e) => {
    if (e.target.classList.contains("kyc_modal_container")) {
      setModalOpenThree(false);
    }
  };
  const handleKycSubmit = async (e) => {
    e.preventDefault();
    if (doubleCounting === "true") {
      alert(
        "Projects receiving or seeking credit for reductions and removals from a project activity under another GHG program are not accepted on CloseCarbon"
      );
      return;
    } else if (Date.now() > Date.parse(projectEndDate)) {
      alert("Project end date can not be in the past");
      return;
    } else if (Date.parse(projectStartDate) > Date.parse(projectEndDate)) {
      alert("Project start date can not be ahead of Project end date ");
      return;
    }
    setLoading(true);
    let response;
    if (subscriptionPlan === "basic" && facilityCategory === "green") {
      response = await submitKycBasicGreen(
        facilityCategory,
        subscriptionPlan,
        facilityName,
        facilityDescription,
        facilityLocation,
        greenProjectLicenseId,
        projectStartDate,
        projectEndDate,
        renewableEnergySolutions,
        baseline,
        fuelSourceBaseline,
        additonalInfo,
        auth?.token
      );
    } else if (
      subscriptionPlan === "basic" &&
      facilityCategory === "non-green"
    ) {
      response = await submitKycBasicNongreen(
        facilityCategory,
        subscriptionPlan,
        facilityName,
        facilityDescription,
        facilityLocation,
        projectStartDate,
        projectEndDate,
        fuelSource,
        baseline,
        fuelSourceBaseline,
        additonalInfo,
        sustainabilitySolution,
        auth?.token
      );
    } else if (
      subscriptionPlan === "governance" &&
      facilityCategory === "carbon-monitoring"
    ) {
      response = await submitKycGovernanceMonitoring(
        facilityCategory,
        subscriptionPlan,
        facilityName,
        facilityDescription,
        facilityLocation,
        projectStartDate,
        projectEndDate,
        auth?.token
      );
    } else if (
      subscriptionPlan === "governance" &&
      facilityCategory === "non-green"
    ) {
      response = await submitKycGovernanceNongreen(
        facilityCategory,
        subscriptionPlan,
        facilityName,
        facilityDescription,
        facilityLocation,
        projectStartDate,
        projectEndDate,
        baseline,
        fuelSourceBaseline,
        additonalInfo,
        sustainabilitySolution,
        auth?.token
      );
    } else if (
      subscriptionPlan === "governance" &&
      facilityCategory === "green"
    ) {
      response = await submitKycGovernanceGreen(
        facilityCategory,
        subscriptionPlan,
        facilityName,
        facilityDescription,
        facilityLocation,
        greenProjectLicenseId,
        projectStartDate,
        projectEndDate,
        renewableEnergySolutions,
        baseline,
        fuelSourceBaseline,
        additonalInfo,
        auth?.token
      );
    }
    console.log("response kyc", response);

    if (response?.data?.statusCode === 200) {
      toast.success(response?.data?.message);
      localStorage.removeItem("token");
      localStorage.setItem("token", response?.data?.token);

      dispatch({
        type: "USER_LOGIN_SUCCESS",
        payload: {
          token: response?.data?.token,
        },
      });

      push(ref(db, "notifications"), {
        type: "Incomplete Profile",
        read: false,
        user: auth?.userInfo?.id,
        timestamp: Date.now(),
      });

      push(ref(db, "notifications"), {
        type: "Validation in progress",
        read: false,
        user: auth?.userInfo?.id,
        timestamp: Date.now(),
      });
      push(ref(db, "kycStatus"), {
        status: "pending",
        user: auth?.userInfo?.id,
        timestamp: Date.now(),
      });
      setModalOpen(false);
    } else if (response?.data?.message === "KYC already submmitted") {
      toast.error(response?.data?.message);
      // dispatch({
      //   type: "KYC_SUBMIT_SUCCESS",
      //   payload: {
      //     token: auth?.token,
      //     data: {
      //       kycStatus: "pending",
      //     },
      //   },
      // });
    } else {
      toast.error("Something went wrong");
    }
    setLoading(false);
  };

  const handleKycTwoSubmit = () => {
    if (facilityType === "") {
      alert("Please select a facility type(Green or Not Green)");
    } else if (scenario === "") {
      alert("Please select a carbon goal");
    } else {
      setModalOpenThree(false);
      handleModalOpen();
    }
  };

  const fileUpload = (e, documentTypeSent) => {
    setUploadLoading(true);

    let files = e;
    let allUploadedFiles = images;

    const fileToUri = (file, cb) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        cb(null, reader.result);
      };
      reader.onerror = function (error) {
        cb(error, null);
      };
    };

    if (files) {
      for (let i = 0; i < files.length; i++) {
        fileToUri(files[0], (err, result) => {
          if (result) {
            axios
              .post(
                `${process.env.REACT_APP_DEV_URL}/open/v1/upload`,
                {
                  media_type: "pdf",
                  folder: "documents",
                  user_id: auth?.userInfo?.id,
                  contents: [result],
                },
                {
                  headers: {
                    Authorization: `Bearer ${auth ? auth.token : ""}`,
                  },
                }
              )
              .then((response) => {
                console.log("merddian", response);
                if (response?.data?.body?.success === true) {
                  if (documentTypeSent === "organizationBoundary") {
                    setOrganizationBoundary(response?.data?.body?.data[0]);
                  } else if (documentTypeSent === "reportingBoundary") {
                    setReportingBoundary(response?.data?.body?.data[0]);
                  } else if (documentTypeSent === "historicalInventory") {
                    setHistoricalInventory(response?.data?.body?.data[0]);
                  } else if (documentTypeSent === "typeofFuel") {
                    setTypeofFuel(response?.data?.body?.data[0]);
                  } else if (
                    documentTypeSent === "descriptionOfRenewableEnergySources"
                  ) {
                    setDescriptionOfRenewableEnergySources(
                      response?.data?.body?.data[0]
                    );
                  } else if (documentTypeSent === "projectMonitoringReport") {
                    setProjectMonitoringReport(response?.data?.body?.data[0]);
                  } else if (documentTypeSent === "projectDescriptionReport") {
                    setProjectDescriptionReport(response?.data?.body?.data[0]);
                  } else {
                    toast.error("document type not specified");
                  }
                }
                setUploadLoading(false);
              })
              .catch((err) => {
                setUploadLoading(false);
              });
          }
        });
      }
    }
  };

  const clickFileUpload = (e, documentTypeSent) => {
    setUploadLoading(true);

    let files = e.target.files;
    let allUploadedFiles = images;

    const fileToUri = (file, cb) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        cb(null, reader.result);
      };
      reader.onerror = function (error) {
        cb(error, null);
      };
    };

    if (files) {
      for (let i = 0; i < files.length; i++) {
        fileToUri(files[0], (err, result) => {
          if (result) {
            axios
              .post(
                `${process.env.REACT_APP_DEV_URL}/open/v1/upload`,
                {
                  media_type: "pdf",
                  folder: "documents",
                  user_id: auth?.userInfo?.id,
                  contents: [result],
                },
                {
                  headers: {
                    Authorization: `Bearer ${auth ? auth.token : ""}`,
                  },
                }
              )
              .then((response) => {
                console.log("response", response);
                if (response?.data?.body?.success === true) {
                  if (documentTypeSent === "organizationBoundary") {
                    setOrganizationBoundary(response?.data?.body?.data[0]);
                  } else if (documentTypeSent === "reportingBoundary") {
                    setReportingBoundary(response?.data?.body?.data[0]);
                  } else if (documentTypeSent === "historicalInventory") {
                    setHistoricalInventory(response?.data?.body?.data[0]);
                  } else if (documentTypeSent === "typeofFuel") {
                    setTypeofFuel(response?.data?.body?.data[0]);
                  } else if (
                    documentTypeSent === "descriptionOfRenewableEnergySources"
                  ) {
                    setDescriptionOfRenewableEnergySources(
                      response?.data?.body?.data[0]
                    );
                  } else if (documentTypeSent === "projectMonitoringReport") {
                    setProjectMonitoringReport(response?.data?.body?.data[0]);
                  } else if (documentTypeSent === "projectDescriptionReport") {
                    setProjectDescriptionReport(response?.data?.body?.data[0]);
                  } else {
                    toast.error("document type not specified");
                  }
                }
                setUploadLoading(false);
              })
              .catch((err) => {
                setUploadLoading(false);
              });
          }
        });
      }
    }
  };

  const textValidation = (text, functionToCall) => {
    // Use regex to test for only letters
    const isLettersOnly = /^[A-Za-z'\s!@#$%^&*()_+=\[{\]};:<>,.?/\\|]+$/.test(
      text
    );
    if (text === "") {
      functionToCall(text);
    } else if (isLettersOnly) {
      functionToCall(text);
    } else {
      // alert('Please enter letters only');
    }
  };
  const numberValidation = (number, functionToCall) => {
    // Use regex to test for only letters
    const isLettersOnly = /^[-+]?[0-9.]+$/.test(number);

    if (number === "") {
      functionToCall(number);
    } else if (isLettersOnly) {
      functionToCall(number);
    } else {
      // alert('Please enter letters only');
    }
  };

  const addressValidation = (address, functionToCall) => {
    // Basic validation

    return true;
  };

  useEffect(() => {
    let dropAreas = document.querySelectorAll("#drag_drop");
    console.log("dropAreasdqew", dropAreas);
    dropAreas.forEach((dropArea) => {
      console.log("dropArea", dropArea);
      let uploadArea = document.querySelector(".admin_upload-area");
      ["dragenter", "dragover", "dragleave", "drop"].forEach((eventName) => {
        dropArea.addEventListener(eventName, preventDefaults, false);
      });

      function preventDefaults(e) {
        e.preventDefault();
        e.stopPropagation();
      }

      ["dragenter", "dragover"].forEach((eventName) => {
        dropArea.addEventListener(eventName, highlight, false);
      });
      ["dragleave", "drop"].forEach((eventName) => {
        dropArea.addEventListener(eventName, unhighlight, false);
      });

      function highlight(e) {
        dropArea.classList.add("highlight");
        console.log("hunm", e);
        console.log("dropArea?.dataset", dropArea?.dataset);
      }

      function unhighlight(e) {
        dropArea.classList.remove("highlight");
      }

      dropArea.addEventListener("drop", handleDrop, false);

      function handleDrop(e) {
        console.log("dave dropped");
        console.log("e", e);
        console.log("e?.target", e?.target);
        console.log("e.target.files", e.target.files);
        console.log("e.dataTransfer", e.dataTransfer);
        console.log("e.dataTransfer.files", e.dataTransfer.files);
        if (dropArea?.dataset?.doctype === "organizationBoundary") {
          fileUpload(e.dataTransfer.files, "organizationBoundary");
          setDocumentType("organizationBoundary");
        } else if (dropArea?.dataset?.doctype === "historicalInventory") {
          fileUpload(e.dataTransfer.files, "historicalInventory");
          setDocumentType("historicalInventory");
        } else if (dropArea?.dataset?.doctype === "typeofFuel") {
          fileUpload(e.dataTransfer.files, "typeofFuel");
          setDocumentType("typeofFuel");
        } else if (dropArea?.dataset?.doctype === "projectDescriptionReport") {
          fileUpload(e.dataTransfer.files, "projectDescriptionReport");
          setDocumentType("projectDescriptionReport");
        } else if (dropArea?.dataset?.doctype === "projectMonitoringReport") {
          fileUpload(e.dataTransfer.files, "projectMonitoringReport");
          setDocumentType("projectMonitoringReport");
        } else if (
          dropArea?.dataset?.doctype === "descriptionOfRenewableEnergySources"
        ) {
          fileUpload(
            e.dataTransfer.files,
            "descriptionOfRenewableEnergySources"
          );
          setDocumentType("descriptionOfRenewableEnergySources");
        }
        // fileUpload(e.dataTransfer.files[0]);
        // console.log("e.dataTransfer", e.dataTransfer.files);
      }
    });
  }, [modalOpen]);

  const handleFailedIdVerificationModalOpen = () => {
    setFailedIdVerificationModal(true);
  };

  const handleFailedIdVerificationModalClose = (e) => {
    if (e.target.classList.contains("failed-id")) {
      setFailedIdVerificationModal(false);
    }
  };

  console.log("automatic", auth);
  console.log(
    'auth?.userInfo?.trdPtyKycStatus === "in_progress"',
    auth?.userInfo?.trdPtyKycStatus === "in_progress"
  );

  return (
    <>
      {modalOpen && (
        <div
          className="kyc_modal_container"
          // onClick={handleModalClose}
        >
          <form className="kyc_modal" onSubmit={handleKycSubmit}>
            <div className="title">Step into the future...</div>
            <div className="subtitle">
              Complete this KYC step, your safety our priority
            </div>
            <div className="kyc_grid">
              <div>
                <InputLabel
                  className="kyc_label"
                  htmlFor="organizationDescription"
                >
                  {facilityCategory === "non-green"
                    ? "Facility/Project name"
                    : facilityCategory === "carbon-monitoring"
                    ? "Facility name"
                    : "Green project name"}
                  <Tooltip
                    title={
                      <Typography variant="h6">
                        Name of project or facility
                      </Typography>
                    }
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="-0.5 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <path
                          d="M12 21.5C17.1086 21.5 21.25 17.3586 21.25 12.25C21.25 7.14137 17.1086 3 12 3C6.89137 3 2.75 7.14137 2.75 12.25C2.75 17.3586 6.89137 21.5 12 21.5Z"
                          stroke="#000000"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>{" "}
                        <path
                          d="M12.9309 8.15005C12.9256 8.39231 12.825 8.62272 12.6509 8.79123C12.4767 8.95974 12.2431 9.05271 12.0008 9.05002C11.8242 9.04413 11.6533 8.98641 11.5093 8.884C11.3652 8.7816 11.2546 8.63903 11.1911 8.47415C11.1275 8.30927 11.1139 8.12932 11.152 7.95675C11.19 7.78419 11.278 7.6267 11.405 7.50381C11.532 7.38093 11.6923 7.29814 11.866 7.26578C12.0397 7.23341 12.2192 7.25289 12.3819 7.32181C12.5446 7.39072 12.6834 7.506 12.781 7.65329C12.8787 7.80057 12.9308 7.97335 12.9309 8.15005ZM11.2909 16.5301V11.1501C11.2882 11.0556 11.3046 10.9615 11.3392 10.8736C11.3738 10.7857 11.4258 10.7057 11.4922 10.6385C11.5585 10.5712 11.6378 10.518 11.7252 10.4822C11.8126 10.4464 11.9064 10.4286 12.0008 10.43C12.094 10.4299 12.1863 10.4487 12.272 10.4853C12.3577 10.5218 12.4352 10.5753 12.4997 10.6426C12.5642 10.7099 12.6143 10.7895 12.6472 10.8767C12.6801 10.9639 12.6949 11.0569 12.6908 11.1501V16.5301C12.6908 16.622 12.6727 16.713 12.6376 16.7979C12.6024 16.8828 12.5508 16.96 12.4858 17.025C12.4208 17.09 12.3437 17.1415 12.2588 17.1767C12.1738 17.2119 12.0828 17.23 11.9909 17.23C11.899 17.23 11.8079 17.2119 11.723 17.1767C11.6381 17.1415 11.5609 17.09 11.4959 17.025C11.4309 16.96 11.3793 16.8828 11.3442 16.7979C11.309 16.713 11.2909 16.622 11.2909 16.5301Z"
                          fill="#000000"
                        ></path>{" "}
                      </g>
                    </svg>
                  </Tooltip>
                </InputLabel>

                <OutlinedInput
                  className="kyc_input"
                  required
                  id="organizationDescription"
                  type="text"
                  placeholder="Enter facility/project name"
                  name="organizationDescription"
                  value={facilityName}
                  onChange={(e) => setFacilityName(e.target.value)}
                />
              </div>
              <div>
                <InputLabel
                  className="kyc_label"
                  htmlFor="organizationDescription"
                >
                  {facilityCategory === "non-green"
                    ? "Facility/Project description"
                    : facilityCategory === "carbon-monitoring"
                    ? "Facility description"
                    : "Green project description"}

                  <Tooltip
                    title={
                      <Typography variant="h6">
                        A brief explanation of what your facility does
                      </Typography>
                    }
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="-0.5 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <path
                          d="M12 21.5C17.1086 21.5 21.25 17.3586 21.25 12.25C21.25 7.14137 17.1086 3 12 3C6.89137 3 2.75 7.14137 2.75 12.25C2.75 17.3586 6.89137 21.5 12 21.5Z"
                          stroke="#000000"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>{" "}
                        <path
                          d="M12.9309 8.15005C12.9256 8.39231 12.825 8.62272 12.6509 8.79123C12.4767 8.95974 12.2431 9.05271 12.0008 9.05002C11.8242 9.04413 11.6533 8.98641 11.5093 8.884C11.3652 8.7816 11.2546 8.63903 11.1911 8.47415C11.1275 8.30927 11.1139 8.12932 11.152 7.95675C11.19 7.78419 11.278 7.6267 11.405 7.50381C11.532 7.38093 11.6923 7.29814 11.866 7.26578C12.0397 7.23341 12.2192 7.25289 12.3819 7.32181C12.5446 7.39072 12.6834 7.506 12.781 7.65329C12.8787 7.80057 12.9308 7.97335 12.9309 8.15005ZM11.2909 16.5301V11.1501C11.2882 11.0556 11.3046 10.9615 11.3392 10.8736C11.3738 10.7857 11.4258 10.7057 11.4922 10.6385C11.5585 10.5712 11.6378 10.518 11.7252 10.4822C11.8126 10.4464 11.9064 10.4286 12.0008 10.43C12.094 10.4299 12.1863 10.4487 12.272 10.4853C12.3577 10.5218 12.4352 10.5753 12.4997 10.6426C12.5642 10.7099 12.6143 10.7895 12.6472 10.8767C12.6801 10.9639 12.6949 11.0569 12.6908 11.1501V16.5301C12.6908 16.622 12.6727 16.713 12.6376 16.7979C12.6024 16.8828 12.5508 16.96 12.4858 17.025C12.4208 17.09 12.3437 17.1415 12.2588 17.1767C12.1738 17.2119 12.0828 17.23 11.9909 17.23C11.899 17.23 11.8079 17.2119 11.723 17.1767C11.6381 17.1415 11.5609 17.09 11.4959 17.025C11.4309 16.96 11.3793 16.8828 11.3442 16.7979C11.309 16.713 11.2909 16.622 11.2909 16.5301Z"
                          fill="#000000"
                        ></path>{" "}
                      </g>
                    </svg>
                  </Tooltip>
                </InputLabel>

                <OutlinedInput
                  className="kyc_input"
                  required
                  id="organizationDescription"
                  type="text"
                  placeholder="Provide a summary description of the facility to enable an understanding of the nature and its implementation."
                  name="organizationDescription"
                  value={facilityDescription}
                  onChange={(e) => setFacilityDescription(e.target.value)}
                />
                <div className="kyc_instructions">
                  <div className="kyc_instructions__title">
                    Include the following:
                  </div>
                  <div className="kyc_instructions__subtitle">
                    <svg
                      width="4"
                      height="4"
                      viewBox="0 0 4 4"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="2" cy="2" r="2" fill="#D9D9D9" />
                    </svg>
                    The objectives of the project
                  </div>
                  <div className="kyc_instructions__subtitle">
                    <svg
                      width="4"
                      height="4"
                      viewBox="0 0 4 4"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="2" cy="2" r="2" fill="#D9D9D9" />
                    </svg>
                    Describe the project activity or activities
                  </div>
                  <div className="kyc_instructions__subtitle">
                    <svg
                      width="4"
                      height="4"
                      viewBox="0 0 4 4"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="2" cy="2" r="2" fill="#D9D9D9" />
                    </svg>
                    An estimate of annual average, total reductions and removals
                  </div>
                </div>
              </div>
              <div>
                <InputLabel className="kyc_label" htmlFor="location">
                  {facilityCategory === "non-green"
                    ? "Facility/Project location"
                    : facilityCategory === "carbon-monitoring"
                    ? "Facility location"
                    : "Green project location"}
                  <Tooltip
                    title={
                      <Typography variant="h6">
                        A precise location of your facility
                      </Typography>
                    }
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="-0.5 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <path
                          d="M12 21.5C17.1086 21.5 21.25 17.3586 21.25 12.25C21.25 7.14137 17.1086 3 12 3C6.89137 3 2.75 7.14137 2.75 12.25C2.75 17.3586 6.89137 21.5 12 21.5Z"
                          stroke="#000000"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>{" "}
                        <path
                          d="M12.9309 8.15005C12.9256 8.39231 12.825 8.62272 12.6509 8.79123C12.4767 8.95974 12.2431 9.05271 12.0008 9.05002C11.8242 9.04413 11.6533 8.98641 11.5093 8.884C11.3652 8.7816 11.2546 8.63903 11.1911 8.47415C11.1275 8.30927 11.1139 8.12932 11.152 7.95675C11.19 7.78419 11.278 7.6267 11.405 7.50381C11.532 7.38093 11.6923 7.29814 11.866 7.26578C12.0397 7.23341 12.2192 7.25289 12.3819 7.32181C12.5446 7.39072 12.6834 7.506 12.781 7.65329C12.8787 7.80057 12.9308 7.97335 12.9309 8.15005ZM11.2909 16.5301V11.1501C11.2882 11.0556 11.3046 10.9615 11.3392 10.8736C11.3738 10.7857 11.4258 10.7057 11.4922 10.6385C11.5585 10.5712 11.6378 10.518 11.7252 10.4822C11.8126 10.4464 11.9064 10.4286 12.0008 10.43C12.094 10.4299 12.1863 10.4487 12.272 10.4853C12.3577 10.5218 12.4352 10.5753 12.4997 10.6426C12.5642 10.7099 12.6143 10.7895 12.6472 10.8767C12.6801 10.9639 12.6949 11.0569 12.6908 11.1501V16.5301C12.6908 16.622 12.6727 16.713 12.6376 16.7979C12.6024 16.8828 12.5508 16.96 12.4858 17.025C12.4208 17.09 12.3437 17.1415 12.2588 17.1767C12.1738 17.2119 12.0828 17.23 11.9909 17.23C11.899 17.23 11.8079 17.2119 11.723 17.1767C11.6381 17.1415 11.5609 17.09 11.4959 17.025C11.4309 16.96 11.3793 16.8828 11.3442 16.7979C11.309 16.713 11.2909 16.622 11.2909 16.5301Z"
                          fill="#000000"
                        ></path>{" "}
                      </g>
                    </svg>
                  </Tooltip>
                </InputLabel>

                <OutlinedInput
                  className="kyc_input"
                  required
                  id="location"
                  type="text"
                  placeholder="Enter facility/project address"
                  name="location"
                  value={facilityLocation}
                  onChange={(e) => setFacilityLocation(e.target.value)}
                />
              </div>
              {facilityCategory === "green" && (
                <div>
                  <InputLabel className="kyc_label" htmlFor="location">
                    Green project license ID
                    <Tooltip
                      title={
                        <Typography variant="h6">
                          The license Id issued for your green project
                        </Typography>
                      }
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="-0.5 0 25 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <path
                            d="M12 21.5C17.1086 21.5 21.25 17.3586 21.25 12.25C21.25 7.14137 17.1086 3 12 3C6.89137 3 2.75 7.14137 2.75 12.25C2.75 17.3586 6.89137 21.5 12 21.5Z"
                            stroke="#000000"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>{" "}
                          <path
                            d="M12.9309 8.15005C12.9256 8.39231 12.825 8.62272 12.6509 8.79123C12.4767 8.95974 12.2431 9.05271 12.0008 9.05002C11.8242 9.04413 11.6533 8.98641 11.5093 8.884C11.3652 8.7816 11.2546 8.63903 11.1911 8.47415C11.1275 8.30927 11.1139 8.12932 11.152 7.95675C11.19 7.78419 11.278 7.6267 11.405 7.50381C11.532 7.38093 11.6923 7.29814 11.866 7.26578C12.0397 7.23341 12.2192 7.25289 12.3819 7.32181C12.5446 7.39072 12.6834 7.506 12.781 7.65329C12.8787 7.80057 12.9308 7.97335 12.9309 8.15005ZM11.2909 16.5301V11.1501C11.2882 11.0556 11.3046 10.9615 11.3392 10.8736C11.3738 10.7857 11.4258 10.7057 11.4922 10.6385C11.5585 10.5712 11.6378 10.518 11.7252 10.4822C11.8126 10.4464 11.9064 10.4286 12.0008 10.43C12.094 10.4299 12.1863 10.4487 12.272 10.4853C12.3577 10.5218 12.4352 10.5753 12.4997 10.6426C12.5642 10.7099 12.6143 10.7895 12.6472 10.8767C12.6801 10.9639 12.6949 11.0569 12.6908 11.1501V16.5301C12.6908 16.622 12.6727 16.713 12.6376 16.7979C12.6024 16.8828 12.5508 16.96 12.4858 17.025C12.4208 17.09 12.3437 17.1415 12.2588 17.1767C12.1738 17.2119 12.0828 17.23 11.9909 17.23C11.899 17.23 11.8079 17.2119 11.723 17.1767C11.6381 17.1415 11.5609 17.09 11.4959 17.025C11.4309 16.96 11.3793 16.8828 11.3442 16.7979C11.309 16.713 11.2909 16.622 11.2909 16.5301Z"
                            fill="#000000"
                          ></path>{" "}
                        </g>
                      </svg>
                    </Tooltip>
                  </InputLabel>

                  <OutlinedInput
                    className="kyc_input"
                    required
                    id="location"
                    type="text"
                    placeholder="Enter green project license ID"
                    name="location"
                    value={greenProjectLicenseId}
                    onChange={(e) => setGreenProjectLicenseId(e.target.value)}
                  />
                </div>
              )}
              <div className="kyc_group">
                <div>
                  <InputLabel className="kyc_label" htmlFor="location">
                    {facilityCategory === "non-green"
                      ? "Facility/Project start date"
                      : facilityCategory === "carbon-monitoring"
                      ? "Facility start date"
                      : "Green project start date"}
                    <Tooltip
                      title={
                        <Typography variant="h6">
                          When did this facility/project start
                        </Typography>
                      }
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="-0.5 0 25 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <path
                            d="M12 21.5C17.1086 21.5 21.25 17.3586 21.25 12.25C21.25 7.14137 17.1086 3 12 3C6.89137 3 2.75 7.14137 2.75 12.25C2.75 17.3586 6.89137 21.5 12 21.5Z"
                            stroke="#000000"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>{" "}
                          <path
                            d="M12.9309 8.15005C12.9256 8.39231 12.825 8.62272 12.6509 8.79123C12.4767 8.95974 12.2431 9.05271 12.0008 9.05002C11.8242 9.04413 11.6533 8.98641 11.5093 8.884C11.3652 8.7816 11.2546 8.63903 11.1911 8.47415C11.1275 8.30927 11.1139 8.12932 11.152 7.95675C11.19 7.78419 11.278 7.6267 11.405 7.50381C11.532 7.38093 11.6923 7.29814 11.866 7.26578C12.0397 7.23341 12.2192 7.25289 12.3819 7.32181C12.5446 7.39072 12.6834 7.506 12.781 7.65329C12.8787 7.80057 12.9308 7.97335 12.9309 8.15005ZM11.2909 16.5301V11.1501C11.2882 11.0556 11.3046 10.9615 11.3392 10.8736C11.3738 10.7857 11.4258 10.7057 11.4922 10.6385C11.5585 10.5712 11.6378 10.518 11.7252 10.4822C11.8126 10.4464 11.9064 10.4286 12.0008 10.43C12.094 10.4299 12.1863 10.4487 12.272 10.4853C12.3577 10.5218 12.4352 10.5753 12.4997 10.6426C12.5642 10.7099 12.6143 10.7895 12.6472 10.8767C12.6801 10.9639 12.6949 11.0569 12.6908 11.1501V16.5301C12.6908 16.622 12.6727 16.713 12.6376 16.7979C12.6024 16.8828 12.5508 16.96 12.4858 17.025C12.4208 17.09 12.3437 17.1415 12.2588 17.1767C12.1738 17.2119 12.0828 17.23 11.9909 17.23C11.899 17.23 11.8079 17.2119 11.723 17.1767C11.6381 17.1415 11.5609 17.09 11.4959 17.025C11.4309 16.96 11.3793 16.8828 11.3442 16.7979C11.309 16.713 11.2909 16.622 11.2909 16.5301Z"
                            fill="#000000"
                          ></path>{" "}
                        </g>
                      </svg>
                    </Tooltip>
                  </InputLabel>

                  <input
                    className="kyc_input kyc_input-date"
                    required
                    id="location"
                    type="date"
                    placeholder="Enter facility/project start date"
                    name="location"
                    value={projectStartDate}
                    onChange={(e) => setProjectStartDate(e.target.value)}
                  />
                </div>
                <div>
                  <InputLabel className="kyc_label" htmlFor="location">
                    {facilityCategory === "non-green"
                      ? "Facility/Project end date"
                      : facilityCategory === "carbon-monitoring"
                      ? "Facility end date"
                      : "Green project end date"}

                    <Tooltip
                      title={
                        <Typography variant="h6">
                          When does this facility/project end
                        </Typography>
                      }
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="-0.5 0 25 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <path
                            d="M12 21.5C17.1086 21.5 21.25 17.3586 21.25 12.25C21.25 7.14137 17.1086 3 12 3C6.89137 3 2.75 7.14137 2.75 12.25C2.75 17.3586 6.89137 21.5 12 21.5Z"
                            stroke="#000000"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>{" "}
                          <path
                            d="M12.9309 8.15005C12.9256 8.39231 12.825 8.62272 12.6509 8.79123C12.4767 8.95974 12.2431 9.05271 12.0008 9.05002C11.8242 9.04413 11.6533 8.98641 11.5093 8.884C11.3652 8.7816 11.2546 8.63903 11.1911 8.47415C11.1275 8.30927 11.1139 8.12932 11.152 7.95675C11.19 7.78419 11.278 7.6267 11.405 7.50381C11.532 7.38093 11.6923 7.29814 11.866 7.26578C12.0397 7.23341 12.2192 7.25289 12.3819 7.32181C12.5446 7.39072 12.6834 7.506 12.781 7.65329C12.8787 7.80057 12.9308 7.97335 12.9309 8.15005ZM11.2909 16.5301V11.1501C11.2882 11.0556 11.3046 10.9615 11.3392 10.8736C11.3738 10.7857 11.4258 10.7057 11.4922 10.6385C11.5585 10.5712 11.6378 10.518 11.7252 10.4822C11.8126 10.4464 11.9064 10.4286 12.0008 10.43C12.094 10.4299 12.1863 10.4487 12.272 10.4853C12.3577 10.5218 12.4352 10.5753 12.4997 10.6426C12.5642 10.7099 12.6143 10.7895 12.6472 10.8767C12.6801 10.9639 12.6949 11.0569 12.6908 11.1501V16.5301C12.6908 16.622 12.6727 16.713 12.6376 16.7979C12.6024 16.8828 12.5508 16.96 12.4858 17.025C12.4208 17.09 12.3437 17.1415 12.2588 17.1767C12.1738 17.2119 12.0828 17.23 11.9909 17.23C11.899 17.23 11.8079 17.2119 11.723 17.1767C11.6381 17.1415 11.5609 17.09 11.4959 17.025C11.4309 16.96 11.3793 16.8828 11.3442 16.7979C11.309 16.713 11.2909 16.622 11.2909 16.5301Z"
                            fill="#000000"
                          ></path>{" "}
                        </g>
                      </svg>
                    </Tooltip>
                  </InputLabel>

                  <input
                    className="kyc_input kyc_input-date"
                    // required
                    id="location"
                    type="date"
                    placeholder="Enter facility/project end date"
                    name="location"
                    value={projectEndDate}
                    onChange={(e) => setProjectEndDate(e.target.value)}
                  />
                </div>
              </div>
              {facilityCategory === "non-green" && (
                <div>
                  <InputLabel
                    className="kyc_label"
                    htmlFor="quantifiedBasedYearEmission"
                  >
                    Facility/Project fuel source
                    <Tooltip
                      title={
                        <Typography variant="h6">
                          What the fuel source for this project is
                        </Typography>
                      }
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="-0.5 0 25 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <path
                            d="M12 21.5C17.1086 21.5 21.25 17.3586 21.25 12.25C21.25 7.14137 17.1086 3 12 3C6.89137 3 2.75 7.14137 2.75 12.25C2.75 17.3586 6.89137 21.5 12 21.5Z"
                            stroke="#000000"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>{" "}
                          <path
                            d="M12.9309 8.15005C12.9256 8.39231 12.825 8.62272 12.6509 8.79123C12.4767 8.95974 12.2431 9.05271 12.0008 9.05002C11.8242 9.04413 11.6533 8.98641 11.5093 8.884C11.3652 8.7816 11.2546 8.63903 11.1911 8.47415C11.1275 8.30927 11.1139 8.12932 11.152 7.95675C11.19 7.78419 11.278 7.6267 11.405 7.50381C11.532 7.38093 11.6923 7.29814 11.866 7.26578C12.0397 7.23341 12.2192 7.25289 12.3819 7.32181C12.5446 7.39072 12.6834 7.506 12.781 7.65329C12.8787 7.80057 12.9308 7.97335 12.9309 8.15005ZM11.2909 16.5301V11.1501C11.2882 11.0556 11.3046 10.9615 11.3392 10.8736C11.3738 10.7857 11.4258 10.7057 11.4922 10.6385C11.5585 10.5712 11.6378 10.518 11.7252 10.4822C11.8126 10.4464 11.9064 10.4286 12.0008 10.43C12.094 10.4299 12.1863 10.4487 12.272 10.4853C12.3577 10.5218 12.4352 10.5753 12.4997 10.6426C12.5642 10.7099 12.6143 10.7895 12.6472 10.8767C12.6801 10.9639 12.6949 11.0569 12.6908 11.1501V16.5301C12.6908 16.622 12.6727 16.713 12.6376 16.7979C12.6024 16.8828 12.5508 16.96 12.4858 17.025C12.4208 17.09 12.3437 17.1415 12.2588 17.1767C12.1738 17.2119 12.0828 17.23 11.9909 17.23C11.899 17.23 11.8079 17.2119 11.723 17.1767C11.6381 17.1415 11.5609 17.09 11.4959 17.025C11.4309 16.96 11.3793 16.8828 11.3442 16.7979C11.309 16.713 11.2909 16.622 11.2909 16.5301Z"
                            fill="#000000"
                          ></path>{" "}
                        </g>
                      </svg>
                    </Tooltip>
                  </InputLabel>

                  <select
                    className="kyc_input"
                    required
                    value={fuelSource}
                    onChange={(e) => setFuelSource(e.target.value)}
                  >
                    <option value="">
                      Select facility/project fuel source
                    </option>
                    <option value="Coal">Coal</option>
                    <option value="Diesel">Diesel</option>
                    <option value="Natural gas">Natural gas</option>
                    <option value="Petroleum">Petroleum</option>
                  </select>
                </div>
              )}
              {facilityCategory === "green" && (
                <div>
                  <InputLabel
                    className="kyc_label"
                    htmlFor="quantifiedBasedYearEmission"
                  >
                    Green project renewable energy
                    <Tooltip
                      title={
                        <Typography variant="h6">
                          The renewable energy source being used in your project or at your facility 
                        </Typography>
                      }
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="-0.5 0 25 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <path
                            d="M12 21.5C17.1086 21.5 21.25 17.3586 21.25 12.25C21.25 7.14137 17.1086 3 12 3C6.89137 3 2.75 7.14137 2.75 12.25C2.75 17.3586 6.89137 21.5 12 21.5Z"
                            stroke="#000000"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>{" "}
                          <path
                            d="M12.9309 8.15005C12.9256 8.39231 12.825 8.62272 12.6509 8.79123C12.4767 8.95974 12.2431 9.05271 12.0008 9.05002C11.8242 9.04413 11.6533 8.98641 11.5093 8.884C11.3652 8.7816 11.2546 8.63903 11.1911 8.47415C11.1275 8.30927 11.1139 8.12932 11.152 7.95675C11.19 7.78419 11.278 7.6267 11.405 7.50381C11.532 7.38093 11.6923 7.29814 11.866 7.26578C12.0397 7.23341 12.2192 7.25289 12.3819 7.32181C12.5446 7.39072 12.6834 7.506 12.781 7.65329C12.8787 7.80057 12.9308 7.97335 12.9309 8.15005ZM11.2909 16.5301V11.1501C11.2882 11.0556 11.3046 10.9615 11.3392 10.8736C11.3738 10.7857 11.4258 10.7057 11.4922 10.6385C11.5585 10.5712 11.6378 10.518 11.7252 10.4822C11.8126 10.4464 11.9064 10.4286 12.0008 10.43C12.094 10.4299 12.1863 10.4487 12.272 10.4853C12.3577 10.5218 12.4352 10.5753 12.4997 10.6426C12.5642 10.7099 12.6143 10.7895 12.6472 10.8767C12.6801 10.9639 12.6949 11.0569 12.6908 11.1501V16.5301C12.6908 16.622 12.6727 16.713 12.6376 16.7979C12.6024 16.8828 12.5508 16.96 12.4858 17.025C12.4208 17.09 12.3437 17.1415 12.2588 17.1767C12.1738 17.2119 12.0828 17.23 11.9909 17.23C11.899 17.23 11.8079 17.2119 11.723 17.1767C11.6381 17.1415 11.5609 17.09 11.4959 17.025C11.4309 16.96 11.3793 16.8828 11.3442 16.7979C11.309 16.713 11.2909 16.622 11.2909 16.5301Z"
                            fill="#000000"
                          ></path>{" "}
                        </g>
                      </svg>
                    </Tooltip>
                  </InputLabel>

                  <select
                    className="kyc_input"
                    required
                    value={renewableEnergySolutions}
                    onChange={(e) =>
                      setRenewableEnergySolutions(e.target.value)
                    }
                  >
                    <option value="">Select renewable energy solution</option>
                    <option value="Solar energy">Solar energy</option>
                    <option value="Wind energy">Wind energy</option>
                    <option value="Hydropower">Hydropower</option>
                    <option value="Geothermal energy">Geothermal energy</option>
                    <option value="Ocean energy">Ocean energy</option>
                    <option value="Biomass energy">Biomass energy</option>
                  </select>
                </div>
              )}
              {facilityCategory !== "carbon-monitoring" && (
                <div>
                  <InputLabel
                    className="kyc_label"
                    htmlFor="quantifiedBasedYearEmission"
                  >
                    Baseline scenario
                    <Tooltip
                      title={
                        <Typography variant="h6">
                          A description of the facility activities before this
                          project started
                        </Typography>
                      }
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="-0.5 0 25 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <path
                            d="M12 21.5C17.1086 21.5 21.25 17.3586 21.25 12.25C21.25 7.14137 17.1086 3 12 3C6.89137 3 2.75 7.14137 2.75 12.25C2.75 17.3586 6.89137 21.5 12 21.5Z"
                            stroke="#000000"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>{" "}
                          <path
                            d="M12.9309 8.15005C12.9256 8.39231 12.825 8.62272 12.6509 8.79123C12.4767 8.95974 12.2431 9.05271 12.0008 9.05002C11.8242 9.04413 11.6533 8.98641 11.5093 8.884C11.3652 8.7816 11.2546 8.63903 11.1911 8.47415C11.1275 8.30927 11.1139 8.12932 11.152 7.95675C11.19 7.78419 11.278 7.6267 11.405 7.50381C11.532 7.38093 11.6923 7.29814 11.866 7.26578C12.0397 7.23341 12.2192 7.25289 12.3819 7.32181C12.5446 7.39072 12.6834 7.506 12.781 7.65329C12.8787 7.80057 12.9308 7.97335 12.9309 8.15005ZM11.2909 16.5301V11.1501C11.2882 11.0556 11.3046 10.9615 11.3392 10.8736C11.3738 10.7857 11.4258 10.7057 11.4922 10.6385C11.5585 10.5712 11.6378 10.518 11.7252 10.4822C11.8126 10.4464 11.9064 10.4286 12.0008 10.43C12.094 10.4299 12.1863 10.4487 12.272 10.4853C12.3577 10.5218 12.4352 10.5753 12.4997 10.6426C12.5642 10.7099 12.6143 10.7895 12.6472 10.8767C12.6801 10.9639 12.6949 11.0569 12.6908 11.1501V16.5301C12.6908 16.622 12.6727 16.713 12.6376 16.7979C12.6024 16.8828 12.5508 16.96 12.4858 17.025C12.4208 17.09 12.3437 17.1415 12.2588 17.1767C12.1738 17.2119 12.0828 17.23 11.9909 17.23C11.899 17.23 11.8079 17.2119 11.723 17.1767C11.6381 17.1415 11.5609 17.09 11.4959 17.025C11.4309 16.96 11.3793 16.8828 11.3442 16.7979C11.309 16.713 11.2909 16.622 11.2909 16.5301Z"
                            fill="#000000"
                          ></path>{" "}
                        </g>
                      </svg>
                    </Tooltip>
                  </InputLabel>

                  <OutlinedInput
                    className="kyc_input"
                    required
                    id="quantifiedBasedYearEmission"
                    type="text"
                    placeholder="Provide a summary description of the facility to enable an understanding of the nature and its implementation."
                    name="quantifiedBasedYearEmission"
                    value={baseline}
                    onChange={(e) => setBaseline(e?.target?.value)}
                  />

                  <div className="kyc_instructions">
                    <div className="kyc_instructions__title">
                      Include the following:
                    </div>
                    <div className="kyc_instructions__subtitle">
                      <svg
                        width="4"
                        height="4"
                        viewBox="0 0 4 4"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="2" cy="2" r="2" fill="#D9D9D9" />
                      </svg>
                      Conditions existing prior to project initiation
                    </div>
                    <div className="kyc_instructions__subtitle">
                      <svg
                        width="4"
                        height="4"
                        viewBox="0 0 4 4"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="2" cy="2" r="2" fill="#D9D9D9" />
                      </svg>
                      Demonstrate that the project is aimed at reducing
                      greenhouse (GHG) emissions
                    </div>
                  </div>
                </div>
              )}
              {facilityCategory !== "carbon-monitoring" && (
                <div>
                  <InputLabel
                    className="kyc_label"
                    htmlFor="quantifiedBasedYearEmission"
                  >
                    {facilityCategory === "non-green"
                      ? "Fuel source in baseline scenario"
                      : "Energy source in baseline scenario"}

                    <Tooltip
                      title={
                        <Typography variant="h6">
                          What fuel source was used before this project started
                        </Typography>
                      }
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="-0.5 0 25 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <path
                            d="M12 21.5C17.1086 21.5 21.25 17.3586 21.25 12.25C21.25 7.14137 17.1086 3 12 3C6.89137 3 2.75 7.14137 2.75 12.25C2.75 17.3586 6.89137 21.5 12 21.5Z"
                            stroke="#000000"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>{" "}
                          <path
                            d="M12.9309 8.15005C12.9256 8.39231 12.825 8.62272 12.6509 8.79123C12.4767 8.95974 12.2431 9.05271 12.0008 9.05002C11.8242 9.04413 11.6533 8.98641 11.5093 8.884C11.3652 8.7816 11.2546 8.63903 11.1911 8.47415C11.1275 8.30927 11.1139 8.12932 11.152 7.95675C11.19 7.78419 11.278 7.6267 11.405 7.50381C11.532 7.38093 11.6923 7.29814 11.866 7.26578C12.0397 7.23341 12.2192 7.25289 12.3819 7.32181C12.5446 7.39072 12.6834 7.506 12.781 7.65329C12.8787 7.80057 12.9308 7.97335 12.9309 8.15005ZM11.2909 16.5301V11.1501C11.2882 11.0556 11.3046 10.9615 11.3392 10.8736C11.3738 10.7857 11.4258 10.7057 11.4922 10.6385C11.5585 10.5712 11.6378 10.518 11.7252 10.4822C11.8126 10.4464 11.9064 10.4286 12.0008 10.43C12.094 10.4299 12.1863 10.4487 12.272 10.4853C12.3577 10.5218 12.4352 10.5753 12.4997 10.6426C12.5642 10.7099 12.6143 10.7895 12.6472 10.8767C12.6801 10.9639 12.6949 11.0569 12.6908 11.1501V16.5301C12.6908 16.622 12.6727 16.713 12.6376 16.7979C12.6024 16.8828 12.5508 16.96 12.4858 17.025C12.4208 17.09 12.3437 17.1415 12.2588 17.1767C12.1738 17.2119 12.0828 17.23 11.9909 17.23C11.899 17.23 11.8079 17.2119 11.723 17.1767C11.6381 17.1415 11.5609 17.09 11.4959 17.025C11.4309 16.96 11.3793 16.8828 11.3442 16.7979C11.309 16.713 11.2909 16.622 11.2909 16.5301Z"
                            fill="#000000"
                          ></path>{" "}
                        </g>
                      </svg>
                    </Tooltip>
                  </InputLabel>

                  <OutlinedInput
                    className="kyc_input"
                    required
                    id="quantifiedBasedYearEmission"
                    type="text"
                    placeholder={facilityCategory === "non-green"
                      ? "Enter Fuel source in baseline scenario"
                      : "Enter Energy source in baseline scenario"}
                    name="quantifiedBasedYearEmission"
                    value={fuelSourceBaseline}
                    onChange={(e) => setFuelSourceBaseline(e?.target?.value)}
                  />

                </div>
              )}
              {facilityCategory !== "carbon-monitoring" && (
                <div>
                  <InputLabel
                    className="kyc_label"
                    htmlFor="quantifiedBasedYearEmission"
                  >
                    Additional information relevant to the project
                    <Tooltip
                      title={
                        <Typography variant="h6">
                          Provide any additional information relevant to this
                          project.
                        </Typography>
                      }
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="-0.5 0 25 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <path
                            d="M12 21.5C17.1086 21.5 21.25 17.3586 21.25 12.25C21.25 7.14137 17.1086 3 12 3C6.89137 3 2.75 7.14137 2.75 12.25C2.75 17.3586 6.89137 21.5 12 21.5Z"
                            stroke="#000000"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>{" "}
                          <path
                            d="M12.9309 8.15005C12.9256 8.39231 12.825 8.62272 12.6509 8.79123C12.4767 8.95974 12.2431 9.05271 12.0008 9.05002C11.8242 9.04413 11.6533 8.98641 11.5093 8.884C11.3652 8.7816 11.2546 8.63903 11.1911 8.47415C11.1275 8.30927 11.1139 8.12932 11.152 7.95675C11.19 7.78419 11.278 7.6267 11.405 7.50381C11.532 7.38093 11.6923 7.29814 11.866 7.26578C12.0397 7.23341 12.2192 7.25289 12.3819 7.32181C12.5446 7.39072 12.6834 7.506 12.781 7.65329C12.8787 7.80057 12.9308 7.97335 12.9309 8.15005ZM11.2909 16.5301V11.1501C11.2882 11.0556 11.3046 10.9615 11.3392 10.8736C11.3738 10.7857 11.4258 10.7057 11.4922 10.6385C11.5585 10.5712 11.6378 10.518 11.7252 10.4822C11.8126 10.4464 11.9064 10.4286 12.0008 10.43C12.094 10.4299 12.1863 10.4487 12.272 10.4853C12.3577 10.5218 12.4352 10.5753 12.4997 10.6426C12.5642 10.7099 12.6143 10.7895 12.6472 10.8767C12.6801 10.9639 12.6949 11.0569 12.6908 11.1501V16.5301C12.6908 16.622 12.6727 16.713 12.6376 16.7979C12.6024 16.8828 12.5508 16.96 12.4858 17.025C12.4208 17.09 12.3437 17.1415 12.2588 17.1767C12.1738 17.2119 12.0828 17.23 11.9909 17.23C11.899 17.23 11.8079 17.2119 11.723 17.1767C11.6381 17.1415 11.5609 17.09 11.4959 17.025C11.4309 16.96 11.3793 16.8828 11.3442 16.7979C11.309 16.713 11.2909 16.622 11.2909 16.5301Z"
                            fill="#000000"
                          ></path>{" "}
                        </g>
                      </svg>
                    </Tooltip>
                  </InputLabel>

                  <OutlinedInput
                    className="kyc_input"
                    required
                    id="quantifiedBasedYearEmission"
                    type="text"
                    placeholder="Provide a summary description of the facility to enable an understanding of the nature and its implementation."
                    name="quantifiedBasedYearEmission"
                    value={additonalInfo}
                    onChange={(e) => setAdditonalInfo(e?.target?.value)}
                  />

                  <div className="kyc_instructions">
                    <div className="kyc_instructions__title">
                      Include any additional information that may have a bearing
                      on the eligibility of the project.
                    </div>

                    <div className="kyc_instructions__subtitle">
                      <svg
                        width="4"
                        height="4"
                        viewBox="0 0 4 4"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="2" cy="2" r="2" fill="#D9D9D9" />
                      </svg>
                      Demonstrate that the project is aimed at reducing
                      greenhouse (GHG) emissions
                    </div>
                  </div>
                </div>
              )}
              {facilityCategory === "non-green" && (
                <div>
                  <InputLabel
                    className="kyc_label"
                    htmlFor="quantifiedBasedYearEmission"
                  >
                    Sustainability solution
                    <Tooltip
                      title={
                        <Typography variant="h6">
                          The solution this facility is embarking on
                        </Typography>
                      }
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="-0.5 0 25 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <path
                            d="M12 21.5C17.1086 21.5 21.25 17.3586 21.25 12.25C21.25 7.14137 17.1086 3 12 3C6.89137 3 2.75 7.14137 2.75 12.25C2.75 17.3586 6.89137 21.5 12 21.5Z"
                            stroke="#000000"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>{" "}
                          <path
                            d="M12.9309 8.15005C12.9256 8.39231 12.825 8.62272 12.6509 8.79123C12.4767 8.95974 12.2431 9.05271 12.0008 9.05002C11.8242 9.04413 11.6533 8.98641 11.5093 8.884C11.3652 8.7816 11.2546 8.63903 11.1911 8.47415C11.1275 8.30927 11.1139 8.12932 11.152 7.95675C11.19 7.78419 11.278 7.6267 11.405 7.50381C11.532 7.38093 11.6923 7.29814 11.866 7.26578C12.0397 7.23341 12.2192 7.25289 12.3819 7.32181C12.5446 7.39072 12.6834 7.506 12.781 7.65329C12.8787 7.80057 12.9308 7.97335 12.9309 8.15005ZM11.2909 16.5301V11.1501C11.2882 11.0556 11.3046 10.9615 11.3392 10.8736C11.3738 10.7857 11.4258 10.7057 11.4922 10.6385C11.5585 10.5712 11.6378 10.518 11.7252 10.4822C11.8126 10.4464 11.9064 10.4286 12.0008 10.43C12.094 10.4299 12.1863 10.4487 12.272 10.4853C12.3577 10.5218 12.4352 10.5753 12.4997 10.6426C12.5642 10.7099 12.6143 10.7895 12.6472 10.8767C12.6801 10.9639 12.6949 11.0569 12.6908 11.1501V16.5301C12.6908 16.622 12.6727 16.713 12.6376 16.7979C12.6024 16.8828 12.5508 16.96 12.4858 17.025C12.4208 17.09 12.3437 17.1415 12.2588 17.1767C12.1738 17.2119 12.0828 17.23 11.9909 17.23C11.899 17.23 11.8079 17.2119 11.723 17.1767C11.6381 17.1415 11.5609 17.09 11.4959 17.025C11.4309 16.96 11.3793 16.8828 11.3442 16.7979C11.309 16.713 11.2909 16.622 11.2909 16.5301Z"
                            fill="#000000"
                          ></path>{" "}
                        </g>
                      </svg>
                    </Tooltip>
                  </InputLabel>

                  <select
                    className="kyc_input"
                    required
                    value={sustainabilitySolution}
                    onChange={(e) => setSustainabilitySolution(e.target.value)}
                  >
                    <option value="">Select Sustainability solution</option>
                    <option value="infrastructure-change">
                      Infrastructure change
                    </option>
                    <option value="carbon-monitoring">Monitoring</option>
                  </select>
                </div>
              )}
              {facilityCategory !== "carbon-monitoring" && (
                <div>
                  <InputLabel
                    className="kyc_label"
                    htmlFor="quantifiedBasedYearEmission"
                  >
                    Double counting and participation under other GHG programs
                    {/* <Tooltip
                      title={
                        <Typography variant="h6">
                          The annual carbon emitted on average by company
                        </Typography>
                      }
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="-0.5 0 25 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <path
                            d="M12 21.5C17.1086 21.5 21.25 17.3586 21.25 12.25C21.25 7.14137 17.1086 3 12 3C6.89137 3 2.75 7.14137 2.75 12.25C2.75 17.3586 6.89137 21.5 12 21.5Z"
                            stroke="#000000"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>{" "}
                          <path
                            d="M12.9309 8.15005C12.9256 8.39231 12.825 8.62272 12.6509 8.79123C12.4767 8.95974 12.2431 9.05271 12.0008 9.05002C11.8242 9.04413 11.6533 8.98641 11.5093 8.884C11.3652 8.7816 11.2546 8.63903 11.1911 8.47415C11.1275 8.30927 11.1139 8.12932 11.152 7.95675C11.19 7.78419 11.278 7.6267 11.405 7.50381C11.532 7.38093 11.6923 7.29814 11.866 7.26578C12.0397 7.23341 12.2192 7.25289 12.3819 7.32181C12.5446 7.39072 12.6834 7.506 12.781 7.65329C12.8787 7.80057 12.9308 7.97335 12.9309 8.15005ZM11.2909 16.5301V11.1501C11.2882 11.0556 11.3046 10.9615 11.3392 10.8736C11.3738 10.7857 11.4258 10.7057 11.4922 10.6385C11.5585 10.5712 11.6378 10.518 11.7252 10.4822C11.8126 10.4464 11.9064 10.4286 12.0008 10.43C12.094 10.4299 12.1863 10.4487 12.272 10.4853C12.3577 10.5218 12.4352 10.5753 12.4997 10.6426C12.5642 10.7099 12.6143 10.7895 12.6472 10.8767C12.6801 10.9639 12.6949 11.0569 12.6908 11.1501V16.5301C12.6908 16.622 12.6727 16.713 12.6376 16.7979C12.6024 16.8828 12.5508 16.96 12.4858 17.025C12.4208 17.09 12.3437 17.1415 12.2588 17.1767C12.1738 17.2119 12.0828 17.23 11.9909 17.23C11.899 17.23 11.8079 17.2119 11.723 17.1767C11.6381 17.1415 11.5609 17.09 11.4959 17.025C11.4309 16.96 11.3793 16.8828 11.3442 16.7979C11.309 16.713 11.2909 16.622 11.2909 16.5301Z"
                            fill="#000000"
                          ></path>{" "}
                        </g>
                      </svg>
                    </Tooltip> */}
                  </InputLabel>
                  <div className="kyc-checkbox-grid">
                    <div className="kyc-checkbox-grid__title">
                      Is the project receiving or seeking credit for reductions
                      and removals from a project activity under another GHG
                      program?
                    </div>
                    <div className="kyc-checkbox-grid__check-box">
                      <div>
                        {doubleCounting !== "true" && (
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            onClick={() => setDoubleCounting("true")}
                          >
                            <rect
                              x="1"
                              y="1"
                              width="16"
                              height="16"
                              rx="4"
                              stroke="url(#paint0_linear_2889_18043)"
                              stroke-width="1.33333"
                            />
                            <defs>
                              <linearGradient
                                id="paint0_linear_2889_18043"
                                x1="1"
                                y1="1.36719"
                                x2="20.0689"
                                y2="10.7886"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stop-color="#006633" />
                                <stop offset="1" stop-color="#02A4CC" />
                              </linearGradient>
                            </defs>
                          </svg>
                        )}
                        {doubleCounting === "true" && (
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            onClick={() => setDoubleCounting("")}
                          >
                            <rect
                              x="1"
                              y="1"
                              width="16"
                              height="16"
                              rx="4"
                              stroke="url(#paint0_linear_2889_18037)"
                              stroke-width="1.33333"
                            />
                            <path
                              d="M5.5 9.49992L7.83333 11.8333L12.5 6.83325"
                              stroke="url(#paint1_linear_2889_18037)"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <defs>
                              <linearGradient
                                id="paint0_linear_2889_18037"
                                x1="1"
                                y1="1.36719"
                                x2="20.0689"
                                y2="10.7886"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stop-color="#006633" />
                                <stop offset="1" stop-color="#02A4CC" />
                              </linearGradient>
                              <linearGradient
                                id="paint1_linear_2889_18037"
                                x1="5.5"
                                y1="6.948"
                                x2="12.5203"
                                y2="11.8039"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stop-color="#006633" />
                                <stop offset="1" stop-color="#02A4CC" />
                              </linearGradient>
                            </defs>
                          </svg>
                        )}
                        Yes
                      </div>
                      <div>
                        {doubleCounting !== "false" && (
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            onClick={() => setDoubleCounting("false")}
                          >
                            <rect
                              x="1"
                              y="1"
                              width="16"
                              height="16"
                              rx="4"
                              stroke="url(#paint0_linear_2889_18043)"
                              stroke-width="1.33333"
                            />
                            <defs>
                              <linearGradient
                                id="paint0_linear_2889_18043"
                                x1="1"
                                y1="1.36719"
                                x2="20.0689"
                                y2="10.7886"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stop-color="#006633" />
                                <stop offset="1" stop-color="#02A4CC" />
                              </linearGradient>
                            </defs>
                          </svg>
                        )}
                        {doubleCounting === "false" && (
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            onClick={() => setDoubleCounting("")}
                          >
                            <rect
                              x="1"
                              y="1"
                              width="16"
                              height="16"
                              rx="4"
                              stroke="url(#paint0_linear_2889_18037)"
                              stroke-width="1.33333"
                            />
                            <path
                              d="M5.5 9.49992L7.83333 11.8333L12.5 6.83325"
                              stroke="url(#paint1_linear_2889_18037)"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <defs>
                              <linearGradient
                                id="paint0_linear_2889_18037"
                                x1="1"
                                y1="1.36719"
                                x2="20.0689"
                                y2="10.7886"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stop-color="#006633" />
                                <stop offset="1" stop-color="#02A4CC" />
                              </linearGradient>
                              <linearGradient
                                id="paint1_linear_2889_18037"
                                x1="5.5"
                                y1="6.948"
                                x2="12.5203"
                                y2="11.8039"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stop-color="#006633" />
                                <stop offset="1" stop-color="#02A4CC" />
                              </linearGradient>
                            </defs>
                          </svg>
                        )}
                        No
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <button className="kyc_submit" type="submit">
              {loading ? "Loading..." : "Complete"}
            </button>
          </form>
        </div>
      )}
      {modalOpenThree && (
        <div
          className="kyc_modal_container"
          // onClick={handleModalCloseThree}
        >
          <div className="kyc_modal kyc_mini">
            <div className="title">Carbon Management Preferences</div>
            <div className="subtitle">
              Answer this questions, to start your journey
            </div>
            <div className="mini_grid_title">Green/Not Green</div>
            <div className="kyc_mini_grid">
              <div>
                Is your facility or operation committed to environmentally
                responsible practices, including the use of energy-efficient
                equipment and renewable energy sources?
              </div>
              <input
                checked={facilityType === "green"}
                type="checkbox"
                name=""
                id=""
                onChange={(e) => {
                  if (e.target.checked) {
                    setFacilityType("green");
                  } else {
                    setFacilityType("");
                  }
                }}
              />
            </div>
            <div className="kyc_mini_grid">
              <div>
                Does your facility primarily rely on conventional energy sources
                and equipment that may not prioritize environmental
                sustainability?
              </div>
              <input
                checked={facilityType === "non-green"}
                type="checkbox"
                name=""
                id=""
                onChange={(e) => {
                  if (e.target.checked) {
                    setFacilityType("non-green");
                  } else {
                    setFacilityType("");
                  }
                }}
              />
            </div>
            <div className="mini_grid_title">Carbon goals</div>
            {facilityType !== "green" && (
              <div className="kyc_mini_grid">
                <div>
                  Did you make infrastructure changes to reduce carbon emissions
                  and are interested in earning carbon incentives as a result?
                </div>
                <input
                  type="checkbox"
                  name=""
                  id=""
                  checked={scenario === "infrastructure-change"}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setScenario("infrastructure-change");
                    } else {
                      setScenario("");
                    }
                  }}
                />
              </div>
            )}
            {facilityType !== "non-green" && (
              <div className="kyc_mini_grid">
                <div>
                  Are you looking to monitor your carbon and earn carbon
                  incentives through your green project
                </div>
                <input
                  type="checkbox"
                  name=""
                  id=""
                  checked={scenario === "green-project"}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setScenario("green-project");
                    } else {
                      setScenario("");
                    }
                  }}
                />
              </div>
            )}
            {facilityType !== "green" && (
              <div className="kyc_mini_grid">
                <div>
                  Are you interested in just monitoring and tracking your carbon
                  emissions?
                </div>
                <input
                  type="checkbox"
                  name=""
                  id=""
                  checked={scenario === "carbon-monitoring"}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setScenario("carbon-monitoring");
                    } else {
                      setScenario("");
                    }
                  }}
                />
              </div>
            )}
            <button className="kyc_submit" onClick={handleKycTwoSubmit}>
              Done
            </button>
          </div>
        </div>
      )}
      {uplaodLoading && <div className="upload_modal"></div>}
      {idVerificationModal && <div id="onfido-mount">
        {/* {loading && <div className="onfido-mount__loader">
          Loading...
        </div>} */}
        </div>}
      {failedIdVerificationModal && (
        <div
          className="failed-id"
          onClick={handleFailedIdVerificationModalClose}
        >
          <div className="failed-id__modal">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 3H5C4.46957 3 3.96086 3.21071 3.58579 3.58579C3.21071 3.96086 3 4.46957 3 5V7M17 3H19C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V7M16 8V10M8 8V10M9 16C9 16 10 17 12 17C14 17 15 16 15 16M12 8V13H11M7 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V17M17 21H19C19.5304 21 20.0391 20.7893 20.4142 20.4142C20.7893 20.0391 21 19.5304 21 19V17"
                stroke="#D42620"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <div className="failed-id__modal__title">
              Id verification failed
            </div>
            <div className="failed-id__modal__subtitle">
              Something went wrong during verifying your Id.
            </div>
            <button
              className="failed-id__modal__button"
              onClick={() => {
                setFailedIdVerificationModal(false);
              }}
            >
              Try again
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Kyc;
