import SideBar from "../../components/ThirdPartyDashboard/SideBar";
import "../../styles/ThirdParty/Profile.scss";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Tabs, Tab } from "@mui/material";
import { getUserInfo, getKyc, updateThirdPartyInfo } from "../../apis/General/users";
import { toast } from "react-toastify";
import Search from "../../components/ThirdPartyDashboard/Search";

// function TabPanel(props) {
//     const { children, value, index, ...other } = props;

//     return (
//       <div
//         role="tabpanel"
//         hidden={value !== index}
//         id={`simple-tabpanel-${index}`}
//         aria-labelledby={`simple-tab-${index}`}
//         {...other}
//       >
//         {value === index && (
//           <Box sx={{ p: 3 }}>
//             <Typography>{children}</Typography>
//           </Box>
//         )}
//       </div>
//     );
//   }

//   TabPanel.propTypes = {
//     children: PropTypes.node,
//     index: PropTypes.number.isRequired,
//     value: PropTypes.number.isRequired,
//   };

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Settings = () => {
const auth = useSelector((state) => state.auth);  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [idNumber, setIdNumber] = useState("");
  const [organization, setOrganization] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [authorizedName, setAuthorizedName] = useState("");
  const [authorizedPhoneNumber, setAuthorizedPhoneNumber] = useState("");
  const [contactInformation, setContactInformation] = useState("");
  const [dateOfIncorporation, setDateOfIncorporation] = useState("");
  const [experience, setExperience] = useState("");
  const [publicKey, setPublicKey] = useState("");
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [country, setCountry] = useState("");
  const [loading, setLoading] = useState(false);

  const [value, setValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };


  const fetchUserInfo = async () => {
    const response = await getUserInfo(auth?.userInfo?.id, auth?.token);
    // console.log('userDetails', response);
    const response2 = await getKyc(auth?.userInfo?.id, auth?.token);

    setFirstName(response?.data?.message?.firstName);
    setLastName(response?.data?.message?.lastName);
    setEmail(response?.data?.message?.email);
    setAddress(response?.data?.message?.address);
    setCountryCode(response?.data?.message?.countryCode);
    // setCountry(response?.data?.message?.country);
    setPhoneNumber(response?.data?.message?.phoneNumber);
    setIdNumber(response?.data?.message?.idNumber);
    setOrganization(
      response?.data?.message?.organization
    );
    setCountry(response?.data?.message?.country);
    setPublicKey(response2?.data?.message?.publicKey);
    setOrganizationName(response2?.data?.message?.organizationName);
    setAuthorizedPhoneNumber(response2?.data?.message?.authorizedPhoneNumber);
    setContactInformation(response2?.data?.message?.contactInformation);
    setDateOfIncorporation(response2?.data?.message?.dateOfIncorporation);
    setExperience(response2?.data?.message?.experience);
    setRegistrationNumber(response2?.data?.message?.registrationNumber);
    setAuthorizedName(response2?.data?.message?.authorizedName);
  };

  const handleUserUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);
    const response = await updateThirdPartyInfo(
      firstName,
      lastName,
      address,
      phoneNumber,
      idNumber,
      organization,
      auth?.token
    );

    // console.log('response', response);
    if (response?.data?.statusCode === 200) {
      toast.success(response?.data?.message);
    } else {
      alert("Something went wrong");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchUserInfo();
  }, []);
  return (
    <div className="thirdparty_profile">
      <div className="sidebar_container">
        <SideBar />
      </div>
      <div className="main">
        {/* <Search /> */}
        {/* <div className="name">
          <div className="abv"> {firstName?.split("")[0]}
            {lastName?.split("")[0]}</div>
          <div className="name_inner">
            <div className=""> {firstName?.split("")[0]}
            {lastName?.split("")[0]}</div>
            <div>{auth?.userInfo?.email}</div>
          </div>
        </div> */}

        <Tabs
          value={value}
          onChange={handleTabChange}
          aria-label="basic tabs example"
          className="tab_label_container"
        >
          <Tab
            className={value === 0 ? "tab_label" : "tab_label tab_active"}
            label="Personal Details"
            {...a11yProps(0)}
          />
          <Tab
            className="tab_label"
            label="Organisation details"
            {...a11yProps(1)}
          />
          <Tab className="tab_label" label="Authorized details" {...a11yProps(2)} />
        </Tabs>
        {/* <TabPanel value={value} index={0}> */}
        {value === 0 && 
        <form className="thirdparty-profile-form" onSubmit={handleUserUpdate}>
          <div className="input_container">
            <div>First Name</div>
            <input type="text" placeholder="First name"
            value={firstName}
            name="firstName"
            onChange={(e) => setFirstName(e.target.value)}
            required
            />
          </div>
          <div className="input_container">
            <div>Last Name</div>
            <input type="text" placeholder="Last name"
            value={lastName}
            name="lastName"
            onChange={(e) => setLastName(e.target.value)}
            required
            />
          </div>
          <div className="input_container">
            <div>Email Address</div>
            <input type="text" placeholder="Email"
            value={email}
            name="email"
            onChange={(e) => setEmail(e.target.value)}
            required
            disabled
            />
          </div>
          <div className="input_container">
            <div>Address</div>
            <input type="text" placeholder="Postal Address"
            value={address}
            name="address"
            onChange={(e) => setAddress(e.target.value)}
            required
            />
          </div>
          <div className="input_container">
            <div>Phone Number</div>
            {/* <div className="input_group">
              <input type="text" placeholder="Country Code"
               value={countryCode}
               onChange={(e) => setCountryCode(e.target.value)}
               required
              />
              <input
                type="text"
                placeholder="Phone Number"
                value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  required
              />
            </div> */}
            <input type="text" placeholder="Phone Number"
            value={phoneNumber}
            name="phoneNumber"
            onChange={(e) => setPhoneNumber(e.target.value)}
            required
            />
          </div>
          {/* <div className="input_container">
            <div>Company</div>
            <input type="text" placeholder="Postal Address" />
          </div> */}
          <div className="input_container">
            <div>ID Number</div>
            <input type="text" placeholder="ID Number"
            value={idNumber}
            name="idNumber"
            onChange={(e) => setIdNumber(e.target.value)}
            required
            />
          </div>
         
          {/* <div className="input_container">
            <div>Experience & Expertise <br /> in CO2 credit issuance</div>
            <textarea type="text" placeholder="Organisation Description"></textarea>
          </div> */}

          <button type="submit" className="settings_submit" disabled={loading}>
          {loading ? "Loading..." : "Save Changes"}
          </button>
        </form>}
       {value === 1 && <form className="thirdparty-profile-form" onSubmit={handleUserUpdate}>
          <div className="input_container">
            <div>Organisation Name</div>
            <input type="text" placeholder="Organisation name"
            value={organization}
            name="organisation"
            onChange={(e) => setOrganization(e.target.value)}
            required
            />
          </div>
          {/* <div className="input_container">
            <div>Registration Number</div>
            <textarea type="text" placeholder="Organisation Description"></textarea>
          </div> */}
          
          {/* <div className="input_container">
            <div>Date of Incorporation</div>
              <input type="text" placeholder="Country Code" />
             
          </div> */}
          {/* <div className="input_container">
            <div>Organ. Phone Number</div>
            <div className="input_group">
              <input type="text" placeholder="Country Code" />
              <input
                type="text"
                placeholder="Phone Number"
              />
            </div>
          </div> */}
          {/* <div className="input_container">
            <div>Physical Address</div>
              <input type="text" placeholder="Country Code" />
             
          </div> */}
          {/* <div className="input_container">
            <div>Organ. Email Address</div>
              <input type="text" placeholder="Country Code" />
             
          </div> */}
          <div className="input_container">
            <div>Country</div>
              <input type="text" placeholder="Country"
              value={country}
              name="country"
              onChange={(e) => setCountry(e.target.value)}
              required
              disabled
              />
             
          </div>

          <button type="submit" className="settings_submit" disabled={loading}>
          {loading ? "Loading..." : "Save Changes"}
          </button>
        </form>}
       {value === 2 && <form className="thirdparty-profile-form">
          <div className="input_container">
            <div>Organisation Name</div>
            <input type="text" placeholder="Organisation Name"
             value={organizationName}
             name="organisationName"
             onChange={(e) => setOrganizationName(e.target.value)}
             required
             disabled
            />
          </div>
          <div className="input_container">
            <div>Registration number</div>
            <input type="text" placeholder="Registration number"
             value={registrationNumber}
             name="registrationNumber"
             onChange={(e) => setRegistrationNumber(e.target.value)}
             required
             disabled
            />
          </div>
          <div className="input_container">
            <div>Date of Incopration</div>
            <input type="text" placeholder="Date of Incopration"
             value={dateOfIncorporation}
             name="dateOfIncorporation"
             onChange={(e) => setDateOfIncorporation(e.target.value)}
             required
             disabled
            />
          </div>
          <div className="input_container">
            <div>Contact Information</div>
            <input type="text" placeholder="Contact Information"
            value={contactInformation}
            name="contactInformation"
            onChange={(e) => setContactInformation(e.target.value)}
            required
            disabled
            />
          </div>
         
          <div className="input_container">
            <div>Company Email</div>
            <input type="text" placeholder="Company Email"
            
            />
          </div>
          <div className="input_container">
            <div>Company Phone number</div>
            <input type="text" placeholder="Company Email" />
          </div>
         
          <div className="input_container">
            <div>Authorized Name</div>
            <input type="text" placeholder="Authorized Name"
             value={authorizedName}
             name="authorizedName"
             onChange={(e) => setAuthorizedName(e.target.value)}
             required
             disabled
            />
          </div>
          <div className="input_container">
            <div>Authorized Phone Number</div>
            {/* <div className="input_group">
              <input type="text" placeholder="Country Code"
              value={authorizedName}
              onChange={(e) => setAuthorizedName(e.target.value)}
              required
              disabled
              />
              <input
                type="text"
                placeholder="Phone Number"
              />
            </div> */}
            <input type="text" placeholder="Authorized Phone Number"
            value={authorizedPhoneNumber}
            name="authorizedPhoneNumber"
            onChange={(e) => setAuthorizedPhoneNumber(e.target.value)}
            required
            disabled
            />
          </div>
          
         
          <div className="input_container">
            <div>wallet Address</div>
            <input type="text" placeholder="wallet Address"
            value={publicKey}
            name="publicKey"
            onChange={(e) => setPublicKey(e.target.value)}
            required
            disabled
            />
          </div>
          <div className="input_container">
            <div>Experience</div>
            <input type="text" placeholder="Experience"
             value={experience}
             name="experience"
             onChange={(e) => setExperience(e.target.value)}
             required
             disabled
            />
          </div>


          <button type="submit" className="settings_submit">
            Save Changes
          </button>
        </form>}
        {/* </TabPanel> */}
      </div>
    </div>
  );
};

export default Settings;
