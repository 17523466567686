import "../../styles/UserDashboard/Login.scss";
import {
  Box,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { forgotPassword, login, verifyOtp } from "../../apis/UserDashboard/authentication";
import { toast } from "react-toastify";

const Login = () => {
  const auth = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [stage, setStage] = useState(0);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [forgotError, setForgotError] = useState(false);
  const [maskedEmail, setMaskedEmail] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [otp1, setOtp1] = useState("");
  const [otp2, setOtp2] = useState("");
  const [otp3, setOtp3] = useState("");
  const [otp4, setOtp4] = useState("");
  const [otp5, setOtp5] = useState("");
  const [otp6, setOtp6] = useState("");
  const [token, setToken] = useState("");

  const navigate = useNavigate();

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = (e) => {
    if (e.target.classList.contains("modal")) {
      setModalOpen(false);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleForgotPassword = () => {
    setStage(1);
    handleModalOpen();
  };
  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    setMaskedEmail(maskEmail(email));

    setStage(2);
  };
  const handleEmailSend = async () => {
    setLoading(true);
    const response = await forgotPassword(email);

    if (response?.data?.statusCode === 200) {
      setStage(3);
    } else {
      alert(response?.data?.message);
    }
    setLoading(false);
  };
  const handleResendEmail = (e) => {
    e.preventDefault();
    setStage(2);
  };

  const handleLogin = async (e) => {
    setLoading(true);
    e.preventDefault();

    const response = await login(email, password);
    console.log('login', response);
    if (response?.data?.statusCode === 200) {
      const tokenDecoded = jwt_decode(response.data.message);
console.log('tokenDecoded', tokenDecoded);

// if(!!tokenDecoded?.twoFaEnabled) {
//   setStage(5);
//   setToken(response.data.message);
// }else {
  if (
    tokenDecoded?.role === "thirdParty" ||
    tokenDecoded?.role === "admin"
  ) {
    toast.error("Please log in with a user account");
  } else {
    localStorage.setItem("token", response.data.message);
    dispatch({
      type: "USER_LOGIN_SUCCESS",
      payload: {
        token: response.data.message,
      },
    });
    navigate("/dashboard");
    window.scrollTo(0, 0);
  }
// }

      
    } else {
      setError(true);
    }
    setLoading(false);
  };

  function maskEmail(email) {
    const atIndex = email.indexOf("@");
    if (atIndex > 1) {
      const firstPart = email.substring(0, 1);
      const lastPart = email.substring(atIndex);
      const maskedFirstPart = firstPart + "*".repeat(atIndex - 1);
      return maskedFirstPart + lastPart;
    }
    return email;
  }

  useEffect(() => {
    if (
      auth?.userInfo?.role === "thirdParty" ||
      auth?.userInfo?.role === "admin"
    ) {
      dispatch({
        type: "LOGOUT_SUCCESS",
      });
      localStorage.removeItem("token");
      navigate("/");
      window.scrollTo(0, 0);
      toast.error("Please log in with a user account");
    }
  }, [auth]);

  const handleOTPSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const otp = `${otp1}${otp2}${otp3}${otp4}${otp5}${otp6}`;
    console.log("email", email);
    const response = await verifyOtp(otp, token);
    console.log("verifyOtp", response);
    // if (response?.data?.statusCode === 200) {
    //   setStage(3);
    // } else if (
    //   response?.data?.message === "Token used. Please, proceed to sign up"
    // ) {
    //   alert(response?.data?.message);
    //   setStage(3);
    // } else if (response?.data?.statusCode === 401) {
    //   // handleShowError("OTP expired", "The one-time code you entered has expired. Request a new OTP to complete your registration.", "Request OTP");
    //   setOtpError(true);
    // }
    setLoading(false);
  };

  useEffect(() => {
    const otpInputs = document.querySelectorAll(".otp_input");

    otpInputs.forEach((input, index) => {
      input.addEventListener("input", (event) => {
        const inputValue = event.target.value;

        if (inputValue && index < otpInputs.length - 1) {
          otpInputs[index + 1].focus();
        }
      });

      input.addEventListener("keydown", (event) => {
        if (event.key === "Backspace" && index > 0 && !input.value) {
          otpInputs[index - 1].focus();
          event.preventDefault();
        }
      });
    });
  }, [stage === 5]);


  return (
    <div className="login">
      <div className="login_inner">
        <div className="title">Redefining Carbon Emission Solutions...</div>
        <div>

          <div className="login_inner__disclaimer">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18 8H17V6C17 3.24 14.76 1 12 1C9.24 1 7 3.24 7 6V8H6C4.9 8 4 8.9 4 10V20C4 21.1 4.9 22 6 22H18C19.1 22 20 21.1 20 20V10C20 8.9 19.1 8 18 8ZM9 6C9 4.34 10.34 3 12 3C13.66 3 15 4.34 15 6V8H9V6ZM18 20H6V10H18V20ZM12 17C13.1 17 14 16.1 14 15C14 13.9 13.1 13 12 13C10.9 13 10 13.9 10 15C10 16.1 10.9 17 12 17Z" fill="white"/>
</svg>

          <div className="login_inner__disclaimer__title">
          <div>Double check that you’re visiting the right URL</div>
          <div>https://closecarbon.com</div>
            </div>
            
          </div>
        {stage === 0 && <div className="box">
          <div className="signup_link">
            Don’t have an account?{" "}
            <span
              className="pointer"
              onClick={() =>{ navigate("/#subscription")
              window.scrollTo(0, 0);
            }}
            >
              Sign Up
            </span>
          </div>
          <div className="box_title">Welcome back!</div>
          <div className="box_subtitle">
            Please enter your login credentials to continue
          </div>
          <form onSubmit={handleLogin}>
            <InputLabel className="login_label" htmlFor="email">
              Email address
            </InputLabel>

            <OutlinedInput
              // className={
              //   errorPresent ? "login-inputs login-error" : "login-inputs"
              // }
              className={error ? "login_input login_error" : "login_input"}
              required
              id="email"
              type="email"
              value={email}
              placeholder="Enter email address"
              name="email"
              onChange={(e) => {
                setEmail(e.target.value);
                setError(false);
              }}
            />
            {error && (
              <div className="login_error_text">
                Incorrect email or password
              </div>
            )}
            <InputLabel className="login_label" htmlFor="password">
              Password
            </InputLabel>
            <OutlinedInput
              className={error ? "login_input login_error" : "login_input"}
              required
              id="password"
              name="password"
              type={showPassword ? "text" : "password"}
              placeholder="Enter password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setError(false);
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />

            {/* <div className="login_error_text">Incorrect password</div> */}

            <div className="login_flex">
            <div className="login_remember">
              {!rememberMe && <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"
onClick={() => setRememberMe(true)}
>
<rect x="1" y="1" width="16" height="16" rx="4" stroke="url(#paint0_linear_2889_18043)" stroke-width="1.33333"/>
<defs>
<linearGradient id="paint0_linear_2889_18043" x1="1" y1="1.36719" x2="20.0689" y2="10.7886" gradientUnits="userSpaceOnUse">
<stop stop-color="#006633"/>
<stop offset="1" stop-color="#02A4CC"/>
</linearGradient>
</defs>
</svg> }  

{ rememberMe &&<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"
onClick={() => setRememberMe(false)}
>
<rect x="1" y="1" width="16" height="16" rx="4" stroke="url(#paint0_linear_2889_18037)" stroke-width="1.33333"/>
<path d="M5.5 9.49992L7.83333 11.8333L12.5 6.83325" stroke="url(#paint1_linear_2889_18037)" stroke-linecap="round" stroke-linejoin="round"/>
<defs>
<linearGradient id="paint0_linear_2889_18037" x1="1" y1="1.36719" x2="20.0689" y2="10.7886" gradientUnits="userSpaceOnUse">
<stop stop-color="#006633"/>
<stop offset="1" stop-color="#02A4CC"/>
</linearGradient>
<linearGradient id="paint1_linear_2889_18037" x1="5.5" y1="6.948" x2="12.5203" y2="11.8039" gradientUnits="userSpaceOnUse">
<stop stop-color="#006633"/>
<stop offset="1" stop-color="#02A4CC"/>
</linearGradient>
</defs>
</svg>}
             <div>Remember me</div>
              </div>
              <div className="forgot pointer" onClick={handleForgotPassword}>
                Forgot password
              </div>
            </div>

            <button className="login_submit" type="submit" disabled={loading}>
              <div>{loading ? "Loading..." : "Login"}</div>
              {!loading && (
                <svg
                  width="19"
                  height="20"
                  viewBox="0 0 19 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.5459 10.7959L8.79594 14.5459C8.58459 14.7573 8.29795 14.876 7.99906 14.876C7.70018 14.876 7.41353 14.7573 7.20219 14.5459C6.99084 14.3346 6.87211 14.0479 6.87211 13.7491C6.87211 13.4502 6.99084 13.1635 7.20219 12.9522L9.03125 11.125H1.25C0.951631 11.125 0.665483 11.0065 0.454505 10.7955C0.243526 10.5845 0.125 10.2984 0.125 10C0.125 9.70163 0.243526 9.41548 0.454505 9.2045C0.665483 8.99353 0.951631 8.875 1.25 8.875H9.03125L7.20406 7.04594C7.09942 6.94129 7.0164 6.81706 6.95977 6.68033C6.90314 6.5436 6.87399 6.39706 6.87399 6.24906C6.87399 5.95018 6.99272 5.66353 7.20406 5.45219C7.41541 5.24084 7.70205 5.12211 8.00094 5.12211C8.29982 5.12211 8.58647 5.24084 8.79781 5.45219L12.5478 9.20219C12.6526 9.30683 12.7356 9.43112 12.7923 9.56793C12.8489 9.70474 12.8779 9.85139 12.8778 9.99945C12.8776 10.1475 12.8482 10.2941 12.7912 10.4308C12.7343 10.5675 12.6509 10.6915 12.5459 10.7959ZM17 0.625H11.75C11.4516 0.625 11.1655 0.743526 10.9545 0.954505C10.7435 1.16548 10.625 1.45163 10.625 1.75C10.625 2.04837 10.7435 2.33452 10.9545 2.5455C11.1655 2.75647 11.4516 2.875 11.75 2.875H16.625V17.125H11.75C11.4516 17.125 11.1655 17.2435 10.9545 17.4545C10.7435 17.6655 10.625 17.9516 10.625 18.25C10.625 18.5484 10.7435 18.8345 10.9545 19.0455C11.1655 19.2565 11.4516 19.375 11.75 19.375H17C17.4973 19.375 17.9742 19.1775 18.3258 18.8258C18.6775 18.4742 18.875 17.9973 18.875 17.5V2.5C18.875 2.00272 18.6775 1.52581 18.3258 1.17417C17.9742 0.822544 17.4973 0.625 17 0.625Z"
                    fill="white"
                  />
                </svg>
              )}
            </button>
          </form>

          {modalOpen && (
            <>
              {/* Email Sent Modal */}
              {stage === 3 && (
                <Box className="email_modal">
                  <svg
                    className="confirm_svg"
                    width="44"
                    height="44"
                    viewBox="0 0 44 44"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M42 20.16V22C41.9975 26.3128 40.601 30.5093 38.0187 33.9636C35.4363 37.4179 31.8066 39.9449 27.6707 41.1678C23.5349 42.3906 19.1145 42.2438 15.0689 40.7491C11.0234 39.2545 7.56931 36.4922 5.22192 32.8741C2.87453 29.256 1.75958 24.9761 2.04335 20.6726C2.32712 16.3691 3.99441 12.2726 6.79656 8.99412C9.59871 5.71561 13.3856 3.43074 17.5924 2.48026C21.7992 1.52979 26.2005 1.96465 30.14 3.71997"
                      stroke="#099137"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M42 6L22 26.02L16 20.02"
                      stroke="#099137"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <div className="modal_title">Sent</div>
                  <div className="modal_subtitle">
                    A code has been sent to your email, please confirm.
                  </div>
                  <button
                    className="modal_button"
                    onClick={() =>
                      navigate(`/forgot-password/${email}`)
                    }
                  >
                    Yeah, Got it!
                  </button>
                  <div
                    className="error didnt_get pointer"
                    onClick={() => setStage(4)}
                  >
                    Nah, I didn’t get the code
                  </div>
                </Box>
              )}
              {/* Automated Email Sent Modal */}
              {stage === 2 && (
                <Box className="email_modal">
                  <svg
                    className="confrirm_svg"
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_179_49)">
                      <path
                        d="M34.666 16.4533C33.2368 15.5538 32.0592 14.3065 31.2431 12.8282C30.4269 11.3498 29.999 9.68866 29.9993 8H5.22595C4.98668 8.00369 4.74899 8.03956 4.51929 8.10667L23.786 27.2933L34.666 16.4533Z"
                        fill="#00AED9"
                      />
                      <path
                        d="M40.0002 18C39.0979 17.9922 38.2008 17.8621 37.3335 17.6133L25.7202 29.1867C25.2205 29.6833 24.5446 29.9621 23.8402 29.9621C23.1357 29.9621 22.4598 29.6833 21.9602 29.1867L2.66682 10C2.60767 10.2174 2.57631 10.4414 2.57349 10.6667V37.3333C2.57349 38.0406 2.85444 38.7189 3.35453 39.219C3.85463 39.719 4.53291 40 5.24015 40H42.5735C43.2807 40 43.959 39.719 44.4591 39.219C44.9592 38.7189 45.2402 38.0406 45.2402 37.3333V16.52C43.6654 17.4918 41.8506 18.0044 40.0002 18ZM7.06682 37.3333H5.21349V35.4267L14.9068 25.8133L16.7868 27.6933L7.06682 37.3333ZM42.5468 37.3333H40.6802L30.9602 27.6933L32.8402 25.8133L42.5335 35.4267L42.5468 37.3333Z"
                        fill="#00AED9"
                      />
                      <path
                        d="M39.9994 14.6666C43.6813 14.6666 46.6661 11.6818 46.6661 7.99992C46.6661 4.31802 43.6813 1.33325 39.9994 1.33325C36.3175 1.33325 33.3328 4.31802 33.3328 7.99992C33.3328 11.6818 36.3175 14.6666 39.9994 14.6666Z"
                        fill="#00AED9"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_179_49">
                        <rect width="48" height="48" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>

                  <div className="modal_title">Recover via email</div>
                  <div className="modal_subtitle">
                    We will send a password recovery code to
                  </div>
                  <div className="modal_email">{maskedEmail}</div>
                  <button
                    className="modal_button"
                    onClick={handleEmailSend}
                    disabled={loading}
                  >
                    {loading ? "Loading..." : "Send"}
                  </button>
                </Box>
              )}
              {/* Not getting code Modal */}
              {stage === 4 && (
                <form className="email_modal" onSubmit={handleResendEmail}>
                  <div className="modal_subtitle bold">
                    Why are you not getting the code:
                  </div>

                  <ul>
                    <li>Check your spam folder.</li>
                    <li>Incorrect email address</li>
                  </ul>
                  <InputLabel className="modal_label" htmlFor="reenter-email">
                    Re-enter email address
                  </InputLabel>

                  <OutlinedInput
                    // className={
                    //   errorPresent ? "login-inputs login-error" : "login-inputs"
                    // }
                    className="modal_input"
                    required
                    id="reenter-email"
                    type="text"
                    value={email}
                    placeholder="Enter email address"
                    name="reenter-email"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                  <button className="modal_button">Resend Code</button>
                </form>
              )}

              {stage === 1 && (
                <form className="email_modal" onSubmit={handleEmailSubmit}>
                  <svg
                    className="confrirm_svg"
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_179_49)">
                      <path
                        d="M34.666 16.4533C33.2368 15.5538 32.0592 14.3065 31.2431 12.8282C30.4269 11.3498 29.999 9.68866 29.9993 8H5.22595C4.98668 8.00369 4.74899 8.03956 4.51929 8.10667L23.786 27.2933L34.666 16.4533Z"
                        fill="#00AED9"
                      />
                      <path
                        d="M40.0002 18C39.0979 17.9922 38.2008 17.8621 37.3335 17.6133L25.7202 29.1867C25.2205 29.6833 24.5446 29.9621 23.8402 29.9621C23.1357 29.9621 22.4598 29.6833 21.9602 29.1867L2.66682 10C2.60767 10.2174 2.57631 10.4414 2.57349 10.6667V37.3333C2.57349 38.0406 2.85444 38.7189 3.35453 39.219C3.85463 39.719 4.53291 40 5.24015 40H42.5735C43.2807 40 43.959 39.719 44.4591 39.219C44.9592 38.7189 45.2402 38.0406 45.2402 37.3333V16.52C43.6654 17.4918 41.8506 18.0044 40.0002 18ZM7.06682 37.3333H5.21349V35.4267L14.9068 25.8133L16.7868 27.6933L7.06682 37.3333ZM42.5468 37.3333H40.6802L30.9602 27.6933L32.8402 25.8133L42.5335 35.4267L42.5468 37.3333Z"
                        fill="#00AED9"
                      />
                      <path
                        d="M39.9994 14.6666C43.6813 14.6666 46.6661 11.6818 46.6661 7.99992C46.6661 4.31802 43.6813 1.33325 39.9994 1.33325C36.3175 1.33325 33.3328 4.31802 33.3328 7.99992C33.3328 11.6818 36.3175 14.6666 39.9994 14.6666Z"
                        fill="#00AED9"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_179_49">
                        <rect width="48" height="48" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>

                  <div className="modal_title">Input email address</div>

                  <InputLabel className="modal_label" htmlFor="email">
                    Email address
                  </InputLabel>

                  <OutlinedInput
                    // className={
                    //   errorPresent ? "login-inputs login-error" : "login-inputs"
                    // }
                    className="modal_input"
                    required
                    id="email"
                    type="text"
                    placeholder="Enter email address"
                    name="email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setForgotError(false);
                    }}
                  />
                  <button type="submit" className="modal_button">
                    Submit
                  </button>
                </form>
              )}
            </>
          )}
        </div>}
        {stage === 5 && (
          <form className="box" onSubmit={handleOTPSubmit}>
            <div className="box_title_verify box_title">
              2FA Verification
            </div>
            <div className="box_subtitle_verify box_subtitle">
              We sent a 6-digit-code to your email address.
            </div>
            <div className="box_subtitle_verify box_subtitle bold">
              Kindly input the code below.
            </div>
            <div className="otp_box_group">
              <div className="otp_box">
                <input
                  className="otp_input"
                  type="text"
                  maxlength="1"
                  required
                  value={otp1}
                  name="otp1"
                  onChange={(e) => {
                    setOtp1(e.target.value);
                    setOtpError(false);
                  }}
                />
              </div>

              <div className="otp_box">
                <input
                  className="otp_input"
                  type="text"
                  maxlength="1"
                  required
                  value={otp2}
                  name="otp2"
                  onChange={(e) => {
                    setOtp2(e.target.value);
                    setOtpError(false);
                  }}
                />
              </div>
              <div className="otp_box">
                <input
                  className="otp_input"
                  type="text"
                  maxlength="1"
                  required
                  value={otp3}
                  name="otp3"
                  onChange={(e) => {
                    setOtp3(e.target.value);
                    setOtpError(false);
                  }}
                />
              </div>
              <div className="otp_box">
                <input
                  className="otp_input"
                  type="text"
                  maxlength="1"
                  required
                  value={otp4}
                  name="otp4"
                  onChange={(e) => {
                    setOtp4(e.target.value);
                    setOtpError(false);
                  }}
                />
              </div>
              <div className="otp_box">
                <input
                  className="otp_input"
                  type="text"
                  maxlength="1"
                  required
                  value={otp5}
                  name="otp5"
                  onChange={(e) => {
                    setOtp5(e.target.value);
                    setOtpError(false);
                  }}
                />
              </div>
              <div className="otp_box">
                <input
                  className="otp_input"
                  type="text"
                  maxlength="1"
                  required
                  value={otp6}
                  name="otp6"
                  onChange={(e) => {
                    setOtp6(e.target.value);
                    setOtpError(false);
                  }}
                />
              </div>
            </div>

            {otpError && (
              <div className="otp_error_text">Invalid or Expired OTP</div>
            )}

            <button className="login_submit" type="submit" disabled={loading}>
              <div>{loading ? "Loading..." : "Verify"}</div>
            </button>
            <div className="error didnt_get pointer" onClick={handleModalOpen}>
              Nah, I didn’t get the code
            </div>
            <div className="didnt_get">
              <span className="didnt_get__error">Note:</span> Please check your{" "}
              <span>Spam filter</span> or <span>Junk mail</span> folder if you
              don’t receive an email us.
            </div>
            {modalOpen && (
              <form className="email_modal">
                <div className="modal_subtitle bold">
                  Why are you not getting the code:
                </div>

                <ul>
                  <li>Check your spam folder.</li>
                  <li>Incorrect email address</li>
                </ul>
                <InputLabel className="modal_label" htmlFor="reenter-email">
                  Re-enter email address
                </InputLabel>

                <OutlinedInput
                  // className={
                  //   errorPresent ? "login-inputs login-error" : "login-inputs"
                  // }
                  className="modal_input"
                  required
                  id="reenter-email"
                  type="text"
                  value={email}
                  placeholder="Enter email address"
                  name="reenter-email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                    // setErrorPresent(false);
                  }}
                />
                <button
                  className="modal_button"
                  type="submit"
                  onClick={handleResendEmail}
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Resend Code"}
                </button>
              </form>
            )}
          </form>
        )}
        </div>
      </div>
      {modalOpen && <div className="modal" onClick={handleModalClose}></div>}
    </div>
  );
};

export default Login;
