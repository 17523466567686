import { useEffect, useState } from "react";
import "../../styles/shared/Notifications.scss";
import {
  getDatabase,
  ref,
  onValue,
  orderByChild,
  equalTo,
  query,
  update,
} from "firebase/database";
import { getKyc } from "../../apis/General/users";
import { useSelector } from "react-redux";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const Notifications = ({ handleModalClose }) => {
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [stage, setStage] = useState(1);
  const [publicKey, setPublicKey] = useState(null);
  const [eventNotifications, setEventNotifications] = useState([]);
  const [firebaseNotifications, setFirebaseNotifications] = useState([]);
  const [firebaseStaticNotifications, setFirebaseStaticNotifications] =
    useState([]);
  const [firebaseStaticNotificationsKeys, setFirebaseStaticNotificationsKeys] =
    useState([]);
  const [staticNotifications, setStaticNotifications] = useState([]);
  const [staticNotificationsKeys, setStaticNotificationsKeys] = useState([]);
  const db = getDatabase();
  const tableRef = ref(db, "events");
  const tableRef2 = ref(db, "notifications");

  useEffect(() => {
    setOpen(true);
    getKycDetails();
  }, []);

  const getKycDetails = async () => {
    const response = await getKyc(auth?.userInfo?.id, auth?.token);
    console.log("getKyc", response);
    setPublicKey(response?.data?.message?.publicKey);
  };

  const getFirebaseNotifications = () => {
    // console.log("holla");
    const eventsQuery = query(
      tableRef2,
      orderByChild("user"),
      equalTo(auth?.userInfo?.id)
      // equalTo(response?.data?.message?.publicKey)
    );
    return onValue(eventsQuery, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        const valueData = Object.values(data);
        const valueKeys = Object.keys(data);
        setFirebaseStaticNotifications(valueData);
        setFirebaseStaticNotificationsKeys(valueKeys);

        console.log("valueData", valueData);
        // const prevNotifications = notifications;
        // prevNotifications.push(...filteredData);
        setFirebaseNotifications(valueData);
      } else {
        console.log(`No firebase notifications found for this user`);
      }
    });
  };
  const getEventNotifications = () => {
    // console.log("holla");
    const eventsQuery = query(
      tableRef,
      orderByChild("addressToRecord"),
      equalTo(publicKey)
      // equalTo(response?.data?.message?.publicKey)
    );
    return onValue(eventsQuery, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        const valueData = Object.values(data);
        const valueKeys = Object.keys(data);
        setStaticNotifications(valueData);
        setStaticNotificationsKeys(valueKeys);
        const filteredData = valueData.filter(
          (item) => item.type === "Carbon Credits Transferred" || "Perform KYC"
        );
        // const prevNotifications = notifications;
        // prevNotifications.push(...filteredData);
        setEventNotifications(filteredData);
      } else {
        console.log(`No CCRT data found for this user`);
      }
    });
  };

  const handleNotificationUpdate = (notification) => {
    update(
      ref(
        db,
        `events/${
          staticNotificationsKeys[staticNotifications.indexOf(notification)]
        }`
      ),
      {
        read: true,
      }
    );
  };
  const handleFirebaseNotificationUpdate = (notification) => {
    update(
      ref(
        db,
        `notifications/${
          firebaseStaticNotificationsKeys[
            firebaseStaticNotifications.indexOf(notification)
          ]
        }`
      ),
      {
        read: true,
      }
    );
  };

  useEffect(() => {
    // getEventNotifications();
    getFirebaseNotifications();
  }, [publicKey]);

  const closeModal = () => {
    setOpen(false);
    setTimeout(() => {
      handleModalClose();
    }, 500);
  };

  console.log("firebaseNotifications", firebaseNotifications);
  console.log("firebaseStaticNotifications", firebaseStaticNotifications);

  return (
    <div className={open ? "notifications open" : "notifications closed"}>
     
     <div className="close pointer">

      <svg
        
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={closeModal}
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.8999 0.500039C14.2999 0.650039 16.5499 1.85004 18.1999 3.50004C20.1499 5.60004 21.1999 8.15004 21.1999 11.15C21.1999 13.55 20.2999 15.8 18.7999 17.75C17.2999 19.55 15.1999 20.9 12.7999 21.35C10.3999 21.8 7.99994 21.5 5.89994 20.3C3.79994 19.1 2.14994 17.3 1.24994 15.05C0.349935 12.8 0.199935 10.25 0.949935 8.00004C1.69994 5.60004 3.04994 3.65004 5.14994 2.30004C7.09994 0.950039 9.49994 0.350039 11.8999 0.500039ZM12.6499 19.85C14.5999 19.4 16.3999 18.35 17.7499 16.7C18.9499 15.05 19.6999 13.1 19.5499 11C19.5499 8.60004 18.6499 6.20004 16.9999 4.55004C15.4999 3.05004 13.6999 2.15004 11.5999 2.00004C9.64994 1.85004 7.54994 2.30004 5.89994 3.50004C4.24994 4.70004 3.04994 6.35004 2.44994 8.45004C1.84994 10.4 1.84994 12.5 2.74994 14.45C3.64994 16.4 4.99994 17.9 6.79994 18.95C8.59994 20 10.6999 20.3 12.6499 19.85ZM10.8499 10.25L14.4499 6.50004L15.4999 7.55004L11.8999 11.3L15.4999 15.05L14.4499 16.1L10.8499 12.35L7.24994 16.1L6.19994 15.05L9.79994 11.3L6.19994 7.55004L7.24994 6.50004L10.8499 10.25Z"
          fill="#667185"
        />
      </svg>
     </div>

      <div className="notifications_title">Notifications</div>
      {firebaseNotifications.map((notification) => (
        <>
        {!notification?.read &&
            notification?.type === "Carbon credit issued" && (
              <div className="notification_card">
                <div className="icon">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.5 15.25C10.307 15.2352 10.1276 15.1455 9.99998 15L6.99998 12C6.93314 11.86 6.91133 11.7028 6.93756 11.5499C6.96379 11.3971 7.03676 11.2561 7.14643 11.1464C7.2561 11.0368 7.39707 10.9638 7.54993 10.9376C7.70279 10.9113 7.86003 10.9331 7.99998 11L10.47 13.47L19 4.99998C19.1399 4.93314 19.2972 4.91133 19.45 4.93756C19.6029 4.96379 19.7439 5.03676 19.8535 5.14643C19.9632 5.2561 20.0362 5.39707 20.0624 5.54993C20.0886 5.70279 20.0668 5.86003 20 5.99998L11 15C10.8724 15.1455 10.6929 15.2352 10.5 15.25Z"
                      fill="#40C73D"
                    />
                    <path
                      d="M12.0001 21C10.3916 20.9974 8.81313 20.5638 7.42903 19.7442C6.04494 18.9246 4.90578 17.7491 4.13011 16.34C3.54049 15.2899 3.17608 14.1286 3.06011 12.93C2.87709 11.172 3.21572 9.39915 4.03375 7.83243C4.85179 6.26571 6.11302 4.97454 7.66011 4.11996C8.71013 3.53035 9.87147 3.16594 11.0701 3.04996C12.2643 2.92151 13.4721 3.03719 14.6201 3.38996C14.7226 3.41044 14.8196 3.45209 14.905 3.51225C14.9905 3.57242 15.0624 3.64977 15.1162 3.73935C15.1699 3.82892 15.2044 3.92875 15.2174 4.03242C15.2303 4.1361 15.2215 4.24134 15.1914 4.3414C15.1613 4.44146 15.1106 4.53412 15.0427 4.61346C14.9747 4.6928 14.8909 4.75705 14.7966 4.80211C14.7023 4.84717 14.5997 4.87203 14.4952 4.8751C14.3908 4.87818 14.2869 4.8594 14.1901 4.81996C13.2187 4.52788 12.1988 4.43269 11.1901 4.53996C10.1931 4.64205 9.22706 4.94479 8.35011 5.42996C7.50524 5.89607 6.75825 6.52082 6.15011 7.26996C5.52397 8.03312 5.0564 8.91355 4.7748 9.85968C4.49319 10.8058 4.4032 11.7986 4.51011 12.78C4.6122 13.777 4.91494 14.743 5.40011 15.62C5.86621 16.4648 6.49096 17.2118 7.24011 17.82C8.00327 18.4461 8.8837 18.9137 9.82982 19.1953C10.776 19.4769 11.7688 19.5669 12.7501 19.46C13.7471 19.3579 14.7132 19.0551 15.5901 18.57C16.435 18.1039 17.182 17.4791 17.7901 16.73C18.4162 15.9668 18.8838 15.0864 19.1654 14.1402C19.447 13.1941 19.537 12.2013 19.4301 11.22C19.4102 11.0118 19.4738 10.8042 19.607 10.6429C19.7401 10.4816 19.9319 10.3799 20.1401 10.36C20.3483 10.3401 20.5559 10.4037 20.7172 10.5369C20.8784 10.67 20.9802 10.8618 21.0001 11.07C21.1822 12.8289 20.8421 14.6026 20.0222 16.1694C19.2023 17.7362 17.9391 19.0268 16.3901 19.88C15.3289 20.4937 14.1497 20.8754 12.9301 21H12.0001Z"
                      fill="#40C73D"
                    />
                  </svg>
                </div>
                <div className="content">
                  <div className="notifications_card_title">
                    You have received CCRT!
                  </div>
                  <div className="subtitle">
                    Go to the <span className="subtitle__green"
                    onClick={() => navigate('/carbon-credit')}
                    >CCRT page</span> or check your metamask wallet to see your CCRT
                  </div>

                  <div className="time">{`${moment(
                    parseInt(notification?.timestamp)
                  ).format("Do MMM YYYY")} ${moment(
                    parseInt(notification?.timestamp)
                  ).format("hh:mm A")}`}</div>
                </div>
                <div
                  className="icon pointer"
                  onClick={() => handleFirebaseNotificationUpdate(notification)}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 13.4L7.10005 18.3C6.91672 18.4834 6.68338 18.575 6.40005 18.575C6.11672 18.575 5.88338 18.4834 5.70005 18.3C5.51672 18.1167 5.42505 17.8834 5.42505 17.6C5.42505 17.3167 5.51672 17.0834 5.70005 16.9L10.6 12L5.70005 7.10005C5.51672 6.91672 5.42505 6.68338 5.42505 6.40005C5.42505 6.11672 5.51672 5.88338 5.70005 5.70005C5.88338 5.51672 6.11672 5.42505 6.40005 5.42505C6.68338 5.42505 6.91672 5.51672 7.10005 5.70005L12 10.6L16.9 5.70005C17.0834 5.51672 17.3167 5.42505 17.6 5.42505C17.8834 5.42505 18.1167 5.51672 18.3 5.70005C18.4834 5.88338 18.575 6.11672 18.575 6.40005C18.575 6.68338 18.4834 6.91672 18.3 7.10005L13.4 12L18.3 16.9C18.4834 17.0834 18.575 17.3167 18.575 17.6C18.575 17.8834 18.4834 18.1167 18.3 18.3C18.1167 18.4834 17.8834 18.575 17.6 18.575C17.3167 18.575 17.0834 18.4834 16.9 18.3L12 13.4Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </div>
            )}
          {!notification?.read &&
            notification?.type === "Incomplete Profile" && (
              <div className="notification_card">
                <div className="icon">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 16.75C11.8019 16.7474 11.6126 16.6676 11.4725 16.5275C11.3324 16.3874 11.2526 16.1981 11.25 16V11C11.25 10.8011 11.329 10.6103 11.4697 10.4697C11.6103 10.329 11.8011 10.25 12 10.25C12.1989 10.25 12.3897 10.329 12.5303 10.4697C12.671 10.6103 12.75 10.8011 12.75 11V16C12.7474 16.1981 12.6676 16.3874 12.5275 16.5275C12.3874 16.6676 12.1981 16.7474 12 16.75ZM12 9.25C11.8019 9.24741 11.6126 9.16756 11.4725 9.02747C11.3324 8.88737 11.2526 8.69811 11.25 8.5V8C11.25 7.80109 11.329 7.61032 11.4697 7.46967C11.6103 7.32902 11.8011 7.25 12 7.25C12.1989 7.25 12.3897 7.32902 12.5303 7.46967C12.671 7.61032 12.75 7.80109 12.75 8V8.5C12.7474 8.69811 12.6676 8.88737 12.5275 9.02747C12.3874 9.16756 12.1981 9.24741 12 9.25Z"
                      fill="#F49F0A"
                    />
                    <path
                      d="M12 21C10.22 21 8.47991 20.4722 6.99987 19.4832C5.51983 18.4943 4.36628 17.0887 3.68509 15.4442C3.0039 13.7996 2.82567 11.99 3.17294 10.2442C3.5202 8.49836 4.37737 6.89472 5.63604 5.63604C6.89472 4.37737 8.49836 3.5202 10.2442 3.17294C11.99 2.82567 13.7996 3.0039 15.4442 3.68509C17.0887 4.36628 18.4943 5.51983 19.4832 6.99987C20.4722 8.47991 21 10.22 21 12C21 14.387 20.0518 16.6761 18.364 18.364C16.6761 20.0518 14.387 21 12 21ZM12 4.5C10.5166 4.5 9.0666 4.93987 7.83323 5.76398C6.59986 6.58809 5.63856 7.75943 5.07091 9.12988C4.50325 10.5003 4.35473 12.0083 4.64411 13.4632C4.9335 14.918 5.64781 16.2544 6.6967 17.3033C7.7456 18.3522 9.08197 19.0665 10.5368 19.3559C11.9917 19.6453 13.4997 19.4968 14.8701 18.9291C16.2406 18.3614 17.4119 17.4001 18.236 16.1668C19.0601 14.9334 19.5 13.4834 19.5 12C19.5 10.0109 18.7098 8.10323 17.3033 6.6967C15.8968 5.29018 13.9891 4.5 12 4.5Z"
                      fill="#F49F0A"
                    />
                  </svg>
                </div>
                <div className="content">
                  <div className="notifications_card_title">
                    Profile Incomplete
                  </div>
                  <div className="subtitle">
                    Please visit your profile and verify your details to have
                    full access to the features of CloseCarbon
                  </div>

                  <div className="time">
                    {`${moment(parseInt(notification?.timestamp)).format(
                      "Do MMM YYYY"
                    )} ${moment(parseInt(notification?.timestamp)).format(
                      "hh:mm A"
                    )}`}
                  </div>
                </div>
                <div
                  className="icon pointer"
                  onClick={() => handleFirebaseNotificationUpdate(notification)}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 13.4L7.10005 18.3C6.91672 18.4834 6.68338 18.575 6.40005 18.575C6.11672 18.575 5.88338 18.4834 5.70005 18.3C5.51672 18.1167 5.42505 17.8834 5.42505 17.6C5.42505 17.3167 5.51672 17.0834 5.70005 16.9L10.6 12L5.70005 7.10005C5.51672 6.91672 5.42505 6.68338 5.42505 6.40005C5.42505 6.11672 5.51672 5.88338 5.70005 5.70005C5.88338 5.51672 6.11672 5.42505 6.40005 5.42505C6.68338 5.42505 6.91672 5.51672 7.10005 5.70005L12 10.6L16.9 5.70005C17.0834 5.51672 17.3167 5.42505 17.6 5.42505C17.8834 5.42505 18.1167 5.51672 18.3 5.70005C18.4834 5.88338 18.575 6.11672 18.575 6.40005C18.575 6.68338 18.4834 6.91672 18.3 7.10005L13.4 12L18.3 16.9C18.4834 17.0834 18.575 17.3167 18.575 17.6C18.575 17.8834 18.4834 18.1167 18.3 18.3C18.1167 18.4834 17.8834 18.575 17.6 18.575C17.3167 18.575 17.0834 18.4834 16.9 18.3L12 13.4Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </div>
            )}
          {!notification?.read &&
            notification?.type === "ID Verification complete" && (
              <div className="notification_card">
                <div className="icon">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.5 15.25C10.307 15.2352 10.1276 15.1455 9.99998 15L6.99998 12C6.93314 11.86 6.91133 11.7028 6.93756 11.5499C6.96379 11.3971 7.03676 11.2561 7.14643 11.1464C7.2561 11.0368 7.39707 10.9638 7.54993 10.9376C7.70279 10.9113 7.86003 10.9331 7.99998 11L10.47 13.47L19 4.99998C19.1399 4.93314 19.2972 4.91133 19.45 4.93756C19.6029 4.96379 19.7439 5.03676 19.8535 5.14643C19.9632 5.2561 20.0362 5.39707 20.0624 5.54993C20.0886 5.70279 20.0668 5.86003 20 5.99998L11 15C10.8724 15.1455 10.6929 15.2352 10.5 15.25Z"
                      fill="#40C73D"
                    />
                    <path
                      d="M12.0001 21C10.3916 20.9974 8.81313 20.5638 7.42903 19.7442C6.04494 18.9246 4.90578 17.7491 4.13011 16.34C3.54049 15.2899 3.17608 14.1286 3.06011 12.93C2.87709 11.172 3.21572 9.39915 4.03375 7.83243C4.85179 6.26571 6.11302 4.97454 7.66011 4.11996C8.71013 3.53035 9.87147 3.16594 11.0701 3.04996C12.2643 2.92151 13.4721 3.03719 14.6201 3.38996C14.7226 3.41044 14.8196 3.45209 14.905 3.51225C14.9905 3.57242 15.0624 3.64977 15.1162 3.73935C15.1699 3.82892 15.2044 3.92875 15.2174 4.03242C15.2303 4.1361 15.2215 4.24134 15.1914 4.3414C15.1613 4.44146 15.1106 4.53412 15.0427 4.61346C14.9747 4.6928 14.8909 4.75705 14.7966 4.80211C14.7023 4.84717 14.5997 4.87203 14.4952 4.8751C14.3908 4.87818 14.2869 4.8594 14.1901 4.81996C13.2187 4.52788 12.1988 4.43269 11.1901 4.53996C10.1931 4.64205 9.22706 4.94479 8.35011 5.42996C7.50524 5.89607 6.75825 6.52082 6.15011 7.26996C5.52397 8.03312 5.0564 8.91355 4.7748 9.85968C4.49319 10.8058 4.4032 11.7986 4.51011 12.78C4.6122 13.777 4.91494 14.743 5.40011 15.62C5.86621 16.4648 6.49096 17.2118 7.24011 17.82C8.00327 18.4461 8.8837 18.9137 9.82982 19.1953C10.776 19.4769 11.7688 19.5669 12.7501 19.46C13.7471 19.3579 14.7132 19.0551 15.5901 18.57C16.435 18.1039 17.182 17.4791 17.7901 16.73C18.4162 15.9668 18.8838 15.0864 19.1654 14.1402C19.447 13.1941 19.537 12.2013 19.4301 11.22C19.4102 11.0118 19.4738 10.8042 19.607 10.6429C19.7401 10.4816 19.9319 10.3799 20.1401 10.36C20.3483 10.3401 20.5559 10.4037 20.7172 10.5369C20.8784 10.67 20.9802 10.8618 21.0001 11.07C21.1822 12.8289 20.8421 14.6026 20.0222 16.1694C19.2023 17.7362 17.9391 19.0268 16.3901 19.88C15.3289 20.4937 14.1497 20.8754 12.9301 21H12.0001Z"
                      fill="#40C73D"
                    />
                  </svg>
                </div>
                <div className="content">
                  <div className="notifications_card_title">
                    ID Verification Complete
                  </div>
                  <div className="subtitle">
                    Your ID verification has been completed.
                  </div>

                  <div className="time">{`${moment(
                    parseInt(notification?.timestamp)
                  ).format("Do MMM YYYY")} ${moment(
                    parseInt(notification?.timestamp)
                  ).format("hh:mm A")}`}</div>
                </div>
                <div
                  className="icon pointer"
                  onClick={() => handleFirebaseNotificationUpdate(notification)}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 13.4L7.10005 18.3C6.91672 18.4834 6.68338 18.575 6.40005 18.575C6.11672 18.575 5.88338 18.4834 5.70005 18.3C5.51672 18.1167 5.42505 17.8834 5.42505 17.6C5.42505 17.3167 5.51672 17.0834 5.70005 16.9L10.6 12L5.70005 7.10005C5.51672 6.91672 5.42505 6.68338 5.42505 6.40005C5.42505 6.11672 5.51672 5.88338 5.70005 5.70005C5.88338 5.51672 6.11672 5.42505 6.40005 5.42505C6.68338 5.42505 6.91672 5.51672 7.10005 5.70005L12 10.6L16.9 5.70005C17.0834 5.51672 17.3167 5.42505 17.6 5.42505C17.8834 5.42505 18.1167 5.51672 18.3 5.70005C18.4834 5.88338 18.575 6.11672 18.575 6.40005C18.575 6.68338 18.4834 6.91672 18.3 7.10005L13.4 12L18.3 16.9C18.4834 17.0834 18.575 17.3167 18.575 17.6C18.575 17.8834 18.4834 18.1167 18.3 18.3C18.1167 18.4834 17.8834 18.575 17.6 18.575C17.3167 18.575 17.0834 18.4834 16.9 18.3L12 13.4Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </div>
            )}
          {!notification?.read &&
            notification?.type === "ID Verification in progress" && (
              <div className="notification_card">
                <div className="icon">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 16.75C11.8019 16.7474 11.6126 16.6676 11.4725 16.5275C11.3324 16.3874 11.2526 16.1981 11.25 16V11C11.25 10.8011 11.329 10.6103 11.4697 10.4697C11.6103 10.329 11.8011 10.25 12 10.25C12.1989 10.25 12.3897 10.329 12.5303 10.4697C12.671 10.6103 12.75 10.8011 12.75 11V16C12.7474 16.1981 12.6676 16.3874 12.5275 16.5275C12.3874 16.6676 12.1981 16.7474 12 16.75ZM12 9.25C11.8019 9.24741 11.6126 9.16756 11.4725 9.02747C11.3324 8.88737 11.2526 8.69811 11.25 8.5V8C11.25 7.80109 11.329 7.61032 11.4697 7.46967C11.6103 7.32902 11.8011 7.25 12 7.25C12.1989 7.25 12.3897 7.32902 12.5303 7.46967C12.671 7.61032 12.75 7.80109 12.75 8V8.5C12.7474 8.69811 12.6676 8.88737 12.5275 9.02747C12.3874 9.16756 12.1981 9.24741 12 9.25Z"
                      fill="#F49F0A"
                    />
                    <path
                      d="M12 21C10.22 21 8.47991 20.4722 6.99987 19.4832C5.51983 18.4943 4.36628 17.0887 3.68509 15.4442C3.0039 13.7996 2.82567 11.99 3.17294 10.2442C3.5202 8.49836 4.37737 6.89472 5.63604 5.63604C6.89472 4.37737 8.49836 3.5202 10.2442 3.17294C11.99 2.82567 13.7996 3.0039 15.4442 3.68509C17.0887 4.36628 18.4943 5.51983 19.4832 6.99987C20.4722 8.47991 21 10.22 21 12C21 14.387 20.0518 16.6761 18.364 18.364C16.6761 20.0518 14.387 21 12 21ZM12 4.5C10.5166 4.5 9.0666 4.93987 7.83323 5.76398C6.59986 6.58809 5.63856 7.75943 5.07091 9.12988C4.50325 10.5003 4.35473 12.0083 4.64411 13.4632C4.9335 14.918 5.64781 16.2544 6.6967 17.3033C7.7456 18.3522 9.08197 19.0665 10.5368 19.3559C11.9917 19.6453 13.4997 19.4968 14.8701 18.9291C16.2406 18.3614 17.4119 17.4001 18.236 16.1668C19.0601 14.9334 19.5 13.4834 19.5 12C19.5 10.0109 18.7098 8.10323 17.3033 6.6967C15.8968 5.29018 13.9891 4.5 12 4.5Z"
                      fill="#F49F0A"
                    />
                  </svg>
                </div>
                <div className="content">
                  <div className="notifications_card_title">
                    ID Verification In Progress
                  </div>
                  <div className="subtitle">
                    Your ID verification is in progress. We'll notify you when
                    it's complete
                  </div>

                  <div className="time">{`${moment(
                    parseInt(notification?.timestamp)
                  ).format("Do MMM YYYY")} ${moment(
                    parseInt(notification?.timestamp)
                  ).format("hh:mm A")}`}</div>
                </div>
                <div
                  className="icon pointer"
                  onClick={() => handleFirebaseNotificationUpdate(notification)}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 13.4L7.10005 18.3C6.91672 18.4834 6.68338 18.575 6.40005 18.575C6.11672 18.575 5.88338 18.4834 5.70005 18.3C5.51672 18.1167 5.42505 17.8834 5.42505 17.6C5.42505 17.3167 5.51672 17.0834 5.70005 16.9L10.6 12L5.70005 7.10005C5.51672 6.91672 5.42505 6.68338 5.42505 6.40005C5.42505 6.11672 5.51672 5.88338 5.70005 5.70005C5.88338 5.51672 6.11672 5.42505 6.40005 5.42505C6.68338 5.42505 6.91672 5.51672 7.10005 5.70005L12 10.6L16.9 5.70005C17.0834 5.51672 17.3167 5.42505 17.6 5.42505C17.8834 5.42505 18.1167 5.51672 18.3 5.70005C18.4834 5.88338 18.575 6.11672 18.575 6.40005C18.575 6.68338 18.4834 6.91672 18.3 7.10005L13.4 12L18.3 16.9C18.4834 17.0834 18.575 17.3167 18.575 17.6C18.575 17.8834 18.4834 18.1167 18.3 18.3C18.1167 18.4834 17.8834 18.575 17.6 18.575C17.3167 18.575 17.0834 18.4834 16.9 18.3L12 13.4Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </div>
            )}
          {!notification?.read &&
            notification?.type === "ID Verification failed" && (
              <div className="notification_card">
                <div className="icon">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12.8999 1.50004C15.2999 1.65004 17.5499 2.85004 19.1999 4.50004C21.1499 6.60004 22.1999 9.15004 22.1999 12.15C22.1999 14.55 21.2999 16.8 19.7999 18.75C18.2999 20.55 16.1999 21.9 13.7999 22.35C11.3999 22.8 8.99994 22.5 6.89994 21.3C4.79994 20.1 3.14994 18.3 2.24994 16.05C1.34994 13.8 1.19994 11.25 1.94994 9.00004C2.69994 6.60004 4.04994 4.65004 6.14994 3.30004C8.09994 1.95004 10.4999 1.35004 12.8999 1.50004ZM13.6499 20.85C15.5999 20.4 17.3999 19.35 18.7499 17.7C19.9499 16.05 20.6999 14.1 20.5499 12C20.5499 9.60004 19.6499 7.20004 17.9999 5.55004C16.4999 4.05004 14.6999 3.15004 12.5999 3.00004C10.6499 2.85004 8.54994 3.30004 6.89994 4.50004C5.24994 5.70004 4.04994 7.35004 3.44994 9.45004C2.84994 11.4 2.84994 13.5 3.74994 15.45C4.64994 17.4 5.99994 18.9 7.79994 19.95C9.59994 21 11.6999 21.3 13.6499 20.85ZM11.8499 11.25L15.4499 7.50004L16.4999 8.55004L12.8999 12.3L16.4999 16.05L15.4499 17.1L11.8499 13.35L8.24994 17.1L7.19994 16.05L10.7999 12.3L7.19994 8.55004L8.24994 7.50004L11.8499 11.25Z"
                      fill="#E43424"
                    />
                  </svg>
                </div>
                <div className="content">
                  <div className="notifications_card_title">
                    ID Verification Failed
                  </div>
                  <div className="subtitle">
                    Your ID verification was unsuccessful. Kindly go through the
                    process again.
                  </div>

                  <div className="time">{`${moment(
                    parseInt(notification?.timestamp)
                  ).format("Do MMM YYYY")} ${moment(
                    parseInt(notification?.timestamp)
                  ).format("hh:mm A")}`}</div>
                </div>
                <div
                  className="icon pointer"
                  onClick={() => handleFirebaseNotificationUpdate(notification)}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 13.4L7.10005 18.3C6.91672 18.4834 6.68338 18.575 6.40005 18.575C6.11672 18.575 5.88338 18.4834 5.70005 18.3C5.51672 18.1167 5.42505 17.8834 5.42505 17.6C5.42505 17.3167 5.51672 17.0834 5.70005 16.9L10.6 12L5.70005 7.10005C5.51672 6.91672 5.42505 6.68338 5.42505 6.40005C5.42505 6.11672 5.51672 5.88338 5.70005 5.70005C5.88338 5.51672 6.11672 5.42505 6.40005 5.42505C6.68338 5.42505 6.91672 5.51672 7.10005 5.70005L12 10.6L16.9 5.70005C17.0834 5.51672 17.3167 5.42505 17.6 5.42505C17.8834 5.42505 18.1167 5.51672 18.3 5.70005C18.4834 5.88338 18.575 6.11672 18.575 6.40005C18.575 6.68338 18.4834 6.91672 18.3 7.10005L13.4 12L18.3 16.9C18.4834 17.0834 18.575 17.3167 18.575 17.6C18.575 17.8834 18.4834 18.1167 18.3 18.3C18.1167 18.4834 17.8834 18.575 17.6 18.575C17.3167 18.575 17.0834 18.4834 16.9 18.3L12 13.4Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </div>
            )}
          {!notification?.read && notification?.type === "IoT registered" && (
            <div className="notification_card">
              <div className="icon">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.5 15.25C10.307 15.2352 10.1276 15.1455 9.99998 15L6.99998 12C6.93314 11.86 6.91133 11.7028 6.93756 11.5499C6.96379 11.3971 7.03676 11.2561 7.14643 11.1464C7.2561 11.0368 7.39707 10.9638 7.54993 10.9376C7.70279 10.9113 7.86003 10.9331 7.99998 11L10.47 13.47L19 4.99998C19.1399 4.93314 19.2972 4.91133 19.45 4.93756C19.6029 4.96379 19.7439 5.03676 19.8535 5.14643C19.9632 5.2561 20.0362 5.39707 20.0624 5.54993C20.0886 5.70279 20.0668 5.86003 20 5.99998L11 15C10.8724 15.1455 10.6929 15.2352 10.5 15.25Z"
                    fill="#40C73D"
                  />
                  <path
                    d="M12.0001 21C10.3916 20.9974 8.81313 20.5638 7.42903 19.7442C6.04494 18.9246 4.90578 17.7491 4.13011 16.34C3.54049 15.2899 3.17608 14.1286 3.06011 12.93C2.87709 11.172 3.21572 9.39915 4.03375 7.83243C4.85179 6.26571 6.11302 4.97454 7.66011 4.11996C8.71013 3.53035 9.87147 3.16594 11.0701 3.04996C12.2643 2.92151 13.4721 3.03719 14.6201 3.38996C14.7226 3.41044 14.8196 3.45209 14.905 3.51225C14.9905 3.57242 15.0624 3.64977 15.1162 3.73935C15.1699 3.82892 15.2044 3.92875 15.2174 4.03242C15.2303 4.1361 15.2215 4.24134 15.1914 4.3414C15.1613 4.44146 15.1106 4.53412 15.0427 4.61346C14.9747 4.6928 14.8909 4.75705 14.7966 4.80211C14.7023 4.84717 14.5997 4.87203 14.4952 4.8751C14.3908 4.87818 14.2869 4.8594 14.1901 4.81996C13.2187 4.52788 12.1988 4.43269 11.1901 4.53996C10.1931 4.64205 9.22706 4.94479 8.35011 5.42996C7.50524 5.89607 6.75825 6.52082 6.15011 7.26996C5.52397 8.03312 5.0564 8.91355 4.7748 9.85968C4.49319 10.8058 4.4032 11.7986 4.51011 12.78C4.6122 13.777 4.91494 14.743 5.40011 15.62C5.86621 16.4648 6.49096 17.2118 7.24011 17.82C8.00327 18.4461 8.8837 18.9137 9.82982 19.1953C10.776 19.4769 11.7688 19.5669 12.7501 19.46C13.7471 19.3579 14.7132 19.0551 15.5901 18.57C16.435 18.1039 17.182 17.4791 17.7901 16.73C18.4162 15.9668 18.8838 15.0864 19.1654 14.1402C19.447 13.1941 19.537 12.2013 19.4301 11.22C19.4102 11.0118 19.4738 10.8042 19.607 10.6429C19.7401 10.4816 19.9319 10.3799 20.1401 10.36C20.3483 10.3401 20.5559 10.4037 20.7172 10.5369C20.8784 10.67 20.9802 10.8618 21.0001 11.07C21.1822 12.8289 20.8421 14.6026 20.0222 16.1694C19.2023 17.7362 17.9391 19.0268 16.3901 19.88C15.3289 20.4937 14.1497 20.8754 12.9301 21H12.0001Z"
                    fill="#40C73D"
                  />
                </svg>
              </div>
              <div className="content">
                <div className="notifications_card_title">IoT registered</div>
                <div className="subtitle">
                  An IoT device has been installed at your facility!
                </div>

                <div className="time">{`${moment(
                  parseInt(notification?.timestamp)
                ).format("Do MMM YYYY")} ${moment(
                  parseInt(notification?.timestamp)
                ).format("hh:mm A")}`}</div>
              </div>
              <div
                className="icon pointer"
                onClick={() => handleFirebaseNotificationUpdate(notification)}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 13.4L7.10005 18.3C6.91672 18.4834 6.68338 18.575 6.40005 18.575C6.11672 18.575 5.88338 18.4834 5.70005 18.3C5.51672 18.1167 5.42505 17.8834 5.42505 17.6C5.42505 17.3167 5.51672 17.0834 5.70005 16.9L10.6 12L5.70005 7.10005C5.51672 6.91672 5.42505 6.68338 5.42505 6.40005C5.42505 6.11672 5.51672 5.88338 5.70005 5.70005C5.88338 5.51672 6.11672 5.42505 6.40005 5.42505C6.68338 5.42505 6.91672 5.51672 7.10005 5.70005L12 10.6L16.9 5.70005C17.0834 5.51672 17.3167 5.42505 17.6 5.42505C17.8834 5.42505 18.1167 5.51672 18.3 5.70005C18.4834 5.88338 18.575 6.11672 18.575 6.40005C18.575 6.68338 18.4834 6.91672 18.3 7.10005L13.4 12L18.3 16.9C18.4834 17.0834 18.575 17.3167 18.575 17.6C18.575 17.8834 18.4834 18.1167 18.3 18.3C18.1167 18.4834 17.8834 18.575 17.6 18.575C17.3167 18.575 17.0834 18.4834 16.9 18.3L12 13.4Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
          )}
          {!notification?.read &&
            notification?.type === "NDC request approved" && (
              <div className="notification_card">
                <div className="icon">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.5 15.25C10.307 15.2352 10.1276 15.1455 9.99998 15L6.99998 12C6.93314 11.86 6.91133 11.7028 6.93756 11.5499C6.96379 11.3971 7.03676 11.2561 7.14643 11.1464C7.2561 11.0368 7.39707 10.9638 7.54993 10.9376C7.70279 10.9113 7.86003 10.9331 7.99998 11L10.47 13.47L19 4.99998C19.1399 4.93314 19.2972 4.91133 19.45 4.93756C19.6029 4.96379 19.7439 5.03676 19.8535 5.14643C19.9632 5.2561 20.0362 5.39707 20.0624 5.54993C20.0886 5.70279 20.0668 5.86003 20 5.99998L11 15C10.8724 15.1455 10.6929 15.2352 10.5 15.25Z"
                      fill="#40C73D"
                    />
                    <path
                      d="M12.0001 21C10.3916 20.9974 8.81313 20.5638 7.42903 19.7442C6.04494 18.9246 4.90578 17.7491 4.13011 16.34C3.54049 15.2899 3.17608 14.1286 3.06011 12.93C2.87709 11.172 3.21572 9.39915 4.03375 7.83243C4.85179 6.26571 6.11302 4.97454 7.66011 4.11996C8.71013 3.53035 9.87147 3.16594 11.0701 3.04996C12.2643 2.92151 13.4721 3.03719 14.6201 3.38996C14.7226 3.41044 14.8196 3.45209 14.905 3.51225C14.9905 3.57242 15.0624 3.64977 15.1162 3.73935C15.1699 3.82892 15.2044 3.92875 15.2174 4.03242C15.2303 4.1361 15.2215 4.24134 15.1914 4.3414C15.1613 4.44146 15.1106 4.53412 15.0427 4.61346C14.9747 4.6928 14.8909 4.75705 14.7966 4.80211C14.7023 4.84717 14.5997 4.87203 14.4952 4.8751C14.3908 4.87818 14.2869 4.8594 14.1901 4.81996C13.2187 4.52788 12.1988 4.43269 11.1901 4.53996C10.1931 4.64205 9.22706 4.94479 8.35011 5.42996C7.50524 5.89607 6.75825 6.52082 6.15011 7.26996C5.52397 8.03312 5.0564 8.91355 4.7748 9.85968C4.49319 10.8058 4.4032 11.7986 4.51011 12.78C4.6122 13.777 4.91494 14.743 5.40011 15.62C5.86621 16.4648 6.49096 17.2118 7.24011 17.82C8.00327 18.4461 8.8837 18.9137 9.82982 19.1953C10.776 19.4769 11.7688 19.5669 12.7501 19.46C13.7471 19.3579 14.7132 19.0551 15.5901 18.57C16.435 18.1039 17.182 17.4791 17.7901 16.73C18.4162 15.9668 18.8838 15.0864 19.1654 14.1402C19.447 13.1941 19.537 12.2013 19.4301 11.22C19.4102 11.0118 19.4738 10.8042 19.607 10.6429C19.7401 10.4816 19.9319 10.3799 20.1401 10.36C20.3483 10.3401 20.5559 10.4037 20.7172 10.5369C20.8784 10.67 20.9802 10.8618 21.0001 11.07C21.1822 12.8289 20.8421 14.6026 20.0222 16.1694C19.2023 17.7362 17.9391 19.0268 16.3901 19.88C15.3289 20.4937 14.1497 20.8754 12.9301 21H12.0001Z"
                      fill="#40C73D"
                    />
                  </svg>
                </div>
                <div className="content">
                  <div className="notifications_card_title">
                  NDCs-S request approved
                  </div>
                  <div className="subtitle">
                    Your NDCs-S request have been approved! please visit the NDCs-S
                    page to access the NDCs-S feature
                  </div>

                  <div className="time">{`${moment(
                    parseInt(notification?.timestamp)
                  ).format("Do MMM YYYY")} ${moment(
                    parseInt(notification?.timestamp)
                  ).format("hh:mm A")}`}</div>
                </div>
                <div
                  className="icon pointer"
                  onClick={() => handleFirebaseNotificationUpdate(notification)}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 13.4L7.10005 18.3C6.91672 18.4834 6.68338 18.575 6.40005 18.575C6.11672 18.575 5.88338 18.4834 5.70005 18.3C5.51672 18.1167 5.42505 17.8834 5.42505 17.6C5.42505 17.3167 5.51672 17.0834 5.70005 16.9L10.6 12L5.70005 7.10005C5.51672 6.91672 5.42505 6.68338 5.42505 6.40005C5.42505 6.11672 5.51672 5.88338 5.70005 5.70005C5.88338 5.51672 6.11672 5.42505 6.40005 5.42505C6.68338 5.42505 6.91672 5.51672 7.10005 5.70005L12 10.6L16.9 5.70005C17.0834 5.51672 17.3167 5.42505 17.6 5.42505C17.8834 5.42505 18.1167 5.51672 18.3 5.70005C18.4834 5.88338 18.575 6.11672 18.575 6.40005C18.575 6.68338 18.4834 6.91672 18.3 7.10005L13.4 12L18.3 16.9C18.4834 17.0834 18.575 17.3167 18.575 17.6C18.575 17.8834 18.4834 18.1167 18.3 18.3C18.1167 18.4834 17.8834 18.575 17.6 18.575C17.3167 18.575 17.0834 18.4834 16.9 18.3L12 13.4Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </div>
            )}

          {!notification?.read &&
            notification?.type === "Validation in progress" && (
              <div className="notification_card">
                <div className="icon">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 16.75C11.8019 16.7474 11.6126 16.6676 11.4725 16.5275C11.3324 16.3874 11.2526 16.1981 11.25 16V11C11.25 10.8011 11.329 10.6103 11.4697 10.4697C11.6103 10.329 11.8011 10.25 12 10.25C12.1989 10.25 12.3897 10.329 12.5303 10.4697C12.671 10.6103 12.75 10.8011 12.75 11V16C12.7474 16.1981 12.6676 16.3874 12.5275 16.5275C12.3874 16.6676 12.1981 16.7474 12 16.75ZM12 9.25C11.8019 9.24741 11.6126 9.16756 11.4725 9.02747C11.3324 8.88737 11.2526 8.69811 11.25 8.5V8C11.25 7.80109 11.329 7.61032 11.4697 7.46967C11.6103 7.32902 11.8011 7.25 12 7.25C12.1989 7.25 12.3897 7.32902 12.5303 7.46967C12.671 7.61032 12.75 7.80109 12.75 8V8.5C12.7474 8.69811 12.6676 8.88737 12.5275 9.02747C12.3874 9.16756 12.1981 9.24741 12 9.25Z"
                      fill="#F49F0A"
                    />
                    <path
                      d="M12 21C10.22 21 8.47991 20.4722 6.99987 19.4832C5.51983 18.4943 4.36628 17.0887 3.68509 15.4442C3.0039 13.7996 2.82567 11.99 3.17294 10.2442C3.5202 8.49836 4.37737 6.89472 5.63604 5.63604C6.89472 4.37737 8.49836 3.5202 10.2442 3.17294C11.99 2.82567 13.7996 3.0039 15.4442 3.68509C17.0887 4.36628 18.4943 5.51983 19.4832 6.99987C20.4722 8.47991 21 10.22 21 12C21 14.387 20.0518 16.6761 18.364 18.364C16.6761 20.0518 14.387 21 12 21ZM12 4.5C10.5166 4.5 9.0666 4.93987 7.83323 5.76398C6.59986 6.58809 5.63856 7.75943 5.07091 9.12988C4.50325 10.5003 4.35473 12.0083 4.64411 13.4632C4.9335 14.918 5.64781 16.2544 6.6967 17.3033C7.7456 18.3522 9.08197 19.0665 10.5368 19.3559C11.9917 19.6453 13.4997 19.4968 14.8701 18.9291C16.2406 18.3614 17.4119 17.4001 18.236 16.1668C19.0601 14.9334 19.5 13.4834 19.5 12C19.5 10.0109 18.7098 8.10323 17.3033 6.6967C15.8968 5.29018 13.9891 4.5 12 4.5Z"
                      fill="#F49F0A"
                    />
                  </svg>
                </div>
                <div className="content">
                  <div className="notifications_card_title">
                    Validation in progress
                  </div>
                  <div className="subtitle">
                    Your facility's project is being validated. We'll let you
                    know when it's completed
                  </div>

                  <div className="time">{`${moment(
                    parseInt(notification?.timestamp)
                  ).format("Do MMM YYYY")} ${moment(
                    parseInt(notification?.timestamp)
                  ).format("hh:mm A")}`}</div>
                </div>
                <div
                  className="icon pointer"
                  onClick={() => handleFirebaseNotificationUpdate(notification)}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 13.4L7.10005 18.3C6.91672 18.4834 6.68338 18.575 6.40005 18.575C6.11672 18.575 5.88338 18.4834 5.70005 18.3C5.51672 18.1167 5.42505 17.8834 5.42505 17.6C5.42505 17.3167 5.51672 17.0834 5.70005 16.9L10.6 12L5.70005 7.10005C5.51672 6.91672 5.42505 6.68338 5.42505 6.40005C5.42505 6.11672 5.51672 5.88338 5.70005 5.70005C5.88338 5.51672 6.11672 5.42505 6.40005 5.42505C6.68338 5.42505 6.91672 5.51672 7.10005 5.70005L12 10.6L16.9 5.70005C17.0834 5.51672 17.3167 5.42505 17.6 5.42505C17.8834 5.42505 18.1167 5.51672 18.3 5.70005C18.4834 5.88338 18.575 6.11672 18.575 6.40005C18.575 6.68338 18.4834 6.91672 18.3 7.10005L13.4 12L18.3 16.9C18.4834 17.0834 18.575 17.3167 18.575 17.6C18.575 17.8834 18.4834 18.1167 18.3 18.3C18.1167 18.4834 17.8834 18.575 17.6 18.575C17.3167 18.575 17.0834 18.4834 16.9 18.3L12 13.4Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </div>
            )}
          {!notification?.read &&
            notification?.type === "Validation successful" && (
              <div className="notification_card">
                <div className="icon">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.5 15.25C10.307 15.2352 10.1276 15.1455 9.99998 15L6.99998 12C6.93314 11.86 6.91133 11.7028 6.93756 11.5499C6.96379 11.3971 7.03676 11.2561 7.14643 11.1464C7.2561 11.0368 7.39707 10.9638 7.54993 10.9376C7.70279 10.9113 7.86003 10.9331 7.99998 11L10.47 13.47L19 4.99998C19.1399 4.93314 19.2972 4.91133 19.45 4.93756C19.6029 4.96379 19.7439 5.03676 19.8535 5.14643C19.9632 5.2561 20.0362 5.39707 20.0624 5.54993C20.0886 5.70279 20.0668 5.86003 20 5.99998L11 15C10.8724 15.1455 10.6929 15.2352 10.5 15.25Z"
                      fill="#40C73D"
                    />
                    <path
                      d="M12.0001 21C10.3916 20.9974 8.81313 20.5638 7.42903 19.7442C6.04494 18.9246 4.90578 17.7491 4.13011 16.34C3.54049 15.2899 3.17608 14.1286 3.06011 12.93C2.87709 11.172 3.21572 9.39915 4.03375 7.83243C4.85179 6.26571 6.11302 4.97454 7.66011 4.11996C8.71013 3.53035 9.87147 3.16594 11.0701 3.04996C12.2643 2.92151 13.4721 3.03719 14.6201 3.38996C14.7226 3.41044 14.8196 3.45209 14.905 3.51225C14.9905 3.57242 15.0624 3.64977 15.1162 3.73935C15.1699 3.82892 15.2044 3.92875 15.2174 4.03242C15.2303 4.1361 15.2215 4.24134 15.1914 4.3414C15.1613 4.44146 15.1106 4.53412 15.0427 4.61346C14.9747 4.6928 14.8909 4.75705 14.7966 4.80211C14.7023 4.84717 14.5997 4.87203 14.4952 4.8751C14.3908 4.87818 14.2869 4.8594 14.1901 4.81996C13.2187 4.52788 12.1988 4.43269 11.1901 4.53996C10.1931 4.64205 9.22706 4.94479 8.35011 5.42996C7.50524 5.89607 6.75825 6.52082 6.15011 7.26996C5.52397 8.03312 5.0564 8.91355 4.7748 9.85968C4.49319 10.8058 4.4032 11.7986 4.51011 12.78C4.6122 13.777 4.91494 14.743 5.40011 15.62C5.86621 16.4648 6.49096 17.2118 7.24011 17.82C8.00327 18.4461 8.8837 18.9137 9.82982 19.1953C10.776 19.4769 11.7688 19.5669 12.7501 19.46C13.7471 19.3579 14.7132 19.0551 15.5901 18.57C16.435 18.1039 17.182 17.4791 17.7901 16.73C18.4162 15.9668 18.8838 15.0864 19.1654 14.1402C19.447 13.1941 19.537 12.2013 19.4301 11.22C19.4102 11.0118 19.4738 10.8042 19.607 10.6429C19.7401 10.4816 19.9319 10.3799 20.1401 10.36C20.3483 10.3401 20.5559 10.4037 20.7172 10.5369C20.8784 10.67 20.9802 10.8618 21.0001 11.07C21.1822 12.8289 20.8421 14.6026 20.0222 16.1694C19.2023 17.7362 17.9391 19.0268 16.3901 19.88C15.3289 20.4937 14.1497 20.8754 12.9301 21H12.0001Z"
                      fill="#40C73D"
                    />
                  </svg>
                </div>
                <div className="content">
                  <div className="notifications_card_title">
                    Validation successful
                  </div>
                  <div className="subtitle">
                    Your facility's project has been validated!
                  </div>

                  <div className="time">{`${moment(
                    parseInt(notification?.timestamp)
                  ).format("Do MMM YYYY")} ${moment(
                    parseInt(notification?.timestamp)
                  ).format("hh:mm A")}`}</div>
                </div>
                <div
                  className="icon pointer"
                  onClick={() => handleFirebaseNotificationUpdate(notification)}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 13.4L7.10005 18.3C6.91672 18.4834 6.68338 18.575 6.40005 18.575C6.11672 18.575 5.88338 18.4834 5.70005 18.3C5.51672 18.1167 5.42505 17.8834 5.42505 17.6C5.42505 17.3167 5.51672 17.0834 5.70005 16.9L10.6 12L5.70005 7.10005C5.51672 6.91672 5.42505 6.68338 5.42505 6.40005C5.42505 6.11672 5.51672 5.88338 5.70005 5.70005C5.88338 5.51672 6.11672 5.42505 6.40005 5.42505C6.68338 5.42505 6.91672 5.51672 7.10005 5.70005L12 10.6L16.9 5.70005C17.0834 5.51672 17.3167 5.42505 17.6 5.42505C17.8834 5.42505 18.1167 5.51672 18.3 5.70005C18.4834 5.88338 18.575 6.11672 18.575 6.40005C18.575 6.68338 18.4834 6.91672 18.3 7.10005L13.4 12L18.3 16.9C18.4834 17.0834 18.575 17.3167 18.575 17.6C18.575 17.8834 18.4834 18.1167 18.3 18.3C18.1167 18.4834 17.8834 18.575 17.6 18.575C17.3167 18.575 17.0834 18.4834 16.9 18.3L12 13.4Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </div>
            )}
          {/* {!notification?.read &&
            notification?.type === "Validation successful" && ( */}
          {/* <div className="notification_card">
            <div className="icon">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.5 15.25C10.307 15.2352 10.1276 15.1455 9.99998 15L6.99998 12C6.93314 11.86 6.91133 11.7028 6.93756 11.5499C6.96379 11.3971 7.03676 11.2561 7.14643 11.1464C7.2561 11.0368 7.39707 10.9638 7.54993 10.9376C7.70279 10.9113 7.86003 10.9331 7.99998 11L10.47 13.47L19 4.99998C19.1399 4.93314 19.2972 4.91133 19.45 4.93756C19.6029 4.96379 19.7439 5.03676 19.8535 5.14643C19.9632 5.2561 20.0362 5.39707 20.0624 5.54993C20.0886 5.70279 20.0668 5.86003 20 5.99998L11 15C10.8724 15.1455 10.6929 15.2352 10.5 15.25Z"
                  fill="#40C73D"
                />
                <path
                  d="M12.0001 21C10.3916 20.9974 8.81313 20.5638 7.42903 19.7442C6.04494 18.9246 4.90578 17.7491 4.13011 16.34C3.54049 15.2899 3.17608 14.1286 3.06011 12.93C2.87709 11.172 3.21572 9.39915 4.03375 7.83243C4.85179 6.26571 6.11302 4.97454 7.66011 4.11996C8.71013 3.53035 9.87147 3.16594 11.0701 3.04996C12.2643 2.92151 13.4721 3.03719 14.6201 3.38996C14.7226 3.41044 14.8196 3.45209 14.905 3.51225C14.9905 3.57242 15.0624 3.64977 15.1162 3.73935C15.1699 3.82892 15.2044 3.92875 15.2174 4.03242C15.2303 4.1361 15.2215 4.24134 15.1914 4.3414C15.1613 4.44146 15.1106 4.53412 15.0427 4.61346C14.9747 4.6928 14.8909 4.75705 14.7966 4.80211C14.7023 4.84717 14.5997 4.87203 14.4952 4.8751C14.3908 4.87818 14.2869 4.8594 14.1901 4.81996C13.2187 4.52788 12.1988 4.43269 11.1901 4.53996C10.1931 4.64205 9.22706 4.94479 8.35011 5.42996C7.50524 5.89607 6.75825 6.52082 6.15011 7.26996C5.52397 8.03312 5.0564 8.91355 4.7748 9.85968C4.49319 10.8058 4.4032 11.7986 4.51011 12.78C4.6122 13.777 4.91494 14.743 5.40011 15.62C5.86621 16.4648 6.49096 17.2118 7.24011 17.82C8.00327 18.4461 8.8837 18.9137 9.82982 19.1953C10.776 19.4769 11.7688 19.5669 12.7501 19.46C13.7471 19.3579 14.7132 19.0551 15.5901 18.57C16.435 18.1039 17.182 17.4791 17.7901 16.73C18.4162 15.9668 18.8838 15.0864 19.1654 14.1402C19.447 13.1941 19.537 12.2013 19.4301 11.22C19.4102 11.0118 19.4738 10.8042 19.607 10.6429C19.7401 10.4816 19.9319 10.3799 20.1401 10.36C20.3483 10.3401 20.5559 10.4037 20.7172 10.5369C20.8784 10.67 20.9802 10.8618 21.0001 11.07C21.1822 12.8289 20.8421 14.6026 20.0222 16.1694C19.2023 17.7362 17.9391 19.0268 16.3901 19.88C15.3289 20.4937 14.1497 20.8754 12.9301 21H12.0001Z"
                  fill="#40C73D"
                />
              </svg>
            </div>
            <div className="content">
              <div className="notifications_card_title">
                IoT Installation Reminder
              </div>
              <div className="subtitle">
                Our team will be arriving at your location on May 3rd between
                12-3pm for your IoT installation. Please ensure you are
                available. Thank you!
                <br />
                <span className="subtitle__red" onClick={() => setModalOpen(true)}>Click here to cancel your installation appointment</span>
              </div>

              <div className="time">{`${moment(
                parseInt(notification?.timestamp)
              ).format("Do MMM YYYY")} ${moment(
                parseInt(notification?.timestamp)
              ).format("hh:mm A")}`}</div>
            </div>
            <div
              className="icon pointer"
              onClick={() => handleFirebaseNotificationUpdate(notification)}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 13.4L7.10005 18.3C6.91672 18.4834 6.68338 18.575 6.40005 18.575C6.11672 18.575 5.88338 18.4834 5.70005 18.3C5.51672 18.1167 5.42505 17.8834 5.42505 17.6C5.42505 17.3167 5.51672 17.0834 5.70005 16.9L10.6 12L5.70005 7.10005C5.51672 6.91672 5.42505 6.68338 5.42505 6.40005C5.42505 6.11672 5.51672 5.88338 5.70005 5.70005C5.88338 5.51672 6.11672 5.42505 6.40005 5.42505C6.68338 5.42505 6.91672 5.51672 7.10005 5.70005L12 10.6L16.9 5.70005C17.0834 5.51672 17.3167 5.42505 17.6 5.42505C17.8834 5.42505 18.1167 5.51672 18.3 5.70005C18.4834 5.88338 18.575 6.11672 18.575 6.40005C18.575 6.68338 18.4834 6.91672 18.3 7.10005L13.4 12L18.3 16.9C18.4834 17.0834 18.575 17.3167 18.575 17.6C18.575 17.8834 18.4834 18.1167 18.3 18.3C18.1167 18.4834 17.8834 18.575 17.6 18.575C17.3167 18.575 17.0834 18.4834 16.9 18.3L12 13.4Z"
                  fill="white"
                />
              </svg>
            </div>
          </div> */}
          {/* )} */}

          {!notification?.read &&
            notification?.type === "Validation rejected" && (
              <div
                className="notification_card pointer"
                onClick={() => navigate("/profile/editable")}
              >
                <div className="icon">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12.8999 1.50004C15.2999 1.65004 17.5499 2.85004 19.1999 4.50004C21.1499 6.60004 22.1999 9.15004 22.1999 12.15C22.1999 14.55 21.2999 16.8 19.7999 18.75C18.2999 20.55 16.1999 21.9 13.7999 22.35C11.3999 22.8 8.99994 22.5 6.89994 21.3C4.79994 20.1 3.14994 18.3 2.24994 16.05C1.34994 13.8 1.19994 11.25 1.94994 9.00004C2.69994 6.60004 4.04994 4.65004 6.14994 3.30004C8.09994 1.95004 10.4999 1.35004 12.8999 1.50004ZM13.6499 20.85C15.5999 20.4 17.3999 19.35 18.7499 17.7C19.9499 16.05 20.6999 14.1 20.5499 12C20.5499 9.60004 19.6499 7.20004 17.9999 5.55004C16.4999 4.05004 14.6999 3.15004 12.5999 3.00004C10.6499 2.85004 8.54994 3.30004 6.89994 4.50004C5.24994 5.70004 4.04994 7.35004 3.44994 9.45004C2.84994 11.4 2.84994 13.5 3.74994 15.45C4.64994 17.4 5.99994 18.9 7.79994 19.95C9.59994 21 11.6999 21.3 13.6499 20.85ZM11.8499 11.25L15.4499 7.50004L16.4999 8.55004L12.8999 12.3L16.4999 16.05L15.4499 17.1L11.8499 13.35L8.24994 17.1L7.19994 16.05L10.7999 12.3L7.19994 8.55004L8.24994 7.50004L11.8499 11.25Z"
                      fill="#E43424"
                    />
                  </svg>
                </div>
                <div className="content">
                  <div className="notifications_card_title">
                    Project Validation Failed
                  </div>
                  <div className="subtitle">
                    Your project failed validation. Please see the reason below:
                    <br />
                    {notification?.reason}
                  </div>

                  <div className="time">{`${moment(
                    parseInt(notification?.timestamp)
                  ).format("Do MMM YYYY")} ${moment(
                    parseInt(notification?.timestamp)
                  ).format("hh:mm A")}`}</div>
                </div>
                <div
                  className="icon pointer"
                  onClick={() => handleFirebaseNotificationUpdate(notification)}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 13.4L7.10005 18.3C6.91672 18.4834 6.68338 18.575 6.40005 18.575C6.11672 18.575 5.88338 18.4834 5.70005 18.3C5.51672 18.1167 5.42505 17.8834 5.42505 17.6C5.42505 17.3167 5.51672 17.0834 5.70005 16.9L10.6 12L5.70005 7.10005C5.51672 6.91672 5.42505 6.68338 5.42505 6.40005C5.42505 6.11672 5.51672 5.88338 5.70005 5.70005C5.88338 5.51672 6.11672 5.42505 6.40005 5.42505C6.68338 5.42505 6.91672 5.51672 7.10005 5.70005L12 10.6L16.9 5.70005C17.0834 5.51672 17.3167 5.42505 17.6 5.42505C17.8834 5.42505 18.1167 5.51672 18.3 5.70005C18.4834 5.88338 18.575 6.11672 18.575 6.40005C18.575 6.68338 18.4834 6.91672 18.3 7.10005L13.4 12L18.3 16.9C18.4834 17.0834 18.575 17.3167 18.575 17.6C18.575 17.8834 18.4834 18.1167 18.3 18.3C18.1167 18.4834 17.8834 18.575 17.6 18.575C17.3167 18.575 17.0834 18.4834 16.9 18.3L12 13.4Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </div>
            )}

          {!notification?.read &&
            notification?.type === "Suggestion Received" && (
              <div className="notification_card">
                <div className="icon">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.5 15.25C10.307 15.2352 10.1276 15.1455 9.99998 15L6.99998 12C6.93314 11.86 6.91133 11.7028 6.93756 11.5499C6.96379 11.3971 7.03676 11.2561 7.14643 11.1464C7.2561 11.0368 7.39707 10.9638 7.54993 10.9376C7.70279 10.9113 7.86003 10.9331 7.99998 11L10.47 13.47L19 4.99998C19.1399 4.93314 19.2972 4.91133 19.45 4.93756C19.6029 4.96379 19.7439 5.03676 19.8535 5.14643C19.9632 5.2561 20.0362 5.39707 20.0624 5.54993C20.0886 5.70279 20.0668 5.86003 20 5.99998L11 15C10.8724 15.1455 10.6929 15.2352 10.5 15.25Z"
                      fill="#40C73D"
                    />
                    <path
                      d="M12.0001 21C10.3916 20.9974 8.81313 20.5638 7.42903 19.7442C6.04494 18.9246 4.90578 17.7491 4.13011 16.34C3.54049 15.2899 3.17608 14.1286 3.06011 12.93C2.87709 11.172 3.21572 9.39915 4.03375 7.83243C4.85179 6.26571 6.11302 4.97454 7.66011 4.11996C8.71013 3.53035 9.87147 3.16594 11.0701 3.04996C12.2643 2.92151 13.4721 3.03719 14.6201 3.38996C14.7226 3.41044 14.8196 3.45209 14.905 3.51225C14.9905 3.57242 15.0624 3.64977 15.1162 3.73935C15.1699 3.82892 15.2044 3.92875 15.2174 4.03242C15.2303 4.1361 15.2215 4.24134 15.1914 4.3414C15.1613 4.44146 15.1106 4.53412 15.0427 4.61346C14.9747 4.6928 14.8909 4.75705 14.7966 4.80211C14.7023 4.84717 14.5997 4.87203 14.4952 4.8751C14.3908 4.87818 14.2869 4.8594 14.1901 4.81996C13.2187 4.52788 12.1988 4.43269 11.1901 4.53996C10.1931 4.64205 9.22706 4.94479 8.35011 5.42996C7.50524 5.89607 6.75825 6.52082 6.15011 7.26996C5.52397 8.03312 5.0564 8.91355 4.7748 9.85968C4.49319 10.8058 4.4032 11.7986 4.51011 12.78C4.6122 13.777 4.91494 14.743 5.40011 15.62C5.86621 16.4648 6.49096 17.2118 7.24011 17.82C8.00327 18.4461 8.8837 18.9137 9.82982 19.1953C10.776 19.4769 11.7688 19.5669 12.7501 19.46C13.7471 19.3579 14.7132 19.0551 15.5901 18.57C16.435 18.1039 17.182 17.4791 17.7901 16.73C18.4162 15.9668 18.8838 15.0864 19.1654 14.1402C19.447 13.1941 19.537 12.2013 19.4301 11.22C19.4102 11.0118 19.4738 10.8042 19.607 10.6429C19.7401 10.4816 19.9319 10.3799 20.1401 10.36C20.3483 10.3401 20.5559 10.4037 20.7172 10.5369C20.8784 10.67 20.9802 10.8618 21.0001 11.07C21.1822 12.8289 20.8421 14.6026 20.0222 16.1694C19.2023 17.7362 17.9391 19.0268 16.3901 19.88C15.3289 20.4937 14.1497 20.8754 12.9301 21H12.0001Z"
                      fill="#40C73D"
                    />
                  </svg>
                </div>
                <div className="content">
                  <div className="notifications_card_title">Suggestion</div>
                  <div className="subtitle">
                    Your suggestion has been sent to CloseCarbon email. Thanks
                    for sharing your ideas with us
                  </div>

                  <div className="time">{`${moment(
                    parseInt(notification?.timestamp)
                  ).format("Do MMM YYYY")} ${moment(
                    parseInt(notification?.timestamp)
                  ).format("hh:mm A")}`}</div>
                </div>
                <div className="icon">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 13.4L7.10005 18.3C6.91672 18.4834 6.68338 18.575 6.40005 18.575C6.11672 18.575 5.88338 18.4834 5.70005 18.3C5.51672 18.1167 5.42505 17.8834 5.42505 17.6C5.42505 17.3167 5.51672 17.0834 5.70005 16.9L10.6 12L5.70005 7.10005C5.51672 6.91672 5.42505 6.68338 5.42505 6.40005C5.42505 6.11672 5.51672 5.88338 5.70005 5.70005C5.88338 5.51672 6.11672 5.42505 6.40005 5.42505C6.68338 5.42505 6.91672 5.51672 7.10005 5.70005L12 10.6L16.9 5.70005C17.0834 5.51672 17.3167 5.42505 17.6 5.42505C17.8834 5.42505 18.1167 5.51672 18.3 5.70005C18.4834 5.88338 18.575 6.11672 18.575 6.40005C18.575 6.68338 18.4834 6.91672 18.3 7.10005L13.4 12L18.3 16.9C18.4834 17.0834 18.575 17.3167 18.575 17.6C18.575 17.8834 18.4834 18.1167 18.3 18.3C18.1167 18.4834 17.8834 18.575 17.6 18.575C17.3167 18.575 17.0834 18.4834 16.9 18.3L12 13.4Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </div>
            )}
        </>
      ))}
      {/* <div className="notification_card">
        <div className="icon">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.8999 1.50004C15.2999 1.65004 17.5499 2.85004 19.1999 4.50004C21.1499 6.60004 22.1999 9.15004 22.1999 12.15C22.1999 14.55 21.2999 16.8 19.7999 18.75C18.2999 20.55 16.1999 21.9 13.7999 22.35C11.3999 22.8 8.99994 22.5 6.89994 21.3C4.79994 20.1 3.14994 18.3 2.24994 16.05C1.34994 13.8 1.19994 11.25 1.94994 9.00004C2.69994 6.60004 4.04994 4.65004 6.14994 3.30004C8.09994 1.95004 10.4999 1.35004 12.8999 1.50004ZM13.6499 20.85C15.5999 20.4 17.3999 19.35 18.7499 17.7C19.9499 16.05 20.6999 14.1 20.5499 12C20.5499 9.60004 19.6499 7.20004 17.9999 5.55004C16.4999 4.05004 14.6999 3.15004 12.5999 3.00004C10.6499 2.85004 8.54994 3.30004 6.89994 4.50004C5.24994 5.70004 4.04994 7.35004 3.44994 9.45004C2.84994 11.4 2.84994 13.5 3.74994 15.45C4.64994 17.4 5.99994 18.9 7.79994 19.95C9.59994 21 11.6999 21.3 13.6499 20.85ZM11.8499 11.25L15.4499 7.50004L16.4999 8.55004L12.8999 12.3L16.4999 16.05L15.4499 17.1L11.8499 13.35L8.24994 17.1L7.19994 16.05L10.7999 12.3L7.19994 8.55004L8.24994 7.50004L11.8499 11.25Z"
              fill="#E43424"
            />
          </svg>
        </div>
        <div className="content">
          <div className="notifications_card_title">Upgrades</div>
          <div className="subtitle">
            CloseCarbon platform will be down from 12:30am- 3:00am for upgrades
          </div>

          <div className="time">Today 09:23AM</div>
        </div>
        <div className="icon">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 13.4L7.10005 18.3C6.91672 18.4834 6.68338 18.575 6.40005 18.575C6.11672 18.575 5.88338 18.4834 5.70005 18.3C5.51672 18.1167 5.42505 17.8834 5.42505 17.6C5.42505 17.3167 5.51672 17.0834 5.70005 16.9L10.6 12L5.70005 7.10005C5.51672 6.91672 5.42505 6.68338 5.42505 6.40005C5.42505 6.11672 5.51672 5.88338 5.70005 5.70005C5.88338 5.51672 6.11672 5.42505 6.40005 5.42505C6.68338 5.42505 6.91672 5.51672 7.10005 5.70005L12 10.6L16.9 5.70005C17.0834 5.51672 17.3167 5.42505 17.6 5.42505C17.8834 5.42505 18.1167 5.51672 18.3 5.70005C18.4834 5.88338 18.575 6.11672 18.575 6.40005C18.575 6.68338 18.4834 6.91672 18.3 7.10005L13.4 12L18.3 16.9C18.4834 17.0834 18.575 17.3167 18.575 17.6C18.575 17.8834 18.4834 18.1167 18.3 18.3C18.1167 18.4834 17.8834 18.575 17.6 18.575C17.3167 18.575 17.0834 18.4834 16.9 18.3L12 13.4Z"
              fill="white"
            />
          </svg>
        </div>
      </div> */}

      {eventNotifications?.map((notification) => (
        <>
          {notification?.type === "Carbon Credits Transferred" &&
            !notification?.read && (
              <div className="notification_card">
                <div className="icon">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.5 15.25C10.307 15.2352 10.1276 15.1455 9.99998 15L6.99998 12C6.93314 11.86 6.91133 11.7028 6.93756 11.5499C6.96379 11.3971 7.03676 11.2561 7.14643 11.1464C7.2561 11.0368 7.39707 10.9638 7.54993 10.9376C7.70279 10.9113 7.86003 10.9331 7.99998 11L10.47 13.47L19 4.99998C19.1399 4.93314 19.2972 4.91133 19.45 4.93756C19.6029 4.96379 19.7439 5.03676 19.8535 5.14643C19.9632 5.2561 20.0362 5.39707 20.0624 5.54993C20.0886 5.70279 20.0668 5.86003 20 5.99998L11 15C10.8724 15.1455 10.6929 15.2352 10.5 15.25Z"
                      fill="#40C73D"
                    />
                    <path
                      d="M12.0001 21C10.3916 20.9974 8.81313 20.5638 7.42903 19.7442C6.04494 18.9246 4.90578 17.7491 4.13011 16.34C3.54049 15.2899 3.17608 14.1286 3.06011 12.93C2.87709 11.172 3.21572 9.39915 4.03375 7.83243C4.85179 6.26571 6.11302 4.97454 7.66011 4.11996C8.71013 3.53035 9.87147 3.16594 11.0701 3.04996C12.2643 2.92151 13.4721 3.03719 14.6201 3.38996C14.7226 3.41044 14.8196 3.45209 14.905 3.51225C14.9905 3.57242 15.0624 3.64977 15.1162 3.73935C15.1699 3.82892 15.2044 3.92875 15.2174 4.03242C15.2303 4.1361 15.2215 4.24134 15.1914 4.3414C15.1613 4.44146 15.1106 4.53412 15.0427 4.61346C14.9747 4.6928 14.8909 4.75705 14.7966 4.80211C14.7023 4.84717 14.5997 4.87203 14.4952 4.8751C14.3908 4.87818 14.2869 4.8594 14.1901 4.81996C13.2187 4.52788 12.1988 4.43269 11.1901 4.53996C10.1931 4.64205 9.22706 4.94479 8.35011 5.42996C7.50524 5.89607 6.75825 6.52082 6.15011 7.26996C5.52397 8.03312 5.0564 8.91355 4.7748 9.85968C4.49319 10.8058 4.4032 11.7986 4.51011 12.78C4.6122 13.777 4.91494 14.743 5.40011 15.62C5.86621 16.4648 6.49096 17.2118 7.24011 17.82C8.00327 18.4461 8.8837 18.9137 9.82982 19.1953C10.776 19.4769 11.7688 19.5669 12.7501 19.46C13.7471 19.3579 14.7132 19.0551 15.5901 18.57C16.435 18.1039 17.182 17.4791 17.7901 16.73C18.4162 15.9668 18.8838 15.0864 19.1654 14.1402C19.447 13.1941 19.537 12.2013 19.4301 11.22C19.4102 11.0118 19.4738 10.8042 19.607 10.6429C19.7401 10.4816 19.9319 10.3799 20.1401 10.36C20.3483 10.3401 20.5559 10.4037 20.7172 10.5369C20.8784 10.67 20.9802 10.8618 21.0001 11.07C21.1822 12.8289 20.8421 14.6026 20.0222 16.1694C19.2023 17.7362 17.9391 19.0268 16.3901 19.88C15.3289 20.4937 14.1497 20.8754 12.9301 21H12.0001Z"
                      fill="#40C73D"
                    />
                  </svg>
                </div>
                <div className="content">
                  <div className="notifications_card_title">
                  CloseCarbon Reduction Tonnes (CCRT) Issuance
                  </div>
                  <div className="subtitle">
                    Congratulations, you’ve been issued CCRT.
                  </div>

                  <div className="time">{`${moment(
                    parseInt(notification?.timestamp) * 1000
                  ).format("Do MMM YYYY")} ${moment(
                    parseInt(notification?.timestamp) * 1000
                  ).format("hh:mm A")}`}</div>
                </div>
                <div
                  className="icon pointer"
                  onClick={() => handleNotificationUpdate(notification)}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 13.4L7.10005 18.3C6.91672 18.4834 6.68338 18.575 6.40005 18.575C6.11672 18.575 5.88338 18.4834 5.70005 18.3C5.51672 18.1167 5.42505 17.8834 5.42505 17.6C5.42505 17.3167 5.51672 17.0834 5.70005 16.9L10.6 12L5.70005 7.10005C5.51672 6.91672 5.42505 6.68338 5.42505 6.40005C5.42505 6.11672 5.51672 5.88338 5.70005 5.70005C5.88338 5.51672 6.11672 5.42505 6.40005 5.42505C6.68338 5.42505 6.91672 5.51672 7.10005 5.70005L12 10.6L16.9 5.70005C17.0834 5.51672 17.3167 5.42505 17.6 5.42505C17.8834 5.42505 18.1167 5.51672 18.3 5.70005C18.4834 5.88338 18.575 6.11672 18.575 6.40005C18.575 6.68338 18.4834 6.91672 18.3 7.10005L13.4 12L18.3 16.9C18.4834 17.0834 18.575 17.3167 18.575 17.6C18.575 17.8834 18.4834 18.1167 18.3 18.3C18.1167 18.4834 17.8834 18.575 17.6 18.575C17.3167 18.575 17.0834 18.4834 16.9 18.3L12 13.4Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </div>
            )}
          {notification?.type === "Perform KYC" && !notification?.read && (
            <div className="notification_card">
              <div className="icon">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.5 15.25C10.307 15.2352 10.1276 15.1455 9.99998 15L6.99998 12C6.93314 11.86 6.91133 11.7028 6.93756 11.5499C6.96379 11.3971 7.03676 11.2561 7.14643 11.1464C7.2561 11.0368 7.39707 10.9638 7.54993 10.9376C7.70279 10.9113 7.86003 10.9331 7.99998 11L10.47 13.47L19 4.99998C19.1399 4.93314 19.2972 4.91133 19.45 4.93756C19.6029 4.96379 19.7439 5.03676 19.8535 5.14643C19.9632 5.2561 20.0362 5.39707 20.0624 5.54993C20.0886 5.70279 20.0668 5.86003 20 5.99998L11 15C10.8724 15.1455 10.6929 15.2352 10.5 15.25Z"
                    fill="#40C73D"
                  />
                  <path
                    d="M12.0001 21.0001C10.3916 20.9975 8.81313 20.5639 7.42903 19.7443C6.04494 18.9248 4.90578 17.7492 4.13011 16.3401C3.54049 15.2901 3.17608 14.1287 3.06011 12.9301C2.87709 11.1722 3.21572 9.39927 4.03375 7.83255C4.85179 6.26584 6.11302 4.97466 7.66011 4.12009C8.71013 3.53047 9.87147 3.16606 11.0701 3.05009C12.2643 2.92163 13.4721 3.03731 14.6201 3.39009C14.7226 3.41056 14.8196 3.45221 14.905 3.51238C14.9905 3.57254 15.0624 3.64989 15.1162 3.73947C15.1699 3.82904 15.2044 3.92887 15.2174 4.03255C15.2303 4.13622 15.2215 4.24146 15.1914 4.34152C15.1613 4.44158 15.1106 4.53425 15.0427 4.61358C14.9747 4.69292 14.8909 4.75718 14.7966 4.80223C14.7023 4.84729 14.5997 4.87215 14.4952 4.87523C14.3908 4.8783 14.2869 4.85952 14.1901 4.82009C13.2187 4.52801 12.1988 4.43281 11.1901 4.54009C10.1931 4.64218 9.22706 4.94492 8.35011 5.43009C7.50524 5.89619 6.75825 6.52094 6.15011 7.27009C5.52397 8.03325 5.0564 8.91367 4.7748 9.8598C4.49319 10.8059 4.4032 11.7987 4.51011 12.7801C4.6122 13.7771 4.91494 14.7431 5.40011 15.6201C5.86621 16.465 6.49096 17.2119 7.24011 17.8201C8.00327 18.4462 8.8837 18.9138 9.82982 19.1954C10.776 19.477 11.7688 19.567 12.7501 19.4601C13.7471 19.358 14.7132 19.0553 15.5901 18.5701C16.435 18.104 17.182 17.4792 17.7901 16.7301C18.4162 15.9669 18.8838 15.0865 19.1654 14.1404C19.447 13.1942 19.537 12.2014 19.4301 11.2201C19.4102 11.0119 19.4738 10.8043 19.607 10.643C19.7401 10.4818 19.9319 10.38 20.1401 10.3601C20.3483 10.3402 20.5559 10.4038 20.7172 10.537C20.8784 10.6701 20.9802 10.8619 21.0001 11.0701C21.1822 12.8291 20.8421 14.6027 20.0222 16.1695C19.2023 17.7363 17.9391 19.0269 16.3901 19.8801C15.3289 20.4938 14.1497 20.8756 12.9301 21.0001H12.0001Z"
                    fill="#40C73D"
                  />
                </svg>
              </div>
              <div className="content">
                <div className="notifications_card_title">KYC Confirmed</div>
                <div className="subtitle">
                  Your KYC details as been verified. Enjoy the full feature of
                  CloseCarbon
                </div>

                <div className="time">{`${moment(
                  parseInt(notification?.timestamp)
                ).format("Do MMM YYYY")} ${moment(
                  parseInt(notification?.timestamp)
                ).format("hh:mm A")}`}</div>
              </div>
              <div
                className="icon pointer"
                onClick={() => handleNotificationUpdate(notification)}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 13.4L7.10005 18.3C6.91672 18.4834 6.68338 18.575 6.40005 18.575C6.11672 18.575 5.88338 18.4834 5.70005 18.3C5.51672 18.1167 5.42505 17.8834 5.42505 17.6C5.42505 17.3167 5.51672 17.0834 5.70005 16.9L10.6 12L5.70005 7.10005C5.51672 6.91672 5.42505 6.68338 5.42505 6.40005C5.42505 6.11672 5.51672 5.88338 5.70005 5.70005C5.88338 5.51672 6.11672 5.42505 6.40005 5.42505C6.68338 5.42505 6.91672 5.51672 7.10005 5.70005L12 10.6L16.9 5.70005C17.0834 5.51672 17.3167 5.42505 17.6 5.42505C17.8834 5.42505 18.1167 5.51672 18.3 5.70005C18.4834 5.88338 18.575 6.11672 18.575 6.40005C18.575 6.68338 18.4834 6.91672 18.3 7.10005L13.4 12L18.3 16.9C18.4834 17.0834 18.575 17.3167 18.575 17.6C18.575 17.8834 18.4834 18.1167 18.3 18.3C18.1167 18.4834 17.8834 18.575 17.6 18.575C17.3167 18.575 17.0834 18.4834 16.9 18.3L12 13.4Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
          )}
        </>
      ))}
      {/* <div className="notification_card">
        <div className="icon">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.2749 3.02587C5.41464 3.68601 4.65648 4.46944 4.0249 5.35087L5.2249 6.25087C5.77573 5.48694 6.43229 4.80513 7.1749 4.22587L6.2749 3.02587ZM3.9499 8.72587L2.5249 8.27587C2.16262 9.31189 1.98492 10.4034 1.9999 11.5009H3.4999C3.49685 10.5575 3.64888 9.61997 3.9499 8.72587ZM2.5249 14.8009C2.87272 15.8467 3.37858 16.8331 4.0249 17.7259L5.2249 16.8259C4.68271 16.0667 4.25325 15.2331 3.9499 14.3509L2.5249 14.8009ZM6.3499 19.9759C7.24265 20.6222 8.22907 21.1281 9.2749 21.4759L9.7249 20.0509C8.8427 19.7475 8.00906 19.3181 7.2499 18.7759L6.3499 19.9759ZM9.2749 1.52587L9.7249 2.95087C10.619 2.64986 11.5565 2.49783 12.4999 2.50087V1.00087C11.4025 0.985901 10.3109 1.1636 9.2749 1.52587ZM18.6499 19.9759C19.5333 19.3175 20.3165 18.5343 20.9749 17.6509L19.7749 16.7509C19.2171 17.5337 18.5328 18.2181 17.7499 18.7759L18.6499 19.9759ZM21.0499 14.2759L22.4749 14.7259C22.8011 13.681 22.9779 12.5953 22.9999 11.5009H21.4999C21.5029 12.4443 21.3509 13.3818 21.0499 14.2759ZM22.3999 8.20087C22.0521 7.15505 21.5462 6.16862 20.8999 5.27587L19.6999 6.17587C20.2421 6.93503 20.6715 7.76868 20.9749 8.65087L22.3999 8.20087ZM18.5749 2.95087C17.6821 2.30455 16.6957 1.79869 15.6499 1.45087L15.1999 2.87587C16.0821 3.17923 16.9157 3.60868 17.6749 4.15087L18.5749 2.95087ZM15.7249 21.4759L15.2749 20.0509C14.3808 20.3519 13.4433 20.5039 12.4999 20.5009V22.0009C13.5899 21.9359 14.6705 21.76 15.7249 21.4759Z"
              fill="#E43424"
            />
            <path
              d="M8.0625 12.5625L10.6875 15.1875L15.9375 9.5625"
              stroke="#E43424"
              stroke-width="2.25"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div className="content">
          <div className="notifications_card_title">Emission reduction</div>
          <div className="subtitle">
            You didn’t meet your emission reduction goal for this month
          </div>

          <div className="time">Today 09:23AM</div>
        </div>
        <div className="icon">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 13.4L7.10005 18.3C6.91672 18.4834 6.68338 18.575 6.40005 18.575C6.11672 18.575 5.88338 18.4834 5.70005 18.3C5.51672 18.1167 5.42505 17.8834 5.42505 17.6C5.42505 17.3167 5.51672 17.0834 5.70005 16.9L10.6 12L5.70005 7.10005C5.51672 6.91672 5.42505 6.68338 5.42505 6.40005C5.42505 6.11672 5.51672 5.88338 5.70005 5.70005C5.88338 5.51672 6.11672 5.42505 6.40005 5.42505C6.68338 5.42505 6.91672 5.51672 7.10005 5.70005L12 10.6L16.9 5.70005C17.0834 5.51672 17.3167 5.42505 17.6 5.42505C17.8834 5.42505 18.1167 5.51672 18.3 5.70005C18.4834 5.88338 18.575 6.11672 18.575 6.40005C18.575 6.68338 18.4834 6.91672 18.3 7.10005L13.4 12L18.3 16.9C18.4834 17.0834 18.575 17.3167 18.575 17.6C18.575 17.8834 18.4834 18.1167 18.3 18.3C18.1167 18.4834 17.8834 18.575 17.6 18.575C17.3167 18.575 17.0834 18.4834 16.9 18.3L12 13.4Z"
              fill="white"
            />
          </svg>
        </div>
      </div> */}

      {modalOpen && <div className="cancel-installation-modal">
        {stage === 1 && <div className="cancel-installation-modal__inner">
          <div className="cancel-installation-modal__inner__title">
            Are you sure?
          </div>
          <div className="cancel-installation-modal__inner__subtitle">
            Your are about to cancel your IoT installation appointment.{" "}
          </div>

          <div className="cancel-installation-modal__inner__button-group">
            <button
            onClick={() => setStage(2)}
            >Yes</button>
            <button>No</button>
          </div>
        </div>}
        {stage === 2 && <div className="cancel-installation-modal__inner">
          <div className="cancel-installation-modal__inner__title">
          IoT installation cancelled
          </div>
          <div className="cancel-installation-modal__inner__subtitle">
          Your installation appointment for May 03rd has been cancelled.
          </div>

          <div className="cancel-installation-modal__inner__reschedule">
          To reschedule: <span onClick={() => {
            setStage(1)
            setModalOpen(false)
          }}>click here</span>
          </div>
        </div>}
      </div>}
    </div>
  );
};

export default Notifications;
